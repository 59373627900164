import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardCaseComponent } from './dashboard-case.component';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@Shared/components/notifications/notifications.module';

@NgModule({
    exports: [DashboardCaseComponent],
    imports: [CommonModule, RouterModule, NotificationsModule, DashboardCaseComponent]
})
export class DashboardCaseModule {}
