import { ChangeDetectionStrategy, Component, input, Input, OnInit } from '@angular/core';
import { UtilityService } from '@Services/utility.service';
import { IMenuItem, MenuItem } from '@Shared/models/IMenuItem';

import { IsNavMenuEnabledPipe } from '@Shared/pipes/utility.pipe';
import { TranslatePipe } from '../../../../Shared/pipes/translate.pipe';
import { MrouterLinkirective } from '../../../../Shared/directives/index.directive';
import { MenuItemsComponent } from '../menu-items/menu-items.component';
import { SvgIconComponent } from '../../../../Shared/components/svg-icon/svg-icon.component';
import { MatMenuModule } from '@angular/material/menu';
import { NgIf, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        MatMenuModule,
        SvgIconComponent,
        MenuItemsComponent,
        RouterLink,
        MrouterLinkirective,
        AsyncPipe,
        IsNavMenuEnabledPipe,
        TranslatePipe,
    ]
})
export class MenuItemComponent {
  readonly menuItem = input<MenuItem>();
}
