import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { EmployinfoComponent } from './employinfo/employinfo.component';
import { FormheaderComponent } from './formheader/formheader.component';
import { IdentificationComponent } from './identification/identification.component';
import { NextofkinComponent } from './nextofkin/nextofkin.component';
import { PersonalinfoComponent } from './personalinfo/personalinfo.component';
import { SharedModule } from '@Shared/shared.module';
import { ComponentsModule } from '@Shared/components/core/button/components.module';
import { RouterModule } from '@angular/router';
import { CreateAccountFormModule } from '../../life-components/create-account-modal/create-account-form/create-account-form.module';
import { SelectClientCreationTypeModule } from '../client-extras/select-client-creation-type/select-client-creation-type.module';
import { MatMenuModule } from '@angular/material/menu';
import { ClientHealthComponent } from './client-health/client-health.component';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
    exports: [
        EmployinfoComponent,
        FormheaderComponent,
        IdentificationComponent,
        NextofkinComponent,
        PersonalinfoComponent, ClientHealthComponent
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        MatDialogModule,
        CreateAccountFormModule,
        MatMenuModule,
        RouterModule,
        SelectClientCreationTypeModule,
        SharedModule,
        MatTabsModule,
        EmployinfoComponent,
        FormheaderComponent,
        IdentificationComponent,
        NextofkinComponent,
        PersonalinfoComponent,
        ClientHealthComponent,
    ],
})
export class CreateIndividualClientModule {}
