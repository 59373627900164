import { Routes } from '@angular/router';
import { ReinsDashboardLayoutComponent } from './reinsurance-shared/components/reins-dashboard-layout/reins-dashboard-layout.component';
import { authenticationGuard } from './guards/authentication.guard';
import { appRoutes } from '../../../evolutics-client-ui/src/app/configs/app-routes-configs/app-routes.config';
import { viewFacultativeRoutes } from '../../../evolutics-client-ui/src/app/Life/payment-desk/find-facultative/view-facultative/view-facultative.routes';

export const routes: Routes = [
  // {
  //   path: 'auth',
  //   children: authenticationRoutes,
  // },

  {
    path: appRoutes.auth._,
    loadChildren: () =>
      import('../../../evolutics-shared-lib/src/lib/Authentication/web-user/web-user-auth.routing'),
  },
  {
    path: 'login',
    redirectTo: appRoutes.auth._ + '/login',
  },
  {
    path: '',
    component: ReinsDashboardLayoutComponent,
    canActivateChild: [authenticationGuard],
    children: [
      {
        path: 'home',
        children: [
          {
            path: '',
            data: { title: 'Dashboard' },
            loadComponent: () =>
              import('./reinsurance-home/reinsurance-home.component').then((c) => c.ReinsuranceHomeComponent),
          },
          {
            path: '',
            data: { title: 'Dashboard' },
            loadChildren: () =>
              import(
                '../../../evolutics-client-ui/src/app/Life/payment-desk/find-facultative/view-facultative/view-facultative.routes'
              ).then((r) => r.viewFacultativeRoutes('')),
          },
        ],
      },
      {
        path: 'workflow-desk',
        loadChildren: () =>
          import('projects/evolutics-client-ui/src/app/Life/Workflow/workflow.module').then(
            (m) => m.WorkflowModule,
          ),
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            data: { title: 'Profile' },
            loadComponent: () => import('./reins-user-profile/reins-user-profile.component'),
          },
          {
            path: 'documents',
            data: { title: 'My Documents' },
            loadChildren: () =>
              import('@Reusables/reusable-pages/document-list/document-list.module').then(
                (m) => m.DocumentListModule,
              ),
          },
        ],
      },

      { path: '', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
];
