import { Component, Input, OnInit, booleanAttribute } from '@angular/core';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-info-icon',
    templateUrl: './info-icon.component.html',
    styleUrls: ['./info-icon.component.scss'],
    imports: [NgIf, NgClass, MatTooltipModule, AsyncPipe, TranslatePipe]
})
export class InfoIconComponent implements OnInit {
  @Input() text: string;
  @Input({ transform: booleanAttribute }) coloured = true;
  @Input({ transform: booleanAttribute }) left = false;
  @Input({ transform: booleanAttribute }) right = true;
  constructor() {}

  ngOnInit(): void {}
}
