import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilityService } from '@Services/utility.service';
import { EValidationType, ICodeDescription, ICodeTitle } from '@Shared/models/index.model';
import { AppService } from '@Services/app.service';
import { CreateCorporateClientService } from '../create-corporate-client.service';
import { TranslatePipe } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { PhoneNumberComponent } from 'ets-fe-ng-sdk';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { InputFormatDirective } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/directives/input-formatter.directive';
import { ValidationMessageComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-company-info-form',
    templateUrl: './company-info-form.component.html',
    styleUrls: ['./company-info-form.component.scss'],
    imports: [
        MatExpansionModule,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        InputBasicComponent,
        ValidationMessageComponent,
        InputFormatDirective,
        AutocompleteComponent,
        NgFor,
        PhoneNumberComponent,
        AsyncPipe,
        TranslatePipe,
    ]
})
export class CompanyInfoFormComponent implements OnInit {
  @Input() form: FormGroup;
  eValidationType = EValidationType;
  regionList: ICodeDescription[] = [];
  countryList: ICodeDescription[] = [];
  stateList: ICodeDescription[] = [];
  townList: ICodeDescription[] = [];
  sectorList: ICodeTitle[] = [];
  segmentList: ICodeTitle[] = [];
  constructor(
    public appS: AppService,
    private formService: CreateCorporateClientService,
    public uS: UtilityService
  ) {}

  ngOnInit(): void {
    this.setCountryList();
    this.setRegionList();
    this.setStateList();
    this.setTownList();
    this.setSectorList();
    this.setSegmentList();
  }
  get validation() {
    return this.form?.controls;
  }

  onSubmit() {
    console.log(this.form);
    this.formService.companyInfo(this.form.value as any);
    let ss1 = { companyInfo: this.form.value };
    console.log(ss1);
  }
  changeTab(tab: any) {
    console.log('tab');
    this.formService.tabChange(tab);
  }

  setCountryList() {
    this.formService.getCountryList().subscribe((res) => {
      this.countryList = res;
    });
  }

  setRegionList() {
    if (this.form.get('addressCountry')?.value) {
      this.formService
        .getRegionList(this.form.get('addressCountry')?.value)
        .subscribe((res) => {
          this.regionList = res;
          const addressRegionFC = this.form.get('addressRegion');
          if (!this.regionList.find((x) => x.code == addressRegionFC?.value))
            addressRegionFC.reset();
        });
    }
  }

  setStateList() {
    if (this.form.get('addressRegion')?.value) {
      this.formService
        .getStateList(this.form.get('addressRegion')?.value)
        .subscribe((res) => {
          this.stateList = res;
          const addressStateFC = this.form.get('addressState');
          if (!this.stateList.find((x) => x.code == addressStateFC?.value))
            addressStateFC.reset();
        });
    }
  }

  setTownList() {
    if (this.form.get('addressState')?.value) {
      this.formService
        .getTownList(this.form.get('addressState')?.value)
        .subscribe((res) => {
          this.townList = res;
          const addressTownFC = this.form.get('addressTown');
          if (!this.townList.find((x) => x.code == addressTownFC?.value))
            addressTownFC.reset();
        });
    }
  }
  setSectorList() {
    this.formService.getSectorList().subscribe((res) => {
      this.sectorList = res;
      console.log('sectorList', res);
    });
  }
  setSegmentList() {
    this.formService.getSegmentList().subscribe((res) => {
      this.segmentList = res;
      console.log('segmentlist', res);
    });
  }
}

