import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Client2Service } from 'projects/evolutics-client-ui/src/app/Services/client.service';
import { EValidationType } from '@Shared/models/index.model';
import { CreateCorporateClientService } from '../create-corporate-client.service';
import { UtilityService } from '@Services/utility.service';
import { CreateNewClientModalComponent } from '../modal/new-client-modal/create-new-client-modal.component';
import { ToAnyPipe } from 'ets-fe-ng-sdk';
import { ValidationMessageComponent } from 'ets-fe-ng-sdk';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-contact-info-form',
    templateUrl: './contact-info-form.component.html',
    styleUrls: ['./contact-info-form.component.scss'],
    imports: [
        FormsModule,
        NgFor,
        ReactiveFormsModule,
        InputBasicComponent,
        BtnComponent,
        ValidationMessageComponent,
        ToAnyPipe,
    ]
})
export class ContactInfoFormComponent implements OnInit {
  @Input() form: UntypedFormArray;
  relationList: any = [];
  eValidationType = EValidationType;
  constructor(
    private formService: CreateCorporateClientService,
    public clientS: Client2Service,
    private uS: UtilityService
  ) {}

  ngOnInit(): void {
    this.onContactInfo();
    this.setRelationList();
  }
  patchClientInfo(control: AbstractControl) {
    return new Promise<any>((resolve) => {
      if (!control?.value) {
        resolve(null);
      } else {
        setTimeout(() => {
          this.clientS.getClientBasicDataLiteByNo(control?.value).subscribe(
            (r) => {
              if (r?.clientNo) {
                (<FormGroup>control.parent).patchValue({
                  dob: r.dateOfBirth,
                  email: r.email,
                  fullName: r.fullName,
                  phoneNumber: r.phone,
                });
                resolve(null);
              } else {
                resolve({ notFound: true });
              }
            },
            (e) => {
              resolve({ notFound: true });
            }
          );
        }, 1000);
      }
    });
  }

  onContactInfo(index = this.form.controls.length) {
    this.form.insert(index, this.createContactData());
  }
  createContactData(): FormGroup {
    const form = new FormGroup({
      dob: new FormControl(),
      email: new FormControl(),
      fullName: new FormControl(),
      phoneNumber: new FormControl(),
      relationship: new FormControl(),
      relClientNo: new FormControl(null, null, this.patchClientInfo.bind(this)),
    });
    return form;
  }
  onDeleteContact(index: number) {
    if (this.form.controls.length === 1) {
      this.form.reset();
      return;
    }
    this.form?.removeAt(index);
  }
  onSubmit() {
    console.log(this.form);
    this.formService.contactInfo(this.form.value as any);
    let ss2 = { contactInfo: this.form.value };
  }
  changeTab(tab: any) {
    console.log('tab');
    this.formService.tabChange(tab);
  }

  setRelationList() {
    this.formService.getRelationList().subscribe((res) => {
      this.relationList = res;
      console.log('relationList', res);
    });
  }

  /**
   * open a dialog to create new client
   * @param index index of the forn array
   */
  openCreateClient(index, type: 'I' | 'C') {
    this.uS.dialogOpener(
      CreateNewClientModalComponent,
      {
        data: {
          type,
        },
        width: '75vw',
        maxWidth: '80vw',
        height: 'auto',
        maxHeight: '80vw',
      },
      (r) => {
        this.form.controls[index].patchValue({
          relClientNo: r?.clientNo,
          relationship: r?.relationship,
        });
      }
    );
  }
}
