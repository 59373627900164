import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { CMSContentTypeComponent } from '../cms-content-type.base.component';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, Observable, of, switchMap, tap } from 'rxjs';
import { LoaderComponent, SDKTranslateNoLoaderPipe } from 'ets-fe-ng-sdk';
import { TranslatePipe } from '../../../../../../Shared/pipes/translate.pipe';
import { SharedModule } from '../../../../../../Shared/shared.module';

@Component({
  selector: 'render-cms-image',
  standalone: true,
  imports: [CommonModule, LoaderComponent, 
      TranslatePipe,
      SDKTranslateNoLoaderPipe,],
  templateUrl: './render-cms-image.component.html',
  styleUrl: './render-cms-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderCMSImageComponent extends CMSContentTypeComponent {
  readonly loading = signal(true);
  readonly image = toSignal(
    toObservable(this.content).pipe(
      filter((content) => !content._hidePreview),
      tap(() => this.loading.set(true)),
      switchMap((content) =>
        content?.docId
          ? this.service.documentService.getFileBlob(content.docId).pipe(
              switchMap(
                (blob) =>
                  new Observable<string>((sub) => {
                    const fr = new FileReader();
                    fr.onload = () => {
                      sub.next(fr.result as string);
                      sub.complete();
                    };

                    fr.onerror = () => {
                      sub.next(null);
                      sub.complete();
                    };
                    fr.readAsDataURL(blob);
                  }),
              ),
            )
          : of(null),
      ),
      tap(() => this.loading.set(false)),
    ),
  );
  readonly altText = computed(() => `${this.content()?.title} image`);
}
