<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" [ngClass]="{fullScreen}">
  @if (fullScreen) {
<mat-toolbar color="primary" class="example-toolbar bg-primary d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 class="example-app-name">{{title|appTranslate|async}}</h1>
    </div>
    <div class="d-flex align-items-center">
      <div class="">
        @if (findButton) {
<a class="btn btn-outline-light cancelBtn-lg" routerLink="../task/client/workflow/find">
          <i class="fa fa-search"></i> Find Task
        </a>
}
      </div>
      <span class="mx-1"></span>
      <div class="">
        @if (createButton) {
<a class="btn btn-outline-light cancelBtn-lg" routerLink="../task/client/workflow/create">
          <i class="fa fa-plus"></i> Create Task
        </a>
}
      </div>
      <div class="">
        @if (showSearch) {
<app-navbar-search></app-navbar-search>
}
      </div>
    </div>
  </mat-toolbar>
}

  <mat-sidenav-container [id]="id" class="example-sidenav-container"
    [style.paddingTop.px]="mobileQuery.matches ? 16 : 0">
    <mat-sidenav class="side-w" mobileClass="mobile" #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
     [opened]="!uS.isMobile&&showMenu" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" [hidden]="!showMenu">
      <div class="p-3">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
          <!-- This is the tree node template for leaf nodes -->
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="expandable-node">
            <div class="mat-tree-node">
              <button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="menu-btn level{{node.level}}">
                <div class="row">
                  <div class="col">
                    <span class="{{node.icon}}"></span> {{node.label|appTranslate|async}}
                  </div>
                  <div class="col-auto">
                    <span class="fa {{treeControl.isExpanded(node) ? 'fa-chevron-down':'fa-chevron-right'}} ">
                    </span>
                  </div>
                </div>
              </button>
            </div>
            <div>
              <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
              <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group" class="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </div>
          </mat-nested-tree-node>
          <!-- There is inline padding applied to this node using styles.
    This padding value depends on the mat-icon-button width. -->
          <mat-tree-node *matTreeNodeDef="let node;when shouldShow" matTreeNodeToggle
            class="single-node level{{node.level}}">
            <a [routerLink]="node.link" class="d-block w-100">
              <span class="{{node.icon}}"></span> {{node.label|appTranslate|async}}
            </a>
            <!-- <a [mrouterLink]="node.link">
                <span class="{{node.icon}}"></span> {{node.label|appTranslate|async}}
              </a> -->
          </mat-tree-node>
          <mat-tree-node *matTreeNodeDef="let node;" matTreeNodeToggle
            class="single-node restricted level{{node.level}}">
            <!-- <a [mrouterLink]="node.link">
                <span class="{{node.icon}}"></span> {{node.label|appTranslate|async}}
              </a> -->
          </mat-tree-node>
        </mat-tree>

      </div>
      @if (isHome) {

        <div class="profile">
          <button class="w-100" mat-button [matMenuTriggerFor]="aboveMenu"><img alt="Avatar" class="avatar me-2" imageType="pp"
              [imageLoader]=" user?.users?.imageUrl "> {{user?.users?.firstName}} {{user?.users?.lastName}}</button>
          <mat-menu #aboveMenu="matMenu" [xPosition]="'after'"  class="profile  glass ">
            <button mat-menu-item routerLink="profile">
              <a class="pointer">{{'Profile'|appTranslate|async}}</a>
            </button>
            <button mat-menu-item (click)="signOut()"><a class=" pointer">{{'Sign out'|appTranslate|async}}</a></button>
            <button mat-menu-item><a class="d-block" href="{{helpLink}}"
                target="_blank">{{'Help'|appTranslate|async}}</a></button>
          </mat-menu>
        </div>
      
}
    </mat-sidenav>

    <mat-sidenav-content class=" ">
      <ng-content>
      </ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>