import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@Shared/pipes/translate.pipe';

@Component({
    selector: 'translator-case',
    imports: [CommonModule, TranslatePipe],
    templateUrl: './translator-case.component.html',
    styleUrls: ['./translator-case.component.scss']
})
export class TranslatorCaseComponent {
  @Input() text: string;
}
