import { Component, OnInit, computed, signal, viewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AppService } from '@Services/app.service';
import { MenuItem } from '@Shared/models/IMenuItem';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { UtilityService } from '@Services/utility.service';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { NavbarSearchComponent } from '@Shared/components/search/search-components/navbar-search/navbar-search.component';

import { FilterMenuArrayPipe } from '@Shared/pipes/utility.pipe';
import { TranslatePipe } from '../../../Shared/pipes/translate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { PSDirective, ImageLoaderDirective } from '../../../Shared/directives/index.directive';
import { NavigationService } from '../navigation.service';

@Component({
    selector: 'app-bottom-nav',
    templateUrl: './bottom-nav.component.html',
    styleUrls: ['./bottom-nav.component.scss'],
    imports: [
        PSDirective,
        NgIf,
        NgFor,
        MenuItemComponent,
        MatTooltipModule,
        ImageLoaderDirective,
        RouterLink,
        AsyncPipe,
        FilterMenuArrayPipe,
        TranslatePipe,
    ]
})
export class BottomNavComponent implements OnInit {
  // navColor = '';
  // type = '';
  // appRoutes = environment.appRoutes;

  // id = 71;
  readonly userFullName = computed(() => this.authS.userProfile()?.users?.fullName);
  readonly userImageUrl = computed(() => this.authS.userProfile()?.users?.imageUrl);

  // menu: MenuItem[] = [];
  readonly helpLink = signal(environment.helpLink);
  searchRef = viewChild<NavbarSearchComponent>('search');
  constructor(
    protected router: Router,
    protected appS: AppService,
    protected navigationService: NavigationService,
    protected authS: AuthenticationService,
    protected uS: UtilityService,
  ) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {}
  signOut() {
    this.uS.startLoader();
    this.authS.logout().then(() => this.router.navigateByUrl('/'));
  }
  tractById = (index, item: MenuItem) => {
    return item.id;
  };
}
