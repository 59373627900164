<div class="row">
  <modal-header [header]="'Create New Client'" [dialogRef]="dialogRef" />
  <div class="row mt-2">
    @if (type != 'C') {
      <div class="col-md-4">
        <app-input-basic
          type="text"
          label="First Name"
          [required]="true"
          [form]="form.controls.personalInformation"
          name="firstName"
          [showValidationMsg]="true"
          #firstName></app-input-basic>
        <!-- <app-validation-message [control]="firstName.control" [minLength]="minNameLength" label="First name">
        </app-validation-message> -->
      </div>
    }
    @if (type != 'C') {
      <div class="col-md-4">
        <app-input-basic
          type="text"
          label="Surname"
          [required]="true"
          [form]="form.controls.personalInformation"
          name="surname"
          [showValidationMsg]="true"
          #surname></app-input-basic>
        <!-- <app-validation-message [control]="surname.control" [minLength]="minNameLength" label="Surname">
        </app-validation-message> -->
      </div>
    }
    @if (type != 'C') {
      <div class="col-md-4">
        <app-input-basic
          type="text"
          label="Middle Name"
          [form]="form.controls.personalInformation"
          name="middleName"
          [showValidationMsg]="true"
          #middleName></app-input-basic>
      </div>
    }
    @if (type === 'C') {
      <div class="col-md-4">
        <app-input-basic
          type="text"
          label="Name"
          [required]="true"
          [form]="form.controls.personalInformation"
          name="fullName"
          [showValidationMsg]="true"></app-input-basic>
        <!-- <app-validation-message [control]="firstName.control" [minLength]="minNameLength" label="First name">
        </app-validation-message> -->
      </div>
    }
    @if (type === 'C') {
      <div class="col-md-4">
        <app-input-basic
          type="text"
          label="Company redg no"
          [form]="form.controls.personalInformation"
          name="companyRedgNo"></app-input-basic>
        <!-- <app-validation-message [control]="surname.control" [minLength]="minNameLength" label="Surname">
        </app-validation-message> -->
      </div>
    }
    @if (type === 'C') {
      <div class="col-md-4">
        <app-autocomplete
          label="Country of Registration"
          [form]="form.controls.personalInformation"
          name="country"
          [options]="countryList"
          valueField="code"
          labelType="cd" />
      </div>
    }
    @if (type === 'C') {
      <div class="col-md-4">
        <app-autocomplete
          label="Segment"
          [form]="form.controls.personalInformation"
          name="segment"
          [options]="segmentList"
          valueField="code"
          labelType="ct" />
        <!-- <app-validation-message [control]="firstName.control" [minLength]="minNameLength" label="First name">
        </app-validation-message> -->
      </div>
    }
    @if (type === 'C') {
      <div class="col-md-4">
        <app-input-basic
          type="text"
          label="Website"
          [form]="form.controls.personalInformation"
          name="website"
          placeholder="www.example.com"
          [noFormat]="true"></app-input-basic>
        <!-- <app-validation-message [control]="surname.control" [minLength]="minNameLength" label="Surname">
        </app-validation-message> -->
      </div>
    }
    @if (type === 'C') {
      <div class="col-md-4">
        <app-autocomplete
          label="Sector"
          [form]="form.controls.personalInformation"
          name="sector"
          [showValidationMsg]="true"
          [options]="sectorList"
          valueField="code"
          labelType="ct" />
      </div>
    }
    <div class="col-md-4">
      <app-input-basic
        type="email"
        label="Email"
        [form]="form.controls.personalInformation"
        name="email"
        [showValidationMsg]="true"
        #email></app-input-basic>
    </div>
    <div class="col-md-4">
      <app-phone-number
        name="phoneNumber"
        label="Phone Number"
        [showValidation]="pn.invalidSignal()"
        [showValidationIcon]="false"
        [showValidationMsg]="true"
        [form]="form.controls.personalInformation"
        #pn></app-phone-number>
    </div>
    <div class="col-md-4">
      <app-input-basic
        type="text"
        label="Address"
        [placeholder]="uS.config.FormPresets.addressPlaceholder"
        [form]="form.controls.personalInformation"
        name="address"
        [showValidationMsg]="true"
        #addressTag></app-input-basic>
    </div>
    <!-- <div *ngIf="type != 'C'" class="col-md-4">
            <app-autocomplete type="select" label='Relationship' [form]="form.controls.personalInformation"
                name='relationship' [options]="cS.getCodesByCodeSubGroup|functionCaller1:'CLIENT_RELATED_TYPE'|async"
                valueField="code" labelType="ct" #relationshipTag [showValidationMsg]="true" />
        </div> -->
    @if (data && type != 'C') {
      <div class="col-md-4">
        <app-input-basic
          type="date"
          label="Date Of Birth"
          [form]="form.controls.personalInformation"
          name="dateOfBirth"
          [showValidationMsg]="true"></app-input-basic>
      </div>
    }
    @if (type != 'C') {
      <div class="col-md-4">
        <app-autocomplete
          type="select"
          label="Gender"
          [form]="form.controls.personalInformation"
          name="gender"
          [options]="cS.getCodesByCodeSubGroup | functionCaller1: 'GENDER' | async"
          valueField="code"
          labelType="ct"
          #gender
          [showValidationMsg]="true" />
      </div>
    }
    @if (type != 'C') {
      <div class="col-md-4">
        <app-input-basic
          type="text"
          label="National Insurance Number"
          [form]="form.controls.identification"
          [minLength]="ninLength"
          [maxLength]="ninLength"
          name="nationalInsuranceNumber"
          [showValidationMsg]="true" />
      </div>
    }
    <div class="col-md-4">
      <app-autocomplete
        label="Source Category"
        [form]="form.controls.personalInformation"
        name="sourceCat"
        [options]="cS.getCodesByCodeSubGroup | functionCaller1: 'CLIENT_SOURCE_CAT' | async"
        valueField="code"
        labelType="ct"
        #source />
    </div>
    @switch (source.valueSignal()) {
      <!-- @case ('DR') {
        <div class="col-md-4">
          <app-input-basic
            [disabled]="true"
            type="text"
            label="Source"
            [form]="form.controls.personalInformation"
            name="source" />
        </div>
      } -->
      @case ('AG') {
        <div class="col-md-4">
          <app-autocomplete
            labelType="ct"
            valueField="code"
            [optionsFunc]="agentS.searchByName"
            [form]="form.controls.personalInformation"
            hint="Search Agents by Name"
            name="source"
            label="Source" />
        </div>
      }
      @case ('RM') {
        <div class="col-md-4">
          <app-autocomplete
            label="Source"
            [form]="form.controls.personalInformation"
            name="source"
            [optionsFunc]="userS.searchRelManagerByName"
            hint="Search Relationship Managers by Name"
            valueField="userName"
            labelType="uf" />
        </div>
      }
      @case ('TM') {
        <div class="col-md-4">
          <app-autocomplete
            label="Source"
            [form]="form.controls.personalInformation"
            name="source"
            [optionsFunc]="userS.searchTelemarketerByName"
            hint="Search Telemarketers by Name"
            valueField="userName"
            labelType="uf" />
        </div>
      }
      @case ('RF') {
        <div class="col-md-4">
          <app-autocomplete
            label="Source"
            [form]="form.controls.personalInformation"
            name="source"
            [optionsFunc]="clientS.searchClientsByName"
            hint="Search Clients by Name"
            valueField="code"
            labelType="ct" />
        </div>
      }
    }
  </div>
  <div class="mt-3">
    <div class="row justify-content-end">
      <div class="col-auto">
        <app-btn
          group="submit"
          [form]="form"
          (mclick)="type != 'C' ? submitIndividual() : submitCorporate()"
          [loading]="loading" />
      </div>
      <div class="col-12">
        <form-error [form]="form" />
      </div>
    </div>
  </div>
</div>
