import { EVFunctions } from 'ets-fe-ng-sdk';
import { uniqBy } from 'lodash-es';
import { EClientType } from 'projects/evolutics-client-ui/src/app/Life/client-desk/client-extras/client.interface';

export interface IClientViewData {
  address: string;
  age?: number;
  ageGroup?: any;
  agentNo: string;
  alternativeEmail: string;
  alternativePhoneNumber: string;
  annualIncomeBand?: any;
  band: string;
  bankNo?: any;
  bmi: string;
  busLine?: any;
  category: string;
  clientNo: string;
  crmClientNo: string;
  clv: string;
  prefCommMethod: string;
  companyRedgNo?: any;
  country?: any;
  countryAddressRegion?: any;
  createdBy?: any;
  crmId: string;
  createdOn: string;
  dateOfBirth: string;
  email: string;
  employer?: any;
  enroleeNo: string;
  enroleeSuffix: string;
  financialSizeCategory?: any;
  firstName: string;
  fullName: string;
  gender: string;
  incomeBand?: string;
  kyc?: any;
  language: string;
  latestDocId: string;
  mailAddRegion?: any;
  maritalStatus: string;
  middleName: string;
  noOfEmployee?: any;
  noOfGroups?: any;
  occupationGroup: string;
  phoneNumber: string;
  providerNo: string[];
  salutation?: any;
  sector: string;
  segment?: any;
  socialMedia?: any;
  source?: any;
  state?: any;
  statusCode: string;
  surname: string;
  title: string;
  town?: any;
  sumAtRisk: any;
  type: EClientType;
  userName: string;
  website?: any;
  // countData: CountData;
  clientContact: ClientContact;
  clientDetails: ClientDetails;
  clientDates: ClientDates;
  categoryList: CategoryList[];
  statusList: StatusList;
  providerList: ProviderList[];
  occupation: Occupation;
}
export class ClientViewData implements IClientViewData {
  address: string;
  age?: number;
  ageGroup?: any;
  agentNo: string;
  alternativeEmail: string;
  alternativePhoneNumber: string;
  annualIncomeBand?: any;
  band: string;
  bmi: string;
  busLine?: any;
  category: string;
  clientNo: string;
  crmClientNo: string;
  clv: string;
  prefCommMethod: string;
  companyRedgDate?: string;
  companyRedgNo?: any;
  country?: any;
  countryAddressRegion?: any;
  createdBy?: any;
  crmId: string;
  createdOn: string;
  dateOfBirth: string;
  email: string;
  employer?: any;
  incomeBand?: string;
  enroleeNo: string;
  enroleeSuffix: string;
  financialSizeCategory?: any;
  firstName: string;
  fullName: string;
  gender: string;
  kyc?: any;
  language: string;
  latestDocId: string;
  mailAddRegion?: any;
  maritalStatus: string;
  middleName: string;
  dateOfDeath?: string;
  placeOfBirth?: string;
  causeDeath?: string;
  educationLevel?: string;
  retirementDate?: string;
  noOfEmployee?: any;
  _occupation: string;
  occupationGroup: string;
  phoneNumber: string;
  providerNo: string[];
  salutation?: any;
  sector: string;
  segment?: any;
  socialMedia?: any;
  source?: any;
  state?: any;
  statusCode: string;
  surname: string;
  tax?: any;
  title: string;
  town?: any;
  type: EClientType;
  userName: string;
  website?: any;
  // countData: CountData;
  clientContact: ClientContact;
  clientDetails: ClientDetails;
  clientDates: ClientDates;
  categoryList: CategoryList[];
  statusList: StatusList;
  providerList: ProviderList[];
  occupation: Occupation;
  groupNos: IGroupNo[];
  sumAtRisk: any;

  get noOfGroups() {
    return 0;
  }
  get groupNo() {
    return this.groupNos?.map((x) => x.groupNo)?.join('; ');
  }
  get bankNo() {
    return 0;
  }
  get providerCode() {
    return this.providerList[0]?.no;
  }
  get providerCategory() {
    return this.providerList[0]?.providerCategory;
  }
  get clientType() {
    return this.type;
  }
  get communication() {
    return this.clientContact?.callWithdraw;
  }
  get redgNo() {
    return this.companyRedgNo;
  }
  get dateCreated() {
    return this.createdOn;
  }
  constructor(iObj: IClientNewViewData) {
    Object.assign(this, iObj);
    this.occupationGroup = iObj.occupation?.occupationGroup;
    this.incomeBand = iObj.occupation?.incomeBand;
    this._occupation = iObj.occupation?.occupation;
    this.category = uniqBy(this.categoryList, 'category')
      .map((x) => x.category)
      .join('/');
    this.fullName =
      this?.fullName?.removeNull() ||
      EVFunctions.strConcatenator(
        EVFunctions.strConcatenator(this.firstName, this.middleName).label,
        this.surname,
      ).label;
    this.fullName = this.fullName?.trim()?.replace('  ', ' ');
    this.age = 0;
    this.sumAtRisk = 0;
    if (this.clientDates?.companyRedgDate)
      this.clientDates.companyRedgDate = this.clientDates.companyRedgDate.split('T')[0];
    if (this.clientDates?.wedAnnivDt) this.clientDates.wedAnnivDt = this.clientDates.wedAnnivDt.split('T')[0];
    const keysToCheck = ['clientContact', 'clientDetails', 'clientDates'];
    const keysToIgnore = ['createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'clientNo', 'id'];
    for (const k of keysToCheck) {
      const cObject = this[k];
      for (const key in cObject) {
        if (this[key] || keysToIgnore.includes(key)) continue;
        else {
          // debugger;
          this[key] = cObject[key];
        }
      }
    }
  }
}
export interface IClientNewViewData {
  active: boolean;
  alpha?: any;
  authorizedBy?: any;
  bmi: number;
  busLine?: any;
  clientNo: string;
  companyCode?: any;
  companyRedgNo?: any;
  createdBy?: any;
  createdOn: string;
  crmClientNo?: any;
  emailAdd1?: any;
  externalRef?: any;
  externalRef2?: any;
  firstName: string;
  fullName: string;
  gender: string;
  id: number;
  language: string;
  maidenName?: any;
  middleName: string;
  motherMaidenName?: any;
  phoneNo1?: any;
  rowId?: any;
  salutation?: any;
  source?: any;
  sourceCat?: any;
  statusCode: string;
  surname: string;
  title: string;
  type: string;
  webApproval?: any;
  countData: CountData;
  clientContact: ClientContact;
  clientDetails: ClientDetails;
  clientDates: ClientDates;
  categoryList: CategoryList[];
  statusList: StatusList;
  providerList: ProviderList[];
  occupation: Occupation;
}

interface Occupation {
  id: number;
  employStatus?: any;
  companyName?: any;
  employerSector?: any;
  employerClientNo: string;
  incomeBand: string;
  occupationGroup: string;
  occupation: string;
  employmentDate: string;
  educationLevel?: any;
  clientNo: string;
  createdOn?: any;
  createdBy?: any;
  cadre?: any;
  staffNo: string;
  jobTitle: string;
  active: boolean;
}

interface ProviderList {
  id: number;
  clientNo: string;
  no: string;
  busLine?: string;
  type?: string;
  providerCategory: string;
  providerSubCategory: string;
  network?: any;
  disciplines?: any;
  branches?: any;
  status?: string;
  tariffCode?: string;
  facilityDescription?: any;
  fullName?: string;
}

interface StatusList {
  clientStatus: string;
  agentStatus: string;
  providerStatus: string;
}

interface CategoryList {
  clientNo: string;
  crmClientNo?: any;
  busLine?: string;
  category: string;
  clientId: number;
  id: number;
  createdBy?: any;
  createdOn: string;
}

interface ClientDates {
  id: number;
  clientNo: string;
  ageConfirmed?: any;
  wedAnnivDt?: string;
  companyRedgDate?: string;
  createdOn: string;
  lastBdayGreetCall?: string;
  createdBy?: string;
  updatedOn: string;
  dateOfBirth: string;
}

interface ClientDetails {
  id: number;
  clientNo: string;
  crmClientNo?: any;
  annIncBand?: any;
  height?: any;
  weight?: any;
  bmi?: any;
  maritalStatus: string;
  sector?: any;
  countryOfRegistration?: any;
  segment?: any;
  placeOfBirth?: any;
  powerOfAttorney?: any;
  clientBandBasis?: any;
  clvCalcBasis?: any;
  voiceRecog?: any;
  clv?: any;
  band?: any;
  ownerClientNo?: any;
  coverCode?: any;
  asar?: any;
  isar?: any;
  arsar?: any;
  effDate?: any;
  ageGroup?: any;
  engageScore?: any;
  createdOn?: any;
  createdBy?: any;
  updatedOn: string;
  updatedBy: string;
}

interface ClientContact {
  id: number;
  clientNo: string;
  crmClientNo?: any;
  phoneNumber: string;
  alternativePhoneNumber: string;
  alternativePhoneNumber3?: any;
  alternativePhoneNumber4?: any;
  website?: any;
  callWithdraw: boolean;
  marketingWithdraw: boolean;
  prefCommMethod: string;
  email: string;
  alternativeEmail: string;
  mailAdd?: any;
  mailAddPostcode?: any;
  mailAddTown?: any;
  mailAddCity?: any;
  mailAddCountry?: any;
  address: string;
  resideAddPostcode?: any;
  town: string;
  state: string;
  country: string;
  region?: any;
  geolocation?: any;
  mailAddRegion?: any;
}

interface CountData {
  noOfBanks: number;
  noOfEmployers: number;
  noOfSocialMedias: number;
  noOfPolicies: number;
  noOfRelationships: number;
  noOfPendingQuotes: number;
  noOfPendingPayouts: number;
  noOfPendingClaims: number;
  age: number;
  sumAtRisk: number;

  noOfGroups: number;
}

interface IGroupNo {
  groupNo: string;
}
