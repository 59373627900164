import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IconType } from '@Shared/models/index.model'; 
import { SVGIconType } from './svg-icon.model';
import { SvgIconService } from './svg-icon.service';

@Component({
    selector: 'svg-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss'],
    standalone: true
})
export class SvgIconComponent implements OnInit {
  @Input() class: string = 'svg-icon';
  config = { color: `currentColor`, onhover: `var(--primary)` };
  icon: string;
  @Input('icon') set _icon(v: SVGIconType) {
    this.icon = this.svgIconS.getSVG(v).icon;
    if (this.icon)
      this.icon = this.icon.replaceAllSubStr('{{color}}', this.config.color);
    this.ngAfterViewInit();
  }
  @ViewChild('iconTag') iconRef: ElementRef<HTMLSpanElement>;
  constructor(public svgIconS: SvgIconService) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    if (this.icon && this.iconRef?.nativeElement)
      this.iconRef.nativeElement.innerHTML = this.icon;
  }
}