import { KeyValue } from '@angular/common';
import { IGetQuery, KVP, SortType } from '@Shared/models/index.model';

export interface IClientUniqueObj extends IGetQuery {
  alternativeEmail?: string;
  alternativePhoneNumber?: string;
  bvn?: string;
  coyRegdNo?: string;
  email?: string;
  idNumber?: string;
  idType?: string;
  nationalInsuranceNumber?: string;
  pageNumber?: number;
  pageSize?: number;
  pensionCommissionNumber?: string;
  phoneNumber?: string;
  sortBy?: string;
  sortDirection?: SortType;
  tin?: string;
  website?: string;
}
export interface IClientSearchObj extends IGetQuery<IClientSearchResult> {
  createDateFrom?: string;
  createDateTo?: string;
  createdBy?: string;
  email?: string;
  externalRef?: string;
  name?: string;
  phoneNo?: string;
}

export enum EClientType {
  individual = 'I',
  corporate = 'C',
  provider = 'P',
}
export const ConfigClientTypes: KeyValue<EClientType, string>[] = [
  { key: EClientType.individual, value: 'Individual' },
  { key: EClientType.corporate, value: 'Corporate' },
];
export interface IClientSearchResult {
  authorizedBy?: string;
  busLine: string;
  clientNo: string;
  compstringCode?: string;
  compstringRedgNo: string;
  countryOfBirth?: string;
  createdOn: string;
  crmClientNo?: string;
  externalRef?: string;
  externalRef2?: string;
  firstName: string;
  fullName: string;
  providerNo: string;
  primaryCompany: string;
  gender: string;
  id: number;
  language?: string;
  maidenName?: string;
  middleName: string;
  motherMaidenName?: string;
  salutation?: string;
  source?: string;
  statusCode?: string;
  surname: string;
  title?: string;
  type: EClientType;
  webApproval?: boolean;
}
export interface IValidation {
  statusCode?: number;
  message?: string;
  response: boolean;
}
export interface IClientEmployer {
  id: number;
  employStatus?: string;
  companyName?: string;
  employerSector?: string;
  employerClientNo: string;
  incomeBand: string;
  occupationGroup: string;
  occupation: string;
  employmentDate: string;
  educationLevel?: any;
  clientNo: string;
  grade: string;
  rank: string;
  ippis: string;
  createdOn?: any;
  createdBy?: any;
  cadre?: any;
  staffNo: string;
  jobTitle: string;
  active: boolean;
}

export interface IClientDateInfo {
  ageConfirmed: boolean;
  _age?: string;
  companyRedgDate: string;
  createdBy: string;
  createdOn: string;
  dateOfBirth: string;
  dateOfDeath: string;
  lastBdayGreetCall: boolean;
  retirementDate: string;
  updatedOn: string;
  wedAnnivDt: string;
}

export interface IClientContactInfo {
  address: string;
  alternativeEmail: string;
  alternativePhoneNumber: string;
  alternativePhoneNumber3: string;
  alternativePhoneNumber4: string;
  callWithdraw: boolean;
  country: string;
  createdBy: string;
  crmClientNo: string;
  email: string;
  geolocation: string;
  mailAdd: string;
  mailAddCity: string;
  mailAddCountry: string;
  mailAddPostcode: string;
  mailAddRegion: string;
  mailAddTown: string;
  marketingWithdraw: boolean;
  phoneNumber: string;
  prefCommMethod: string;
  region: string;
  resideAddPostcode: string;
  state: string;
  town: string;
  website: string;
}

export interface IClientDetails {
  ageGroup: string;
  annIncBand: string;
  arsar: number;
  asar: number;
  band: string;
  bmi: number;
  causeDeath: string;
  clientBandBasis: string;
  clv: string;
  clvCalcBasis: string;
  countryOfRegistration: string;
  coverCode: string;
  createdBy: string;
  createdOn: string;
  crmClientNo: string;
  educationLevel: string;
  effDate: string;
  engageScore: string;
  height: number;
  isar: number;
  maritalStatus: string;
  ownerClientNo: string;
  pep: boolean;
  placeOfBirth: string;
  powerOfAttorney: string;
  religion: string;
  sector: string;
  clientNo: string;
  segment: string;
  updatedBy: string;
  updatedOn: string;
  voiceRecog: boolean;
  weight: number;
}

export interface IClientTax {
  id: number;
  clientNo: string;

  createdBy: string;
  createdOn: string;
  taxCountry1: string;
  taxCountry2: string;
  taxIdentificationNumber: string;
  taxResidentCountry1: string;
  taxResidentCountry2: string;
  ukTaxStatus: string;
  usIdExpiryDate: string;
  usIdIssueDate: string;
  usIdNumber: string;
  usTaxPayorStatus: string;
  usTaxStatus: string;
}

export interface IClientEmployment {
  id: number;
  active: true;
  cadre: string;
  companyName: string;
  createdBy: string;
  createdOn: string;
  educationLevel: string;
  employStatus: string;
  employerClientNo: string;
  employerSector: string;
  employmentDate: string;
  grade: string;
  incomeBand: string;
  ippis: string;
  jobTitle: string;
  occupation: string;
  occupationGroup: string;
  rank: string;
  retirementDate: string;
  staffNo: string;
}

export interface IClientKYC {
  active: boolean;
  addressVerified: boolean;
  bankNoVerified: boolean;
  bvn: string;
  complianceCheck: boolean;
  createdBy: string;
  crmClientNo: string;
  emailVerified: boolean;
  idExpiryDate: string;
  idIssueAge: number;
  idIssueAuth: string;
  idIssueDate: string;
  idIssuePlace: string;
  idNumber: string;
  idType: string;
  idVerified: boolean;
  intPassNo: string;
  kycFormVerified: boolean;
  nationalInsuranceNumber: string;
  nationality: string;
  passportVerified: boolean;
  pensionCommissionNumber: string;
  phoneNoVerified: boolean;
  redgNoVerified: boolean;
  secondNationality: string;
  signatureVerified: boolean;
  socialSecurityNo: string;
  valid: boolean;
  id: number;
  clientNo: string;
}

export interface IClientSocialMedia {
  active?: boolean;
  clientId?: number;
  platform: string;
  username: string;
}

export interface IClientBank {
  accountNo?: string;
  accountName?: string;
  accountType: string;
  bankCode: string;
  bankName: string;
  blocked: boolean;
}

export enum EClientSection {
  basic = 'basic',
  contact = 'Contact',
  date = 'Date',
  health = 'Health',
  tax = 'Tax',
  additional = 'Additional',
  employment = 'Employment',
  kyc = 'KYC',
  bank = 'Bank',
  social = 'Social Media',
}

export enum EProviderSection {
  contact = 'Contact',
  contactPerson = 'Contact Person',
  payment = 'Payment',
}
