import { computed, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  readonly svgIcons = computed<EIcon[]>(() => Object.keys(Icons).sort() as EIcon[]);
  readonly svgIconsIndex = computed(() => {
    const map: Record<EIcon, boolean> = {} as Record<EIcon, boolean>;
    this.svgIcons().forEach((i) => (map[i] = true));
    return map;
  });

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.svgIcons().forEach((icon) =>
      iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)),
    );
  }

  isRegistered(icon: string) {
    return this.svgIconsIndex()[icon as EIcon];
  }

  isClass(icon: EIcon): { isClass: boolean; class?: string } {
    const cls = Icons[icon];
    return typeof cls == 'string' ? { isClass: true, class: cls } : { isClass: false };
  }
}

const Icons = {
  actuarial: true,
  analytics: true,
  crm: true,
  erm: true,
  finance: true,
  general: true,
  health: true,
  human: true,
  life: true,
  dbcloner: true,
  process: true,
  edit: 'fa fa-pencil',
};

export type EIcon = keyof typeof Icons;
