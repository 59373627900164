<span class="d-flex align-items-center" [matTooltip]="text|appTranslate|async">
  @if (left && text) {
  <i class="fa fa-info-circle me-2" [ngClass]="{'text-primary':coloured}"></i>
  }
  <span>
    <ng-content></ng-content>
  </span>
  @if (!left && text) {
  <i class="fa fa-info-circle ms-2" [ngClass]="{'text-primary':coloured}" ></i>
  }
</span>