import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalFormLayoutComponent } from './modal-form-layout.component';
import { SharedModule } from '@Shared/shared.module';
import { NavigationModule } from '../navigation/navigation.module';


@NgModule({
    imports: [CommonModule, NavigationModule, ModalFormLayoutComponent],
    exports: [ModalFormLayoutComponent]
})
export class ModalFormLayoutModule {}
