<modal-header header="Add Document" [dialogRef]="dialogRef" />
<loader [loading]="loading" [height]="case.offsetHeight">
  <div class="p-2" #case>
    <div class="">
      <div class="meta form-label mb-2">
        @if (file) {
          <div class="row align-items-start mb-1">
            <div class="col">
              <div class="hide-scroll file-name text-primary">
                {{ file?.name }}
              </div>
            </div>
            @if (file) {
              <div class="col-auto text-end">
                <span class="text-danger pointer p-1" (click)="removeFile()">x</span>
              </div>
            }
          </div>
        }
      </div>
      <input type="file" class="inputform" hidden (change)="upload($event)" #inputFile />
      <app-btn
        icon="upload"
        (mclick)="inputFile.click()"
        [type]="file ? 'primary' : 'secondary'"
        text="Select File"></app-btn>
    </div>
    <div class="mt-3">
      <app-input-basic
        label="Title"
        type="text"
        name="title"
        [form]="form"
        [stacked]="true"
        [showValidationMsg]="true" />
    </div>
    @if (!(form.value.category && hideInputWithValue)) {
      <div class="">
        <app-autocomplete
          label="Category"
          type="select"
          name="category"
          [form]="form"
          [stacked]="true"
          [options]="docS.getCategoryList | functionCaller | async"
          labelType="ct"
          valueField="code"
          [showValidation]="cat.invalidSignal()"
          [showValidationIcon]="false"
          #cat
          [showValidationMsg]="true" />
      </div>
    }
    @if (!(form.value.subCategory && hideInputWithValue)) {
      <div class="">
        <app-autocomplete
          label="Sub Category"
          type="select"
          name="subCategory"
          [form]="form"
          [stacked]="true"
          [options]="docS.getSubCategoryList | functionCaller1: form.value.category | async"
          labelType="ct"
          valueField="code"
          [showValidation]="subCat.invalidSignal()"
          [showValidationIcon]="false"
          #subCat
          [showValidationMsg]="true" />
      </div>
    }
    <div class="">
      <app-autocomplete
        label="Sensitivity"
        type="select"
        name="sensitivity"
        [form]="form"
        [stacked]="true"
        [options]="sensitivities"
        labelType="ct"
        valueField="code"
        [disabled]="true"
        [showValidationMsg]="true" />
    </div>
    <!-- <div class="">
            <label>Branch</label><br>
            <select formControlName="branch" class="form-control  control-bg-gray" required>
              <option [value]="null">Select Branch</option>
              <option [value]="item.code" *ngFor="let item of branches">{{item.code}} - {{item.description}}</option>
            </select>
          </div> -->

    <div class="">
      <app-input-basic
        label="Box No"
        type="text"
        name="boxNo"
        [form]="form"
        [stacked]="true"
        [showValidationMsg]="true" />
    </div>
    @if (allowUserTagging()) {
      <div class="mt-3">
        <app-autocomplete
          label="User"
          type="text"
          name="user"
          [form]="form"
          [stacked]="true"
          [options]="userS.getAllUserCodeAndFullName | functionCaller | async"
          labelType="uf"
          valueField="userName"
          #user
          [showValidationMsg]="true" />
      </div>
    }
  </div>
  <mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
      <div class="">
        <app-btn
          icon="users"
          (mclick)="tagUser()"
          [type]="form.value.user ? 'primary' : 'secondary'"
          text="Tag User"></app-btn>
      </div>
      <div class=" ">
        <app-btn
          type="primary"
          text="Upload"
          icon="upload"
          [form]="form"
          [disabled]="!file"
          (mclick)="onSubmit()"></app-btn>
      </div>
    </div>
  </mat-dialog-actions>
</loader>
