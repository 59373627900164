import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardAdminComponent } from './dashboard-admin.component';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';



@NgModule({
    exports: [DashboardAdminComponent],
    imports: [CommonModule, NavigationModule, RouterModule, DashboardAdminComponent]
})
export class DashboardAdminModule {}
