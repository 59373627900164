<div class="container">
  <form [formGroup]="fc()" (ngSubmit)="onSubmit()">
    <div class="row mt-4 align-items-start">
      <div class="col-md-4">
        <app-autocomplete
          type="select"
          label="ID Type"
          [form]="fc()"
          name="idType"
          [stacked]="true"
          [options]="ccS.getIDTypes | functionCaller | async"
          labelType="ct"
          [showValidationMsg]="true"
          valueField="code" />
      </div>
      <div class="col-md-4">
        <label class="form-label text-primary">{{ 'ID Number' | appTranslate | async }}</label>
        <br />
        <input type="text" formControlName="idNumber" class="form-control control-bg-gray" />
        <app-validation-message [control]="validation?.idNumber" label="ID number"></app-validation-message>
      </div>
      <div class="col-md-4">
        <label class="form-label text-primary">{{ 'ID Issue Date' | appTranslate | async }}</label>
        <br />
        <app-input-basic
          [showLabel]="false"
          type="date"
          name="idIssueDate"
          [maxToday]="true"
          [form]="fc()"
          [showValidationMsg]="true" />
      </div>
      <div class="col-md-4">
        <label class="form-label text-primary">{{ 'ID Expiry date' | appTranslate | async }}</label>
        <br />
        <app-input-basic
          [showLabel]="false"
          type="date"
          name="idExpiryDate"
          [minToday]="true"
          [form]="fc()"
          [showValidationMsg]="true" />
      </div>
      <div class="col-md-4">
        <label class="form-label text-primary">
          {{ 'Tax Identification Numbers' | appTranslate | async }}
        </label>
        <br />
        <input type="text" formControlName="taxIdentificationNumber" class="form-control control-bg-gray" />
        <app-validation-message
          [control]="validation?.taxIdentificationNumber"
          label="TIN"></app-validation-message>
      </div>
      <div class="col-md-4">
        <label class="form-label text-primary">
          {{ 'National Insurance Number' | appTranslate | async }}
        </label>
        <br />
        <app-input-basic
          type="number"
          [noFormat]="true"
          [form]="fc()"
          name="nationalInsuranceNumber"
          label="National Insurance Number"
          [minLength]="ninLength"
          [maxLength]="ninLength"
          [showLabel]="false"
          [showValidationMsg]="true" />
      </div>
      <div class="col-md-4">
        <label class="form-label text-primary">{{ 'Bank Verification Number' | appTranslate | async }}</label>
        <br />
        <app-input-basic
          type="number"
          [noFormat]="true"
          name="bvn"
          [minLength]="11"
          [maxLength]="11"
          [form]="fc()"
          [showValidationMsg]="true"
          #bvnumber />
      </div>
      <div class="col-md-4">
        <label class="form-label text-primary">
          {{ 'Pension Commission Number' | appTranslate | async }}
        </label>
        <br />
        <app-input-basic
          type="text"
          [noFormat]="true"
          [form]="fc()"
          name="pensionCommissionNumber"
          [prefix]="penComPrefix"
          label="Pension Commission Number"
          [minLength]="penComLength"
          [maxLength]="penComLength"
          [showLabel]="false"
          [showValidationMsg]="true"
          #penNo></app-input-basic>
        <!-- <app-validation-message [input]="penNo" 
          [control]="validation?.pensionCommissionNumber" label="Pension Commission Number" [minLength]="penComLength"
          [maxLength]="penComLength">
        </app-validation-message> -->
      </div>
      <div class="col-md-4">
        <label class="form-label text-primary">
          {{ 'International Passport Number' | appTranslate | async }}
        </label>
        <br />
        <app-input-basic
          type="text"
          [noFormat]="true"
          [form]="fc()"
          name="intPassNo"
          [showLabel]="false"
          [showValidationMsg]="true"></app-input-basic>
        <!-- <app-validation-message [input]="penNo" 
          [control]="validation?.pensionCommissionNumber" label="Pension Commission Number" [minLength]="penComLength"
          [maxLength]="penComLength">
        </app-validation-message> -->
      </div>
      <div class="col-md-4">
        <app-file-upload
          label="Upload Passport Photo"
          accept="image"
          [(file)]="passportFile"></app-file-upload>
      </div>
      <div class="col-md-4">
        <app-file-upload label="Upload Identity Card" accept="image" [(file)]="idcardFile"></app-file-upload>
      </div>
    </div>
  </form>
</div>
