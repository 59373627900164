<loader [loading]="loading">
  @if (schema) {
    <app-find-item
      label="Client"
      id="client"
      orderField="createdOn"
      orderDirection="desc"
      [autoOrder]="false"
      [formSchema]="schema"
      [displayedColumns]="displayedColumns"
      [showAdditionalColumns]="true"
      [isCompact]="true"
      [searchObservableFunc]="search"></app-find-item>
  }
</loader>
