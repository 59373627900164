import { computed, Injectable } from '@angular/core';
import { AuthenticationService } from '../../Authentication/auth-extras/authentication.service';
import { Router } from '@angular/router';
import { UtilityService } from '../../Services/utility.service';
import { environment } from '../../../environments/environment';
import { MenuItem } from '../../Shared/models/IMenuItem';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  readonly profileMenuButtons = computed<Partial<MenuItem>[]>(() => [
    { label: 'Profile', link: 'profile' },
    { label: 'Sign In Sessions', link: 'login-sessions' },
    { label: 'Log Errors & Request Features', link: 'admin-desk/evolutics/log-errors-and-request-features' },
    {
      label: 'Sign Out',
      action: () => {
        this.uS.startLoader();
        this.authS.logout().then(() => this.router.navigateByUrl('/'));
      },
    },
    { label: 'Help', link: environment.helpLink },
  ]);

  constructor(
    public uS: UtilityService,
    public router: Router,
    protected authS: AuthenticationService,
  ) {}
}
