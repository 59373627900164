import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { GetValueLabel } from 'ets-fe-ng-sdk';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'form-link',
    templateUrl: './form-link.component.html',
    styleUrls: ['./form-link.component.scss'],
    imports: [RouterLink, GetValueLabel]
})
export class FormLinkComponent implements OnInit {
  @Input() cqueryParams: { [x: string]: string };
  @Input() crouterLink: string;
  @Input() value: string;
  @Input() options: any[];
  @Input() labelField: string;
  @Input() valueField: string;
  constructor() {}

  ngOnInit(): void {}
}
