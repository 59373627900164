import { CommonModule } from '@angular/common';
import { Directive, ElementRef, Input, NgModule, booleanAttribute } from '@angular/core';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';
import { EPageBtnID } from '../models/IMenuItem';
import { AppService } from '@Services/app.service';

@Directive({
  selector: '[pmV]',
  standalone: true,
})
export class PermissionManagerViewDirective {
  slug: string;
  @Input({ alias: 'permissionDebug', transform: booleanAttribute }) debug: boolean;
  @Input() parentPM: string;
  @Input('pmV') set pm(v: EPageBtnID | string) {
    if (this.debug) debugger;
    this.slug = v;
    if (!PermissionManager.pagePermission) return;
    const res = PermissionManager.selectFromComplexMap(
      v,
      this.parentPM || PermissionManager.pagePermission.slug,
      this.appS.system
    );
    if (!res?.viewAccess) this.ef.nativeElement.remove();
  }
  constructor(public ef: ElementRef<HTMLElement>, public appS: AppService) {}
}

@Directive({
  selector: '[pmVOld]',
  standalone: true,
})
export class PermissionManagerViewOldDirective {
  id: string;
  @Input('pmV') set pm(v: EPageBtnID | string) {
    this.id = v;
    if (!PermissionManager.pagePermission) return;
    // const res = PermissionManager.findByID(v, null);
    // // const res = PermissionManager.findByID(v, PermissionManager.pagePermission);
    // if (!res) return;
    // if (!res.viewAccess) {
    //   this.ef.nativeElement.remove();
    //   return;
    // }
  }
  constructor(public ef: ElementRef<HTMLElement>, public appS: AppService) {}
  ngOnInit(): void {
    if (this.id) return;
    if (!PermissionManager.pagePermission) return;
    if (!PermissionManager.pagePermission.viewAccess) this.ef.nativeElement.remove();
  }
}

@Directive({
  selector: '[pmE]',
  standalone: true,
})
export class PermissionManagerEditDirective {
  slug: string;
  @Input('pmE') set pm(v: EPageBtnID | string) {
    this.slug = v;
    if (!PermissionManager.pagePermission) return;
    const res = PermissionManager.selectFromComplexMap(
      v,
      PermissionManager.pagePermission.slug,
      this.appS.system
    );
    if (!res?.editAccess) this.ef.nativeElement.remove();
  }
  constructor(public ef: ElementRef<HTMLElement>, public appS: AppService) {}
}

@Directive({
  selector: '[pmEOld]',
  standalone: true,
})
export class PermissionManagerEditOldDirective {
  id: string;
  @Input('pmE') set pm(v: EPageBtnID | string) {
    this.id = v;
    if (!PermissionManager.pagePermission) return;
    // const res = PermissionManager.findByID(v, null);
    // const res = PermissionManager.findByID(v, PermissionManager.pagePermission);
    // if (!res) return;
    // if (!res.editAccess) {
    //   this.ef.nativeElement.remove();
    //   return;
    // }
  }
  constructor(public ef: ElementRef<HTMLElement>, public appS: AppService) {}
  ngOnInit(): void {
    if (this.id) return;
    if (!PermissionManager.pagePermission) return;
    if (!PermissionManager.pagePermission.editAccess) this.ef.nativeElement.remove();
  }
}

const comps = [
  PermissionManagerViewDirective,
  PermissionManagerEditDirective,
  PermissionManagerViewOldDirective,
  PermissionManagerEditOldDirective,
];
@NgModule({
  imports: [CommonModule, ...comps],
  exports: comps,
})
export class PermissionManagerDirectivesModule {}
