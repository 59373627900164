import { computed, effect, Injectable, signal } from '@angular/core';
import { ApiService } from '@Services/api.service';
import { StorageService } from '@Services/storage.service';
import { map, of, switchMap, tap } from 'rxjs';
import { environment } from '@environments/environment';
import { IWebUser } from './web-user-authentication.model';
import { AdminUserService } from 'projects/evolutics-sales-ui/src/app/admin/user/user.service';
import { EWebAccessType } from '../../Shared/enums/accessType.enum';

@Injectable({
  providedIn: 'root',
})
export class WebUserAuthenticationService {
  baseURL = environment.apiBaseUrl + '/rest';
  readonly userKey = 'webUser';
  count = 0;
  readonly maxCount = 5;

  get accessType() {
    return environment.accessType;
  }
  readonly _user = signal<IWebUser>(null);
  readonly user = computed(() => this._user());

  constructor(
    private apiS: ApiService,
    private sS: StorageService,
    private salesPortalUserS: AdminUserService,
  ) {
    this.getFromLocal();
    // effect(() => {
    //   if (this._user()) this.saveToLocal(this._user());
    // });
  }

  forgetPassword(data: { username: string; loginUrl: string; passwordAuthUrl: string }) {
    return this.apiS.post(`${this.baseURL}/web/access/forgot-password/notification`, {
      ...data,
      access: this.accessType,
    });
  }

  loginUser(username: string, password: string) {
    return this.apiS
      .post<IWebUser>(`${this.baseURL}/web/access/login`, {
        username,
        password,
        access: this.accessType,
      })
      .pipe(
        map((r) => {
          if (r.access != this.accessType) throw `User does not have access to this platform`;
          return r;
        }),
        tap((r) => {
          this.saveToLocal(r);
        }),
        switchMap((webUser) =>
          webUser.memberCode
            ? this.salesPortalUserS.getMemberViewByMemberCode(webUser.memberCode).pipe(
                tap((r) => {
                  this.saveToLocal(<IWebUser>{ primaryCode: r?.memberData?.primaryCode, ...webUser });
                }),
              )
            : of(webUser),
        ),
      );
  }
  updatePassword(data: { confirmPassword: string; password: string; webAccessType?: EWebAccessType }) {
    if (!data.webAccessType) data.webAccessType = this.accessType;
    return this.apiS.put(`${this.baseURL}/web/access/password`, data);
  }

  logout() {
    this.sS.clear();
    location.href = '/';
  }

  saveToLocal(user: IWebUser) {
    environment.webUserSignal.set(user);
    this._user.set(user);
    this.sS.saveItem(this.userKey, user);
  }

  getFromLocal() {
    environment.webUserSignal.set(this.sS.getItem(this.userKey));
    return environment.webUser;
  }

  /**
   * Calls the endpoint that updates a user password.
   * @param data
   * @returns
   */
  updatePasswordWithReference(data: {
    emailOrUsername: string;
    password: string;
    confirmPassword: string;
    reference: string;
  }) {
    return this.apiS.put(this.baseURL + `/web/access/password/${data.reference}`, {
      confirmPassword: data.confirmPassword,
      password: data.password,
      username: data.emailOrUsername,
      access: this.accessType,
    });
  }

  /**
   * Calls the endpoint tthat validates the password refrence sent via email
   * @param reference
   * @returns
   */
  validateReference(reference: string) {
    return this.apiS.get<{
      id: number;
      email: string;
      username?: string;
      reference: string;
      passwordAuthUrl: string;
      loginUrl?: any;
      createdOn: string;
      expireOn: string;
      verified?: any;
    }>(`${this.baseURL}/web/access/validate/reference/${reference}`);
  }
}
