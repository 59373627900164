<modal-header header="{{data?.header||'Pictures'}}" [dialogRef]="dialogRef">
    <div headerBtns class="me-3">
        <app-btn text="Upload New" icon="import" (mclick)="openUploadDialog()" />
    </div>

</modal-header>



<loader [loading]="loading">
    <div class="">
        @if (images?.length) {
        <div class="h-100 mt-3">
            <!-- <ng-carousel class="h-100" [numberPerView]="1" [useIndicators]="true" [items]="images" *ngIf="images" /> -->
            @if (images[currentIndex]; as currentImg;) {
            <div class="position-relative largeImgCont p-10">
                <img [src]="currentImg.src" class="largeImg m-auto d-block" alt="{{currentImg.label}}">
            </div>
            }
            <div class="thumbnails mt-4">
                @for (img of images; track img) {
                <div class="thumbnail-item pointer position-relative" (click)="currentIndex=$index">
                    <app-btn [mini]="true" type="danger" icon="delete" iconBtn class="deleteBtn" (mclick)="delete($index)" />
                    <img [src]="img.src" alt="{{img.label}}">
                </div>
                }
            </div>
        </div>
        } @else {
        <div class="px-24 py-100 my-100 center text-center">
            <div>
                <div class="mb-16">{{'There have been no images uploaded'|appTranslate|async}}</div>
                <app-btn text="Upload" icon="add" (mclick)="openUploadDialog()" />
            </div>
        </div>
        }
    </div>
</loader>