import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { NgIf } from '@angular/common';

@Component({
    selector: 'view-form-buttons',
    templateUrl: './view-form-buttons.component.html',
    styleUrls: ['./view-form-buttons.component.scss'],
    imports: [NgIf, BtnComponent, RouterLink]
})
export class ViewFormButtonsComponent implements OnInit {
  @Input() isShow:boolean
  @Input() class:string
  constructor() {}

  ngOnInit(): void {}
}
