<span id="searchView">
  <a class="nav-link px-md-0 px-lg-0 px-sm-0 px-xl-2 pointer" [ngClass]="{active:showSearch}" (click)="toggleSearch()">
    <i class="fa fa-{{showSearch?'close':'search'}} text-light search-icon"></i>
    <!-- <i class="fa fa-{{showSearch?'close':'search'}} text-light"
       matTooltip="{{showSearch?'Close search results':'Click to search the system'}}"></i> -->
    <!-- <i class="fa fa-search"></i>  -->
  </a>
  <div class="search-view glass" [hidden]="!showSearch" #searchView>
    <div class=" container py-2">
      <div class="row justify-content-center">
        <div class="col-lg mb-3">
          <div class="search-box d-flex align-items-center">
            <i class="fa fa-search"></i> <input type="search" class="" placeholder="Search" [formControl]="query">
          </div>
          @if (resultLength) {
          <div class="search-description">
            {{resultLength}} {{(resultLength|i18nPlural:resultLengthMap)|appTranslate|async}}
            {{'found.'|appTranslate|async}}
          </div>
          }
        </div>
      </div>
      <div class=" result-list" #resultList>
        <div class="row justify-content-center">
          @if (results?.length) {
          @for (type of results; track type) {
          <div class="col-lg">
            <!-- <h6 class="mb-0">{{type?.type}}</h6>
               <hr> -->
            <div class="">
              <div class="search-result-case" *ngFor="let item of type?.menuItems" [searchCase]="item">
                <div class="breadcrumb-case">
                  <span *ngFor="let bc of item.breadcrumbs;let isLast = last">{{bc.key}}{{isLast?'':' \\ '}}</span>
                </div>
                <div>
                  <a routerLink="{{item.system|systemLink:item.link}}" class="link" (click)="openLink()">
                    <div class="align-items-center d-flex">

                      <span class="system"><i class="{{item.systemIcon}} me-2"></i> <span>{{item.label}}</span>
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          }
          }
          @else{
          <div class="search-description   col-lg-12 text-center">
            <ng-container *ngIf="query?.value; else elseHasNoQueryTemplate">
              {{'Sorry, your search returned no results...'|appTranslate|async}}
            </ng-container>
            <ng-template #elseHasNoQueryTemplate>
              {{'Please type you query in the box.'|appTranslate|async}}
            </ng-template>
          </div>
          }
          <div class="col-12">
            <div class="search-result-case">
              <div class="breadcrumb-case">
                {{'For Additional Resources'|appTranslate|async}}
              </div>
              <div>
                <a routerLink="{{helpLink}}" class="link">
                  <div class="align-items-center d-flex">
                    <i class="fa fa-info-circle me-2"></i>
                    <span class="system"> {{"Help"|appTranslate|async}} </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="close-btn pointer" (click)="toggleSearch()">
       Close
     </div> -->
  </div>
</span>