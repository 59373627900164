import { Component, OnInit } from '@angular/core';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { ConfigClientTypes, EClientType } from '../client.interface';
import { CommonModule } from '@angular/common';
import { BtnModule } from '@Shared/components/btn/btn.module';

@Component({
    templateUrl: './select-client-creation-type.component.html',
    styleUrls: ['./select-client-creation-type.component.scss'],
    imports: [CommonModule, BtnModule]
})
export class SelectClientCreationTypeComponent implements OnInit {
  types = ConfigClientTypes;
  constructor(public dialogRef: MatDialogRef<SelectClientCreationTypeComponent>) {}

  ngOnInit(): void {}

  close(type: EClientType) {
    this.dialogRef.close(type);
  }
}
