import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  computed,
  effect,
  forwardRef,
  input,
  viewChild,
} from '@angular/core';
import { MatMenu as MatMenu, MenuPositionX, MatMenuModule } from '@angular/material/menu';
import { AppService } from '@Services/app.service';
import { MenuItem } from '@Shared/models/IMenuItem';

import { FilterMenuArrayPipe } from '@Shared/pipes/utility.pipe';
import { TranslatePipe } from '../../../../Shared/pipes/translate.pipe';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-menu-items',
    templateUrl: './menu-items.component.html',
    styleUrls: ['./menu-items.component.scss', '../bottom-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatMenuModule,
        NgFor,
        NgIf,
        NgClass,
        forwardRef(() => MenuItemsComponent),
        RouterLink,
        AsyncPipe,
        FilterMenuArrayPipe,
        TranslatePipe,
    ]
})
export class MenuItemsComponent implements OnInit {
  readonly parentMenuItem = input<MenuItem>();
  readonly menuItems = input<MenuItem[]>();
  readonly computedMenuItems = computed(() => this.appS.filterMenuArray(this.menuItems()));
  readonly xPosition = input<MenuPositionX>('after');
  matMenu = viewChild<MatMenu>('menu');
  constructor(public appS: AppService) {
    // effect(() => {
    //   console.log(this.parentMenuItem()?.label, this.computedMenuItems());
    // });
  }

  ngOnInit(): void {}
}
