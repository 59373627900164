import { CommonModule } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { DocumentListService } from '@Reusables/reusable-pages/document-list/document-list.service';
import { IDocument } from '@Reusables/reusable-pages/document-list/document.interface';
import { UtilityService } from '@Services/utility.service';
import ModalComponents from '@Shared/components/modal-components/modal.components';
import { IModalConfig, RefCat, SubCategory } from '@Shared/models/index.model';
import { ICarouselItem, NgCarouselComponent } from '@serene-dev/ng-carousel';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { TranslatePipe } from '../../../Shared/pipes/translate.pipe';
import { BtnComponent } from 'ets-fe-ng-sdk';

@Component({
    selector: 'entity-images-modal',
    templateUrl: './entity-images-modal.component.html',
    styleUrl: './entity-images-modal.component.scss',
    imports: [
        CommonModule,
        ModalComponents,
        NgCarouselComponent,
        LoaderComponent,
        TranslatePipe,
        BtnComponent,
    ]
})
export class EntityImagesModalComponent {
  get subCategory() {
    return this.data?.subCategory || 'PP';
  }
  get refNo() {
    return this.data?.refNo;
  }
  get refCat() {
    return this.data?.refCat;
  }
  pictureDocs: IDocument[] = [];
  images: (ICarouselItem & { file?: File })[] = [];
  currentIndex = 0;
  loading: boolean;

  constructor(
    public uS: UtilityService,
    public docS: DocumentListService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: IParams,
    public dialogRef: MatDialogRef<EntityImagesModalComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      this.pictureDocs =
        (await this.docS.getEntityImageDocs({
          refCategory: this.refCat,
          refNo: this.refNo,
          subCategory: this.subCategory,
        })) || [];
      await lastValueFrom(this.docS.getDocumentsbaseURL());
      this.generateImgLinks();
    } catch (error) {
      this.uS.info(error, 0);
    }
    this.loading = false;
  }
  async generateImgLinks() {
    this.loading = true;
    this.images = await Promise.all(
      this.pictureDocs.map(async (x) => ({
        src: await this.docS.getFileDownloadURL(x),
        label: x.title,
      }))
    );
    this.currentIndex = 0;
    this.loading = false;
  }

  openUploadDialog() {
    const fileEl = document.createElement('input');
    fileEl.type = 'file';
    fileEl.onchange = () => {
      this.parse(fileEl.files);
    };
    fileEl.click();
  }

  parse(files: FileList) {
    if (files.length === 0) return;

    const f = files[0];
    const mimeType = f.type;
    if (files.length == 1 && f.size > 10000000) {
      this.uS.notify('Image has to be less then 10mb in size', 0);
      return;
    }
    if (mimeType.match(/image\/*/) == null) {
      this.uS.notify('Only images are supported.', 0);
      return;
    }
    const fr = new FileReader();
    fr.readAsDataURL(f);
    fr.onload = (e) => {
      this.images.push({ src: fr.result as string, file: f });
    };
    this.upload(f);
  }

  async upload(file: File) {
    try {
      const res = await lastValueFrom(
        this.docS.submitDocument2(file, {
          title: `${this.data.header || 'Image'}`,
          subCategory: this.subCategory as any,
          refCat: this.refCat as any,
          refNo: this.refNo,
        })
      );
      this.pictureDocs.unshift(res);
      this.generateImgLinks();
    } catch (error) {
      this.uS.info(error, 0);
    }
  }

  async delete(index: number) {
    try {
      const doc = this.pictureDocs?.[index];
      if (!doc) throw `Image was not selected`;
      const res = await lastValueFrom(this.docS.deleteDocument(doc.id));
      this.pictureDocs.splice(index, 1);
      this.generateImgLinks();
    } catch (error) {
      this.uS.info(error, 0);
    }
  }

  static modalFactory = (
    params: IParams,
    valueCB: () => any
  ): IModalConfig<EntityImagesModalComponent> => ({
    component: EntityImagesModalComponent,
    config: { height: '90vh', width: '80vw', data: params },
    valueCB,
    closeCB: valueCB,
  });
}

interface IParams {
  refNo: string;
  refCat: RefCat;
  subCategory: SubCategory;
  header?: string;
}
