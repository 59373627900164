<div>
    <div class="row justify-content-center">
        <div class="col-md-auto p-3 mt-4 text-center">
            <span class="text-muted">{{'Upload files named in this format: '|appTranslate|async}}</span>
            <span>{{fileFormats[fileNameFormat]}}</span>
        </div>
    </div>
    <div class="row justify-content-center">
        @if (errors?.length) {
<div class="col-md-auto text-danger border border-danger light-red-bg rounded text-center mt-2 mb-4">
            <p>{{'The following files are not in the right format: '}}</p>
            @for (error of errors; track error) {
  <span class="me-5">{{error}}</span>
}
        </div>
}
    </div>
    @if (files?.length) {
<div class="table-responsive">
        <table class="table table-striped col-md-auto">
@switch (fileNameFormat) {
            @case ('NON_POLICY_DOCUMENT') {
  
                <thead>
                    <th>{{'Ref Cat'|appTranslate|async}}</th>
                    <th>{{'Ref No'|appTranslate|async}}</th>
                    <th>{{'Doc SubCat'|appTranslate|async}}</th>
                    <th>{{'Title'|appTranslate|async}}</th>
                </thead>
                <tbody>
                    @for (file of fileNames; track file) {
  <tr>
                        <td>{{file.refCat}}</td>
                        <td>{{file.refNo}}</td>
                        <td>{{file.docSubCat}}</td>
                        <td>{{file.title}}</td>
                    </tr>
}
                </tbody>
            
}
            @case ('POLICY_DOCUMENT') {
  
                <thead>
                    <th>{{'Ref Cat'|appTranslate|async}}</th>
                    <th>{{'Policy No'|appTranslate|async}}</th>
                    <th>{{'Policy Code'|appTranslate|async}}</th>
                    <th>{{'Doc SubCat'|appTranslate|async}}</th>
                    <th>{{'Title'|appTranslate|async}}</th>
                </thead>
                <tbody>
                    @for (file of fileNames; track file) {
  <tr>
                        <td>{{file.refCat}}</td>
                        <td>{{file.policyNo}}</td>
                        <td>{{file.policyCode}}</td>
                        <td>{{file.docSubCat}}</td>
                        <td>{{file.title}}</td>
                    </tr>
}
                </tbody>
            
}
            @case ('POLICY_DOCUMENT_SUFFIX') {
  
                <thead>
                    <th>{{'Ref Cat'|appTranslate|async}}</th>
                    <th>{{'Policy No'|appTranslate|async}}</th>
                    <th>{{'Policy Code'|appTranslate|async}}</th>
                    <th>{{'Policy No Suffix'|appTranslate|async}}</th>
                    <th>{{'Doc SubCat'|appTranslate|async}}</th>
                    <th>{{'Title'|appTranslate|async}}</th>
                </thead>
                <tbody>
                    @for (file of fileNames; track file) {
  <tr>
                        <td>{{file.refCat}}</td>
                        <td>{{file.policyNo}}</td>
                        <td>{{file.policyCode}}</td>
                        <td>{{file.policyNoSuffix}}</td>
                        <td>{{file.docSubCat}}</td>
                        <td>{{file.title}}</td>
                    </tr>
}
                </tbody>
            
}
        }
</table>

    </div>
}
</div>