import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IAccount } from '@Shared/models/account.interface';
import { ApiService } from '@Services/api.service';
import { CodeService } from './code.service';
import { ICodeDescription, ISearchResponse2 } from '@Shared/models/index.model';
import { of } from 'rxjs';
import { EndorsementResponse } from '@Reusables/reusable-pages/endorsement/endorsement-extras/endorsement.model';
import { map } from 'rxjs/operators';
import { IAccountPost } from '../Life/Setup/Account/accounts-extras/account-model';
import { HttpHeaders } from '@angular/common/http';
import { ISearchResponse } from '@Shared/components/search/search-extras/search.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private baseAPIURL = environment.apiBaseUrl + '/rest/';

  constructor(private apiService: ApiService, public codeS: CodeService) {}

  getProviderList = () => {
    return this.codeS.getAllCodeByCodeSubGroup('MM_PROVIDER');
  };

  getAccCodeByCode = (accCode: string) => {
    if (!accCode) return of<IAccountPost>(null);
    return this.apiService.get<IAccountPost>(this.baseAPIURL + `acc/codes/${accCode}`);
  };
  /**
   * Get all ledger codes by the user's primary company code
   * @param companyCode
   * @returns
   */
  getLedgerCodesByCompanyCode = (companyCode: string = environment.userCompanyCode) => {
    return this.apiService
      .get<{ ledgerCode: string; description: string }[]>(
        this.baseAPIURL + `acc/codes/account/ledger/${companyCode}`
      )
      .pipe(
        map((r: any) =>
          r.map(
            (x: any) =>
              <ICodeDescription>{
                code: x.ledgerCode,
                description: x.description,
              }
          )
        )
      );
  };

  getAccCodeByLedgerCode = (ledgerCode: string) => {
    if (!ledgerCode) return of(null);
    return this.apiService.get<
      ISearchResponse2<{
        id: number;
        code: string;
        companyCode: string;
        accFormat?: any;
        ledgerCode: string;
        accountGroup: string;
        accCodesId: number;
        description?: any;
        accountType?: any;
        createdOn: string;
        createdBy: string;
        updatedOn: string;
        updatedBy: string;
        rowId?: any;
      }>
    >(this.baseAPIURL + `acc/codes/mapping/search`, {
      ledgerCode,
    });
  };

  getAllCD = () => {
    return this.apiService.get<ICodeDescription[]>(this.baseAPIURL + 'acc/codes/desc');
  };

  getAllAccountSigns = () => this.apiService.get<any[]>(this.baseAPIURL + 'acc/codes/signs');

  deleteAccountSign = (id: any) =>
    this.apiService.delete(this.baseAPIURL + 'acc/codes/signs/' + id);
  createAccountSign = (data) => this.apiService.post(this.baseAPIURL + 'acc/codes/signs/', data);
  updateAccountSign = (id: any, data) =>
    this.apiService.put(this.baseAPIURL + 'acc/codes/signs/' + id, data);

  createAccount(data: IAccount) {
    return this.apiService.post<IAccount>(this.baseAPIURL + 'client/bank/', data);
  }
  createAccountWithEnd(data: IAccount) {
    return this.apiService.post(
      this.baseAPIURL + 'client/bank/',
      data
      // {
      //   options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
      // }
    );
    // .pipe(map((r) => new EndorsementResponse<IAccount>(r)));
  }
  updateAccount(data: IAccount) {
    return this.apiService
      .put(this.baseAPIURL + 'client/bank/' + data.clientNo, data)
      .pipe(map((r) => new EndorsementResponse<IAccount>(r)));
  }
  updateAccountWithEnd(data: IAccount) {
    return this.apiService
      .put(this.baseAPIURL + 'client/bank/' + data.clientNo, data, {
        options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
      })
      .pipe(map((r) => new EndorsementResponse<IAccount>(r)));
  }
}
