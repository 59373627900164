import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  linkedSignal,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { CMSService } from '../../cms.service';
import { ICMSContent, ICMSReference, ICMSReferenceTreeItem } from '../../cms.model';
import { debounceTime, filter, forkJoin, lastValueFrom, map, of } from 'rxjs';
import { SaveCMSResourceService } from '../save-cms-resource/save-cms-resource.service';
import { PageTemplateComponent } from '../../../../../Shared/components/page-template/page-template.component';
import { CMSResourceComponent } from '../cms-resource/cms-resource.component';
import { CMSLayoutService } from '../../cms-layout/cms-layout.service';
import { CMSResourceService } from '../cms-resource/cms-resource.service';
import {
  BtnComponent,
  FindItemComponent,
  InputBasicComponent,
  ITableCol,
  LoaderComponent,
  ModalComponents,
  ISearchFormSchema,
  TablePlainComponent,
  TextCase2Component,
  VerticalNavComponent,
  TrimTextPipe,
  EVFunctions,
} from 'ets-fe-ng-sdk';
import { TranslatePipe } from '../../../../../Shared/pipes/translate.pipe';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuItem } from '../../../../../Shared/models/IMenuItem';
import { cloneDeep, isEqual } from 'lodash-es';
import { rxResource, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MapCMSResourceComponent } from '../map-cms-resource/map-cms-resource.component';
import SaveCMSResourceComponent from '../save-cms-resource/save-cms-resource.component';
import { MatListModule } from '@angular/material/list';
import { TranslateNoLoaderPipe, TranslateSingleNoLoaderPipe } from '@evolutics/translation';
import { IconComponent } from '../../../../../Shared/components/icon/icon.component';
import { Title } from '@angular/platform-browser';
import { CmsExplorerSubmapperComponent } from './cms-explorer-submapper/cms-explorer-submapper.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import ViewCMSResourceComponent from '../view-cms-resource/view-cms-resource.component';

@Component({
  selector: 'cms-resource-explorer',
  imports: [
    VerticalNavComponent,
    ModalComponents,
    BtnComponent,
    LoaderComponent,
    TranslatePipe,
    TranslateNoLoaderPipe,
    CommonModule,
    MatExpansionModule,
    TextCase2Component,
    FindItemComponent,
    TablePlainComponent,
    MatListModule,
    CmsExplorerSubmapperComponent,
    InputBasicComponent,
    MatSlideToggleModule,
    RouterModule,
    MatTreeModule,
    IconComponent,
    RouterModule,
    ClipboardModule,
    TrimTextPipe,
    TranslateSingleNoLoaderPipe,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    // SharedModule,
    SaveCMSResourceComponent,
    CMSResourceComponent,
    MapCMSResourceComponent,
    ViewCMSResourceComponent,
  ],
  providers: [CMSResourceService, SaveCMSResourceService],
  templateUrl: './cms-resource-explorer.component.html',
  styleUrl: './cms-resource-explorer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class CMSResourceExplorerComponent extends PageTemplateComponent {
  protected readonly service = inject(CMSService);
  protected readonly cmsResourceService = inject(CMSResourceService);
  protected readonly titleService = inject(Title);
  protected readonly saveService = inject(SaveCMSResourceService);
  protected readonly cmsLayoutService = inject(CMSLayoutService);

  readonly applicationCode = input.required<string>();
  readonly allowEdit = input<boolean>();
  readonly mini = input<boolean>();
  readonly usePathsOnly = input<boolean>();
  readonly baseQuery = input<Partial<ICMSReference>>();
  // readonly appendedQuery = input<Partial<ICMSReference>>();
  readonly explorerTitle = input<string>(document.title);
  readonly menu = input<MenuItem[]>();
  readonly toolbarEndTemplate = input<TemplateRef<any> | undefined>();

  readonly verticalNavRef = viewChild(VerticalNavComponent);

  private readonly routeQueryKeys: { [field in keyof ICMSReference]: true } = {
    helpGuideContentId: true,
    refCat: true,
    refId: true,
    id: true,
    applicationCode: true,
    moduleType: true,
  };
  protected readonly routeQuery = toSignal<Partial<ICMSReference>>(
    this.route.queryParams.pipe(
      map((qp) => {
        const ret: Partial<ICMSReference> = {};
        for (const key in qp) {
          if (key in this.routeQueryKeys) ret[key] = qp[key];
        }
        return Object.keys(ret).length ? ret : null;
      }),
    ),
  );

  readonly overrideQuery = signal<ICMSReference>(null);

  readonly referenceTree = computed<ICMSReferenceTreeItem[]>(() =>
    this.menuToTreeItem(cloneDeep(this.menu())),
  );

  readonly contentResourceQuery = computed(() =>
    this.routeQuery() || this.selectedReferenceTreeItem()?.queryParams
      ? {
          ...this.baseQuery(),
          ...this.routeQuery(),
          ...this.selectedReferenceTreeItem()?.queryParams,
          ...(this.overrideQuery() || {}),
        }
      : this.overrideQuery() || null,
  );
  readonly resourceLoader = rxResource({
    request: () => {
      this.baseRefreshSignal();
      return this.contentResourceQuery();
    },
    // request: () => ({ ...this.baseQuery(), ...this.appendedQuery(), ...this.routeQuery() }),
    loader: ({ request }) => (request ? this.service.getContentResource(request) : of(null)),
  });
  readonly referenceResource = rxResource({
    request: () => {
      this.baseRefreshSignal();
      return this.contentResourceQuery();
    },
    loader: ({ request }) => this.service.getContentReference(request),
  });

  readonly resource = computed(() => this.resourceLoader.value());
  readonly hasResource = computed(() => !!this.resource());
  readonly loadingResource = computed(() => this.resourceLoader.isLoading());

  private readonly _viewMode = signal<ViewMode>(ViewMode.viewing);
  readonly viewMode = computed<ViewMode>(() => (this.allowEdit() ? this._viewMode() : ViewMode.viewing));
  readonly ViewMode = ViewMode;

  readonly selectedReferenceItemChain = linkedSignal(() =>
    this.findSelectedReferenceItemChain(this.routeQuery(), this.referenceTree()),
  );
  readonly selectedReferenceTreeItemParents = computed(() => this.selectedReferenceItemChain()?.slice(0, -1));
  readonly selectedReferenceTreeItem = linkedSignal(() => this.selectedReferenceItemChain()?.lastItem());

  // d = effect(() => console.log('referenceTree', this.referenceTree()));
  // dab = effect(() => console.log('selectedReferenceItemChain', this.selectedReferenceItemChain()));

  readonly referenceTreeRef =
    viewChild<MatTree<ICMSReferenceTreeItem, ICMSReferenceTreeItem>>('referenceTreeEl');
  protected readonly toggleBtnIDPrefix = 'toggleBtn_';
  readonly treeItemExpander = toObservable(this.referenceTreeRef).subscribe(() => {
    const selectedReferenceItemChain = this.selectedReferenceTreeItemParents();
    this.referenceTreeRef()?.collapseAll();
    if (this.referenceTreeRef() && selectedReferenceItemChain?.length) {
      for (const item of selectedReferenceItemChain)
        document.getElementById(this.toggleBtnIDPrefix + item.id)?.click();
      const lastItem = selectedReferenceItemChain.lastItem();
      if (lastItem) document.getElementById(this.toggleBtnIDPrefix + lastItem.id)?.scrollIntoView();
    }
  });
  readonly relatedResourcesResource = rxResource({
    request: () => ({
      parents: this.selectedReferenceTreeItemParents(),
      resource: this.resource(),
      baseQuery: this.baseQuery(),
    }),
    loader: ({ request }) =>
      !request.parents?.length || request.resource || !this.selectedReferenceTreeItem()
        ? of([] as ICMSReferenceTreeItem[])
        : forkJoin(
            request.parents.map((ti) =>
              ti.queryParams
                ? this.service
                    .searchContentReference({ ...request.baseQuery, ...ti.queryParams })
                    .pipe(map((r) => (r.content?.length ? ti : null)))
                : of(null),
            ),
          ),
  });
  readonly loadingRelatedResources = computed(() => this.relatedResourcesResource.isLoading());
  readonly relatedResources = computed(() => this.relatedResourcesResource.value()?.filter((x) => !!x));
  readonly mappedResourcesResource = rxResource({
    request: () => ({
      item: this.selectedReferenceTreeItem(),
      resource: this.resource(),
      baseQuery: this.baseQuery(),
    }),
    loader: ({ request }) =>
      !request?.item?.queryParams
        ? of([] as ICMSReference[])
        : this.service
            .searchContentReference({ ...request.baseQuery, ...request?.item.queryParams })
            .pipe(map((r) => r.content)),
  });
  readonly mappedResources = computed(() =>
    this.mappedResourcesResource.value()?.filter((x) => !!x?.helpGuideContentTitle),
  );
  readonly hasMultipleMappedResources = computed(() => this.mappedResources()?.length > 1);
  readonly welcomeText = computed<string>(() => `Welcome to ${this.explorerTitle()}`);

  readonly searchForm = new FormGroup({
    query: new FormControl<string>(''),
  });
  readonly searchQuery = toSignal(this.searchForm.controls.query.valueChanges);
  readonly searchFormSchema = computed<ISearchFormSchema[]>(() => [
    {
      field: 'searchKey',
      label: 'Search',
    },
  ]);
  readonly searchResponseColumn = computed<ITableCol<ICMSContent>[]>(() => [
    {
      f: 'title',
      t: '',

      // customTemplate: this.searchCellTemplRef(),
    },
  ]);
  readonly selectedContentBlockList = signal<ICMSContent[]>([]);
  readonly showSubMapButton = computed<boolean>(() => !!this.selectedContentBlockList()?.length);
  readonly searchFindItemRef = viewChild<FindItemComponent>('searchFI');
  readonly searchCellTemplRef = viewChild<TemplateRef<any>>('searchCellTempl');

  readonly subs = this.uS.createSubscriptionManager(
    this.searchForm.controls.query.valueChanges
      .pipe(
        debounceTime(600),
        filter((x) => x?.length > 3),
      )
      .subscribe((q) => {
        this.searchFindItemRef().searchForm().patchValue({ searchKey: q });
        this.searchFindItemRef().search();
      }),
  );

  async ngOnInit() {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  refresh() {
    this.setViewMode(ViewMode.viewing);
    this.baseRefresh();
  }

  private findSelectedReferenceItemChain(
    routeQuery = this.routeQuery(),
    refrenceTree = this.referenceTree(),
  ): ICMSReferenceTreeItem[] {
    if (!routeQuery) return null;
    for (const item of refrenceTree) {
      if (isEqual(item.queryParams, routeQuery)) return [item];
      if (!item.subs) continue;
      const res = this.findSelectedReferenceItemChain(routeQuery, item.subs);
      if (res) return [item, ...res];
    }
    return null;
  }

  menuToTreeItem(menu: MenuItem[]): ICMSReferenceTreeItem[] {
    const ret: ICMSReferenceTreeItem[] = [],
      usePathsOnly = this.usePathsOnly();
    for (const { subs, originalPath: link, ...item } of menu)
      ret.push({
        ...item,
        link,
        queryParams: usePathsOnly
          ? link
            ? { refId: link }
            : null
          : item.id || link
            ? { refId: item.id || link }
            : null,
        subs: subs ? this.menuToTreeItem(subs) : null,
        id: item.id || this.uS.generateUUID(),
      });

    return ret;
  }

  childrenAccessor = (node: ICMSReferenceTreeItem) => node.subs ?? [];

  hasChild = (_: number, node: ICMSReferenceTreeItem) => !!node.subs && node.subs.length > 0;

  search() {}

  setViewMode(mode: ViewMode) {
    this._viewMode.set(mode);
  }
  readonly updateTitle = effect(() => {
    const suffix = this.explorerTitle(),
      resource = this.resource();
    this.titleService.setTitle(EVFunctions.strConcatenator2(resource?.title, suffix));
  });

  async deleteMapping(reference = this.referenceResource.value()) {
    const proceed = await this.uS.confirm(`Proceed to delete mapping to ${reference.helpGuideContentTitle}?`);
    if (!proceed) return;
    this.loadingSignal.set(true);
    try {
      const resource = this.resource();
      const res = await lastValueFrom(this.service.deleteHelpGuideContentReferencing(reference.id));
      this.uS.notify(
        `${resource?.title || ''} mapping has been deleted from ${this.selectedReferenceTreeItem()?.label}`.trim(),
      );
      this.refresh();
    } catch (error) {
      this.uS.info(error, 0);
    }
    this.loadingSignal.set(false);
  }

  readonly searchHelpGuideContentUsingIndex = computed(() => {
    const applicationCode = this.applicationCode();
    return (query) =>
      this.service.searchHelpGuideContentUsingIndex({
        ...query,
        applicationCode: applicationCode == 'EV' ? null : query.applicationCode,
      });
  });

  toggleSideMenu() {
    this.verticalNavRef().toggleSideMenu();
  }

  goHome() {
    if (this.mini()) {
      this.selectedReferenceTreeItem.set(null);
      this.overrideQuery.set(null);
    } else this.router.navigate(['./'], { relativeTo: this.route });
  }
}

enum ViewMode {
  creating = 'creating',
  mapping = 'mapping',
  viewing = 'viewing',
}
