<div class="loading custom-progress-bar">
  @if (uS.environment.pageLoader.isLoading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
</div>
<loader [loading]="pS.isLoading()" text="Loading" [height]="screenHeight()">
  <router-outlet></router-outlet>
</loader>

<!-- <app-chat-help-desk></app-chat-help-desk> -->
<div class="home-versionNo">v{{ uS.environment.versionNo }}</div>
@if (allowProfilePreview()) {
  <user-preview-modal />
}
<toast-notifications />
<user-helper />
