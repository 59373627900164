import { IAccountPost, ICreateBank, ICreateSortCode } from './account-model';
import { Injectable } from '@angular/core';
import { IVehicleBrand } from 'projects/evolutics-client-ui/src/app/General/setups/codes/vehicles/vehicle-extras/vehicle.model';
import { ApiService } from '@Services/api.service';
import { ICode, ICodeDescription, ICodeTitle } from '@Shared/models/index.model';
import { environment } from '@environments/environment';
import { AccCode, AccCodeMapping, AllAccCodes, CreateAccCode } from '../account';
import { map } from 'rxjs/operators';
import { IBulkGLM, IGLM } from '../general-ledger-mapping/glm.model';

@Injectable({
  providedIn: 'root',
})
export class AccountExtrasService {
  private get baseURL() {
    return environment.apiBaseUrl + '/rest/';
  }
  constructor(private apiService: ApiService) {}

  getAllAccCodes() {
    return this.apiService.get(this.baseURL + 'acc/codes/mapping');
  }

  getAllAccCodesByCompany(company: string) {
    return this.apiService.get(this.baseURL + 'acc/codes/mapping/company/' + company);
  }

  getAccountMappingByCompanyAndFormat(companyCode: string, format: string) {
    return this.apiService.get<any>(this.baseURL + `acc/codes/mapping/${companyCode}/${format}`);
  }

  getAccCodeMappingByCompany(company: string) {
    return this.apiService.get(this.baseURL + 'acc/codes/account/code/mapping/' + company);
  }

  getAllAccCodesByGroup(group: string) {
    return this.apiService.get(this.baseURL + 'acc/codes/group/' + group);
  }

  getCodeSubGroup = (value: string) => {
    return this.apiService.get<ICodeTitle[]>(this.baseURL + `codes/sub/category/${value}`);
  };

  getAccCodesAndDescriptionByGroup = (group: string) => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `acc/codes/desc/group/${group}`);
  };

  getAccountsByGroup = (groupCode) => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `acc/codes/desc/group/${groupCode}`);
  };

  getBanks = () => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `bank/code/desc`);
  };

  getBankByBankCode = (code) => {
    return this.apiService.get<any>(this.baseURL + `bank/code/${code}`);
  };
  getAllAgentsNo = () => {
    return this.apiService.get<any[]>(this.baseURL + `agent/no`);
  };

  getCompanyCodeAndDesc = () => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `company/code/desc`);
  };

  getCurrency = () => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `currency`);
  };

  getCountry = () => {
    return this.apiService.getWithLocalCache<ICodeDescription[]>(this.baseURL + `location/country/code/desc`);
  };

  getCity = () => {
    return this.apiService.getWithLocalCache<ICodeDescription[]>(this.baseURL + `location/state`);
  };

  getCitiesByRegion = (regionCode: string) => {
    return this.apiService.getWithLocalCache<ICodeDescription[]>(
      this.baseURL + `location/state/${regionCode}`,
    );
  };
  getCitiesByCountry = (countryCode: string) => {
    return this.apiService.getWithLocalCache<ICodeDescription[]>(
      this.baseURL + `location/state/country/${countryCode}`,
    );
  };

  getBankCitiesByCountry = (countryCode: string) => {
    return this.apiService.get(this.baseURL + `location/state/country/${countryCode}`);
  };

  getRegion = (countryCode: string) => {
    return this.apiService.getWithLocalCache<ICodeDescription[]>(
      this.baseURL + `location/region/country/${countryCode}`,
    );
  };

  getTownByCity = (cityCode: string) => {
    return this.apiService.getWithLocalCache<ICodeDescription[]>(this.baseURL + `location/town/${cityCode}`);
  };

  getBankTownByCity = (cityCode: string) => {
    return this.apiService.get(this.baseURL + `location/town/${cityCode}`);
  };

  getBankCodeAndDescByTown = (townCode: string) => {
    return this.apiService.get(this.baseURL + `bank/code/desc/town/${townCode}`);
  };

  getSortCode = () => {
    return this.apiService.get<ICode[]>(this.baseURL + `sort/codes`);
  };

  getBranchTitleByBankCode = (bankCode: string) => {
    return this.apiService.get<ICodeTitle[]>(this.baseURL + `sort/codes/branch_title/${bankCode}`);
  };

  getAccCodes = () => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `acc/codes/desc`);
  };

  getBankByCompany = (bankCode: string) => {
    return this.apiService.getWithLocalCache<ICodeDescription[]>(
      this.baseURL + `location/country/code/desc/${bankCode}`,
    );
  };

  getBankByCountry = (bankCode: string) => {
    return this.apiService.getWithLocalCache<ICodeDescription[]>(this.baseURL + `bank/code/desc/${bankCode}`);
  };

  submitBank = (data: ICreateBank) => {
    return this.apiService.post<ICreateBank>(this.baseURL + 'bank/account', data);
  };

  modifyBank(data: ICreateBank, code: string) {
    return this.apiService.put<ICreateBank>(this.baseURL + 'bank/account/' + code, data);
  }

  saveSortCode(data) {
    return this.apiService.post(this.baseURL + 'sort/codes', data);
  }

  getSortCodes(pageNumber, pageSize, searchParams = {}) {
    let params = Object.entries(searchParams).reduce(
      (query, [key, value]) => (value || value == '0' ? `${query}&${key}=${value}` : `${query}`),
      '',
    );
    return this.apiService.get(
      `${this.baseURL}sort/codes/search?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`,
    );
  }

  getSortCodeRecord(sortCode) {
    return this.apiService
      .get(this.baseURL + 'sort/codes/search?sortCode=' + sortCode)
      .pipe(map((res) => res?.page?.content[0]));
  }

  submitAccountMappingCode = (data) => {
    return this.apiService.post(this.baseURL + `acc/codes/mapping`, data);
  };

  submitAccountPost = (data: IAccountPost) => {
    return this.apiService.postWithEndorsment<IAccountPost>(this.baseURL + 'acc/codes/', data);
  };

  submitProcessWorkflow = (data) => {
    return this.apiService.post(this.baseURL + 'workflows/task/process', data);
  };

  searchSetupNotification = (companyCode) => {
    return this.apiService.get(this.baseURL + `setup/notification/search?companyCode=${companyCode}`);
  };

  submitSetupNotification = (data) => {
    return this.apiService.post(this.baseURL + 'setup/notification', data);
  };

  updateSetupNotification = (data) => {
    return this.apiService.put(this.baseURL + `setup/notification`, data);
  };
  deleteSetupNotification = (id: number) => {
    return this.apiService.delete(this.baseURL + `setup/notification/${id}`);
  };

  updateAccountCodesMappingLedger = (id: number | string, data: IAccountPost) => {
    return this.apiService.post(this.baseURL + `acc/codes/`, data);
  };

  modifyAccountPost(data: IAccountPost, code: string) {
    return this.apiService.post<IAccountPost>(this.baseURL + 'acc/codes/' + code, data).toPromise();
  }

  getAccountbyAccountCode = (accCode) => {
    return this.apiService.get<IAccountPost>(this.baseURL + `acc/codes/${accCode}`);
  };

  getProcessWorkflowBytaskCodeandProcessCode = (query) => {
    return this.apiService.get(
      this.baseURL + `workflows/task/process/search` + this.apiService.getRequestParse(query),
    );
  };

  getProcessWorkflows = (query = {}) => {
    return this.apiService.get(this.baseURL + `workflows/task/process/search`, query).pipe(
      map((res) => {
        const workflowTaskProcess = res.content.map((r) => r.workflowTaskProcess);
        return workflowTaskProcess;
      }),
    );
  };

  getAccCodesby = (accCode) => {
    return this.apiService.get<AllAccCodes>(this.baseURL + `acc/codes/${accCode}`);
  };

  getAccCodesTypebyAccCode = (accCode) => {
    return this.apiService.get<AllAccCodes>(this.baseURL + `acc/codes/type/${accCode}`);
  };

  getBranchCodeAndDescriptionByCompanyCode = (companyCode) => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `branch/code/desc/${companyCode}`);
  };

  getMappedAndUnmapped = (companyCode, accountFormat) => {
    return this.apiService.get<{
      mappedCodesCount: number;
      unmappedCodesControl: number;
    }>(this.baseURL + `acc/codes/count/mapped?companyCode=${companyCode}&accountFormat=${accountFormat}`);
  };

  getAllAccountCodes = () => {
    return this.apiService.get(this.baseURL + `acc/codes/`);
  };

  updateAccountCodes = (data) => {
    return this.apiService.put(this.baseURL + 'acc/codes/', data);
  };

  bulkAccountcodes(payload: IBulkGLM[]) {
    return this.apiService.post(this.baseURL + `acc/codes/bulk`, payload);
  }
}
