<div class="menu bottom-nav  {{ appS.systemSignal() }}">
  <div class="row m-0 align-items-center">
    <div class="col overflow-x-auto" PSBox>
      <nav class="navbar navbar-expand-sm navbar-dark">
        <div class="navbar-collapse navbars" id="navbar1">
          <ul class="navbar-nav justify-content-around mx-auto">
            @for (item of appS.currentBottomMenu(); track item.id) {
              <li class="{{ item.hasSub ? 'dropdown' : '' }} nav-item mx-1 ">
                <app-menu-item [menuItem]="item" />
              </li>
            }
          </ul>
        </div>
      </nav>
    </div>
    <div class="col-auto">
      <a
        aria-expanded="false"
        class="nav-link dropdown-toggle p-0 px-md-0 px-lg-0 px-sm-0 px-xl-1"
        data-bs-toggle="dropdown"
        href="#"
        role="button"
        [matTooltip]="userFullName()">
        <img alt="Avatar" class="avatar" imageType="pp" [imageLoader]="userImageUrl()" />
        <b class="caret"></b>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark glass dashboard-menu">
        <!-- <li><a class="dropdown-item" href="#">Settings</a></li>
  <li>
    <hr class="dropdown-divider">
  </li> -->
        @for (mi of navigationService.profileMenuButtons(); track mi.label) {
          <li>
            @if (mi.link) {
              <a class="dropdown-item underline" routerLink="{{ mi.link }}">
                {{ mi.label | appTranslate | async }}
              </a>
            } @else if (mi.action) {
              <a class="dropdown-item underline" (click)="mi.action()">
                {{ mi.label | appTranslate | async }}
              </a>
            }
          </li>
        }
        <!-- <li>
        <a class="dropdown-item underline" routerLink="profile">
          {{ 'Profile' | appTranslate | async }}
        </a>
      </li>
      <li>
        <a class="dropdown-item underline" routerLink="login-sessions">
          {{ 'Log in sessions' | appTranslate | async }}
        </a>
      </li>
      <li>
        <a (click)="signOut()" class="dropdown-item underline pointer">
          {{ 'Sign out' | appTranslate | async }}
        </a>
      </li>
      <li>
        <a class="dropdown-item" routerLink="{{ helpLink() }}">{{ 'Help' | appTranslate | async }}</a>
      </li> -->
      </ul>
    </div>
  </div>
</div>
<ng-container *ngComponentOutlet=""></ng-container>
