
@if (listFiles) {
<div [ngClass]="{meta:mini}" class="{{class}} form-label mb-2 hide-scroll">
  @for (item of files; track item; let i = $index) {
  <div class="row align-items-start mb-1">
    <div class="col">
      <div class="hide-scroll file-name">
        {{item?.name}}
      </div>
    </div>
    <div class="col-auto text-end">
      <span class="text-danger pointer p-1 fa fa-close" (click)="removeFile(i);"></span>
    </div>
  </div>
}
  @for (item of fileMetadatas; track item; let i = $index) {
  <div class="row align-items-start mb-1">
    <div class="col">
      <div class="hide-scroll file-name">
        {{item?.metadata?.title}}
      </div>
    </div>
    <div class="col-auto text-end">
      <span class="text-danger pointer p-1 fa fa-close" (click)="removeFileMetadata(i)"></span>
    </div>
  </div>
}
</div>
}
<!-- <input type="file"  style="display: none;" accept="{{accept}}" (change)="onUpload($event)" #uploadInput [multiple]="multiple"> -->
<app-btn icon="upload" (mclick)="openDialog()" [type]="file||fileMetadata?.file?'primary':'secondary'"
  [disabled]="disabled" [help]="help" [text]="label">

</app-btn>