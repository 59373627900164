import { Component, Inject, OnInit, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogRef as MatDialogRef,
  MAT_DIALOG_DATA as MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogConfig,
} from '@angular/material/dialog';
import { FKVP } from '@Shared/models/index.model';
import { DocumentListService } from '../../reusable-pages/document-list/document-list.service';
import { CardComponent, FunctionCaller, FunctionCaller1, TextCase2Component } from 'ets-fe-ng-sdk';
import { TranslatePipe } from '../../../Shared/pipes/translate.pipe';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { ModalHeaderComponent } from '@Shared/components/modal-header/modal-header.component';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { UtilityService } from '@Services/utility.service';
import { lastValueFrom } from 'rxjs';
import { UserService } from '@Services/user.service';

@Component({
    templateUrl: './view-document-modal.component.html',
    styleUrls: ['./view-document-modal.component.scss'],
    selector: 'view-document-modal',
    providers: [MatDialogConfig],
    imports: [
        BtnComponent,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        InputBasicComponent,
        NgFor,
        AsyncPipe,
        FunctionCaller,
        FunctionCaller1,
        TranslatePipe,
        ModalHeaderComponent,
        AutocompleteComponent,
        MatDialogModule,
        LoaderComponent,
        CardComponent,
        TextCase2Component,
    ]
})
export class ViewDocumentModalComponent implements OnInit {
  readonly document = signal<any>(null);
  lbls1: FKVP[] = [
    new FKVP('code', 'Document Code'),
    new FKVP('refCat', 'Ref Category'),
    {
      key: 'refNo',
      label: 'Ref Number',
    },
    new FKVP('name', 'Document Name'),
  ];
  readonly loading = signal<boolean>(false);

  constructor(
    public docS: DocumentListService,
    public uS: UtilityService,
    public dialogRef: MatDialogRef<ViewDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { notification },
    public userS: UserService,
  ) {}

  async ngOnInit() {
    this.loading.set(true);
    const doc = await lastValueFrom(this.docS.getMetadataByID(this.data.notification?.refNo));
    this.document.set(doc);
    this.lbls1.forEach((l) => {
      l.value = this.document()[l.key];
    });
    this.loading.set(false);
  }

  async downloadDocument(btn?: BtnComponent) {
    btn?.setLoader(true);
    await this.docS.downloadDocument({
      docKey: this.document().docKey,
      name: this.document().name,
    });
    btn?.setLoader(false);
  }
}
