<!-- @if (menuItem | isMenuEnabled; as menuCheck) { -->
@if (menuItem(); as menuItem) {
  @if (menuItem.hasSub) {
    <a
      aria-expanded="false"
      class="nav-link hasDropDown px-md-0 px-lg-0 px-sm-0 px-xl-1"
      role="button"
      [id]="menuItem.id"
      [matMenuTriggerFor]="menuComp.matMenu()">
      <span [hidden]="!menuItem.icon" class="{{ menuItem.icon }}"></span>
      <svg-icon [hidden]="!menuItem.svgIcon" [icon]="menuItem.svgIcon"></svg-icon>
      {{ menuItem.label | appTranslate | async }}
    </a>
    <app-menu-items
      #menuComp
      [parentMenuItem]="menuItem"
      [xPosition]="menuItem.submenuPosition"
      [menuItems]="menuItem.subs" />
  } @else {
    <a
      aria-expanded="false"
      class="nav-link px-md-0 px-lg-0 px-sm-0 px-xl-1"
      routerLink="{{ menuItem.link }}"
      role="button"
      [id]="menuItem.id">
      <span [hidden]="!menuItem.icon" class="{{ menuItem.icon }}"></span>
      <svg-icon [hidden]="!menuItem.svgIcon"></svg-icon>
      {{ menuItem.label | appTranslate | async }}
    </a>
  }
}
<!-- } -->
