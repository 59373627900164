import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '@Services/utility.service';
import { FindSortCodeFormComponent } from './find-sort-code-form/find-sort-code-form.component';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { TranslatePipe } from "../../../Shared/pipes/translate.pipe";
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-find-sort-code-modal',
    templateUrl: './find-sort-code-modal.component.html',
    styleUrls: ['./find-sort-code-modal.component.scss'],
    imports: [BtnComponent, FindSortCodeFormComponent, TranslatePipe, CommonModule]
})
export class FindSortCodeModalComponent implements OnInit {
  constructor(
    public uS: UtilityService, 
    public dialogRef: MatDialogRef<FindSortCodeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { clientNo: string }
  ) {}

  ngOnInit(): void {}

  close(data?: any) {
    this.dialogRef.close(data);
  }
}
