import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { ISearchResponse2 } from 'ets-fe-ng-sdk';
import { Observable, lastValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '@Services/api.service';
import { ClientViewData, IClientViewData } from '@Shared/models/client-desk.interface';
import { IClientSearchObj, IClientSearchResult, IValidation } from '../client-extras/client.interface';
import { UtilityService } from '@Services/utility.service';

@Injectable({ providedIn: 'root' })
export class FindClientService {
  get baseURL() {
    return environment.apiBaseUrl + '/rest/';
  }

  constructor(
    public apiService: ApiService,
    public uS: UtilityService,
  ) {}
  search = (data: IClientSearchObj) => {
    console.log(data);
    return this.apiService
      .get<ISearchResponse2<IClientSearchResult>>(this.baseURL + 'client/search', {
        ...data,
        pageNumber: data.pageNumber == 0 ? 1 : data.pageNumber,
      })
      .pipe(catchError((e) => of(null)));
  };

  searchClientsByName = (name: string) =>
    this.search({ name }).pipe(
      map((res) =>
        res?.content?.sort2('fullName', true)?.map((c) => ({ code: c.clientNo, title: c.fullName })),
      ),
    );

  getClientList(data: any) {
    return this.apiService.get(this.baseURL + 'client/view/' + data).pipe(map((r) => new ClientViewData(r)));
  }
  getClientNoByProviderNo(providerNo: string) {
    return this.apiService.getText(this.baseURL + 'client/no/provider/' + providerNo);
  }
  getClientViewData = (clientNo: string) => {
    return this.apiService
      .get(this.baseURL + 'client/view/' + clientNo)
      .pipe(map((r) => new ClientViewData(r)));
  };
  /**
   * Checks if a client no is valid using the backend
   * @param clientNo
   * @returns
   */
  validateClientNo = (clientNo: string) => {
    return this.apiService.get<IValidation>(this.baseURL + 'client/validate/clientno/' + clientNo);
  };

  /**
   * Form validator for client numbers and patches the form with the client data
   * @param control
   * @returns
   */
  clientNoValidator = async (control: AbstractControl) => {
    // debugger
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.getClientViewData(val)
      .toPromise()
      .then((res) => {
        if (!res.clientNo) return { notFound: true };
        if (!res.type) return { notFound: true };
        (control.parent as FormGroup)?.patchValue({ clientData: res });
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };

  /**
   * Form validator for client numbers and does not patch the form with the client data
   * @param control
   * @returns
   */
  clientNoValidatorLite = async (control: AbstractControl) => {
    // debugger
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.uS.asyncValidation({
      uniqueKey: 'asyncValidateClientNoFindClientervice',
      callback: () =>
        lastValueFrom(
          this.validateClientNo(val),
          // .pipe(map((r) => r?.response))
        ),
    });
  };
}
