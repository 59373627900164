<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row align-items-end">
    <div class="col-md-4">
      <app-input-basic
        type="text"
        label="First Name"
        [required]="true"
        [showValidationMsg]="true"
        [form]="form"
        name="firstName"
        #firstName />
    </div>
    <div class="col-md-4">
      <app-input-basic
        type="text"
        label="Surname"
        [showValidationMsg]="true"
        [required]="true"
        [form]="form"
        name="surname"
        #surname />
    </div>
    <div class="col-md-4">
      <app-input-basic
        type="text"
        label="Middle Name"
        [showValidationMsg]="true"
        [form]="form"
        name="middleName"
        #middleName />
    </div>
    <div class="col-md-4">
      <app-autocomplete
        type="select"
        label="Reason"
        [form]="form"
        name="reason"
        valueField="code"
        labelType="ct"
        [options]="reasons"
        #reasonTag
        [showValidationMsg]="true" />
    </div>
    <div class="col-md-4">
      <app-input-basic
        type="date"
        label="Date of birth"
        [form]="form"
        name="dateOfBirth"
        [maxToday]="true"
        [showValidationMsg]="true"
        [required]="!dateNotRequiredOptions[reasonTag.valueSignal()]"
        #dateOfBirth />
    </div>
    @if (!appS.hasBusLine) {
      <div class="col-md-4">
        <app-input-basic
          type="select"
          label="Business Line"
          [form]="form"
          name="busLine"
          labelType="vl"
          valueField="value"
          [options]="appS.busLines"
          #busLine />
        <app-validation-message [input]="busLine" />
      </div>
    }
    <!-- <div class="col-md-4">
      <app-input-basic [showValidationMsg]="true" type="datetime-local" label='Datetime of birth' [maxToday]="true" [form]="form" name='dateOfBirthTime' />
    </div> -->
  </div>
</form>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    {{ 'Contact' | appTranslate | async }}
  </mat-expansion-panel-header>
  <form [formGroup]="form">
    <div class="row align-items-end">
      <div class="col-md-4">
        <app-input-basic
          type="email"
          label="Email"
          [form]="form"
          name="email"
          [showValidationMsg]="true"
          #email />
      </div>
      <div class="col-md-4">
        <app-input-basic
          type="email"
          label="Alternate Email"
          [form]="form"
          name="alternativeEmail"
          [showValidationMsg]="true"
          #alternativeEmail />
      </div>
      <div class="col-md-4">
        <!-- {{pn.valid}} -->
        <app-phone-number
          name="phoneNumber"
          [form]="form"
          label="Phone Number"
          [countryCode3]="countryTag.valueSignal()"
          [showValidation]="pn.invalidSignal()"
          [showValidationIcon]="false"
          [showValidationMsg]="true"
          #pn />
        <!-- <app-validation-message [control]="validation?.phoneNumber"/> -->
      </div>
      @for (subForm of altPhoneNumbersForm.controls; track subForm; let i = $index) {
        <div class="col-md-4">
          <div class="row align-items-end">
            <div class="col">
              <app-phone-number
                label="Alternate Phone Number {{ altPhoneNumbersFormLabel(i) }}"
                name="{{ i }}"
                [countryCode3]="countryTag.valueSignal()"
                [showValidation]="pn2.invalidSignal()"
                [showValidationIcon]="false"
                [form]="altPhoneNumbersForm"
                #pn2 />
            </div>
            <div class="col-auto d-flex">
              <app-btn
                icon="delete"
                type="danger-outline"
                [iconBtn]="true"
                (mclick)="removePhoneNumberForm(i)"></app-btn>
              <span class="mx-1"></span>
              <app-btn
                icon="add"
                type="outline"
                [iconBtn]="true"
                [disabled]="altPhoneNumbersForm.controls.length == maxAltNumAllowed"
                (mclick)="addPhoneNumberForm(i + 1)" />
            </div>
          </div>
          <app-validation-message [control]="validation?.alternativePhoneNumber" />
        </div>
      }
      <div class="col-md-4">
        <app-autocomplete
          label="Nationality"
          [form]="form"
          name="nationality"
          [showValidation]="nationalityTag.invalidSignal()"
          [showValidationIcon]="false"
          [options]="countryList"
          labelType="cd"
          valueField="code"
          #nationalityTag />
        <app-validation-message [input]="nationalityTag" />
      </div>
      <div class="col-md-4">
        <app-input-basic
          type="text"
          label="Address"
          [placeholder]="uS.config.FormPresets.addressPlaceholder"
          [form]="form"
          name="address"
          #addressTag />
        <app-validation-message />
      </div>
      <div class="col-md-4">
        <app-autocomplete
          label="Address Country"
          [form]="form"
          name="addressCountry"
          [showValidation]="countryTag.invalidSignal()"
          [showValidationIcon]="false"
          (mchange)="setRegionList()"
          [options]="countryList"
          labelType="cd"
          valueField="code"
          #countryTag />
        <app-validation-message [input]="countryTag" />
      </div>
      <div class="col-md-4">
        <app-autocomplete
          [disabled]="!countryTag.hasValue"
          label="Address Region"
          [form]="form"
          name="addressRegion"
          (mchange)="setStateList()"
          [showValidation]="locationRegion.invalidSignal()"
          [showValidationIcon]="false"
          [options]="regionList"
          labelType="cd"
          valueField="code"
          #locationRegion />
        <app-validation-message />
      </div>
      <div class="col-md-4">
        <app-autocomplete
          [disabled]="!locationRegion.hasValue"
          label="Address State"
          [form]="form"
          name="addressState"
          (mchange)="setTownList()"
          [showValidation]="stateTag.invalidSignal()"
          [showValidationIcon]="false"
          [options]="stateList"
          labelType="cd"
          valueField="code"
          #stateTag />
        <app-validation-message />
      </div>
      <div class="col-md-4">
        <app-autocomplete
          [disabled]="!stateTag.hasValue"
          label="Address Town"
          [form]="form"
          name="addressTown"
          [showValidation]="townTag.invalidSignal()"
          [showValidationIcon]="false"
          [options]="townList"
          labelType="cd"
          valueField="code"
          #townTag />
        <app-validation-message />
      </div>
      <div class="col-md-4">
        <app-input-basic type="text" label="Address Geolocation" [form]="form" name="addressGeolocation" />
        <app-validation-message />
      </div>
    </div>
  </form>
</mat-expansion-panel>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    {{ 'Details' | appTranslate | async }}
  </mat-expansion-panel-header>
  <form [formGroup]="form">
    <div class="row align-items-center">
      <div class="col-md-4">
        <app-autocomplete
          label="Title"
          [form]="form"
          name="title"
          [showValidationMsg]="true"
          [options]="titles"
          labelType="ct"
          valueField="code" />
      </div>
      <div class="col-md-4">
        <app-autocomplete
          label="Gender"
          [form]="form"
          name="gender"
          [showValidationMsg]="true"
          [options]="genders"
          labelType="ct"
          valueField="code" />
      </div>
      <div class="col-md-4">
        <app-input-basic type="text" label="Place Of Birth" [form]="form" name="placeOfBirth" />
        <app-validation-message />
      </div>
      <div class="col-md-4">
        <app-input-basic type="checkbox" label="VIP" [form]="form" name="vip" />
        <app-validation-message />
      </div>
      <div class="col-md-4">
        <app-input-basic type="checkbox" label="PEP" [form]="form" name="pep" />
        <app-validation-message />
      </div>

      <div class="col-md-4">
        <app-autocomplete
          label="Marital Status"
          [form]="form"
          name="maritalStatus"
          [showValidationMsg]="true"
          [options]="maritalStatuses"
          labelType="ct"
          valueField="code" />
      </div>
    </div>
  </form>
</mat-expansion-panel>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    {{ 'Dates' | appTranslate | async }}
  </mat-expansion-panel-header>
  <form [formGroup]="form">
    <div class="row align-items-end">
      <div class="col-md-4">
        <app-input-basic
          type="date"
          label="Retirement Date"
          [form]="employmentInformationForm"
          name="retirementDate"
          [showValidationMsg]="true" />
      </div>
    </div>
  </form>
</mat-expansion-panel>
