import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { ICodeDescription } from '@Shared/models/index.model';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ApiService } from '@Services/api.service';
import {
  ICompany,
  ICompanyEmailGatewayIn,
  ICompanyInfo,
  ICompanyPayoutMethod,
  ICompanyPortals,
  ICompanyPrediction,
  ICompanyRates,
  ICompanySuspiciousInflow,
  ICompanyTier,
  ICompanyTolerance,
} from 'projects/evolutics-client-ui/src/app/Life/Setup/organization/companies/create-company/create-company.model';
import { EVFunctions, ISearchResponse2 } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  baseURL = environment.apiBaseUrl + '/rest';
  retryCount = 1;
  noRetry = 0;

  constructor(public apiService: ApiService) {}

  getCodeAndCompanyCodeTeirCat(tierCat: string): Observable<any> {
    return this.apiService
      .get(`${this.baseURL}/company/tier/code/companycode/${tierCat}`)
      .pipe(retry(this.retryCount));
  }

  getPaymentMethods(companyCode: string): Observable<any> {
    return this.apiService
      .get(`${this.baseURL}/company/payment/outward/${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  getPaymentMethodsByUserCode(userCode: string): Observable<any> {
    return this.apiService
      .get(`${this.baseURL}/company/payout/methods/${userCode}`)
      .pipe(retry(this.retryCount));
  }

  deleteTierByID = (id: number[]) =>
    id?.length ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + '/company/tiers', id) : of(null);

  deleteEmailGatewayOutByID = (id: number) =>
    id
      ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + `/company/email/gateway/out/${id}`)
      : of(null);

  deleteToleranceByID = (id: number) =>
    id ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + '/company/tolerance/' + id) : of(null);

  deleteSuspiciousTransactionByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/suspicious/inflow/' + id) : of(null);

  deletePayoutMethodsByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/payout/methods/' + id) : of(null);

  deleteSuspiciousByID = (id: number) =>
    id ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + '/company/suspicious/' + id) : of(null);

  deleteUserMailGroupByID = (id: number) =>
    id
      ? this.apiService.deleteWithBodyWithEndorsment(this.baseURL + '/company/mail/user-group/' + id)
      : of(null);

  deleteLossTriggerByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/loss/trigger/' + id) : of(null);

  deleteGatewayInByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/email/gateway/in/' + id) : of(null);

  deleteGatewayOutByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/email/gateway/out/' + id) : of(null);

  deleteTiersByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/tiers/' + id) : of(null);

  deleteCompanySuspenseByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/suspense/history/' + id) : of(null);

  deleteCompanyFundingGroupingByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/funding/grouping/' + id) : of(null);

  deleteCompanyCertificateIssueByID = (id: number) =>
    id ? this.apiService.delete(this.baseURL + '/company/certificate-issue/' + id) : of(null);

  getCompanyCodeByCompanyCode(companyCode: string) {
    if (!companyCode) return of<ICompany>(null);
    return this.apiService
      .get<ICompany>(`${this.baseURL}/company/${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  searchCompanyCodeByCompanyCode(companyCode: string) {
    return this.apiService
      .get<ISearchResponse2<ICompanyInfo>>(`${this.baseURL}/company/search?code=${companyCode}`)
      .pipe(
        map((res) => res.content[0]),
        retry(this.retryCount),
      );
  }

  getCompanyCodeAndDesc = () => {
    return this.apiService.getCodes<ICodeDescription>(`${this.baseURL}/company/code/desc`);
  };
  getCompanyDesc = (companyCode: string) => {
    return this.getCompanyCodeAndDesc().pipe(
      map((r) => r?.find((x) => x.code?.toUpperCase() == companyCode?.toUpperCase())),
    );
  };
  getCompanyCodeByCompanyCodeFactory = () => (code: string) =>
    this.getCompanyCodeByCompanyCode(code).pipe(
      map((r) => EVFunctions.strConcatenator2(code, r?.companyInfo?.description)),
    );

  getCompanyMailGroup(companyCode: string) {
    return this.apiService.get<
      ISearchResponse2<{
        companyCode: string;
        mailGroup: string;
        name: string;
        userEmail: string;
        userName: string;
      }>
    >(`${this.baseURL}/company/mail/user-group/search`, {
      companyCode,
    });
  }

  getCompanyTiers(companyCode: string) {
    return this.apiService.get<ISearchResponse2<ICompanyTier>>(
      `${this.baseURL}/company/tiers/${companyCode}`,
    );
  }

  getCompanyPayoutPrediction(companyCode: string) {
    return this.apiService.get<ISearchResponse2<ICompanyPrediction>>(
      `${this.baseURL}/company/payout/prediction/search?companyCode=${companyCode}`,
    );
  }

  getCompanyTolerance(companyCode: string) {
    return this.apiService.get<ISearchResponse2<ICompanyTolerance>>(
      `${this.baseURL}/company/tolerance/${companyCode}`,
    );
  }

  getCompanySuspiciousTransactions(companyCode: string) {
    return this.apiService.get<ISearchResponse2<ICompanySuspiciousInflow>>(
      `${this.baseURL}/company/suspicious/inflow/${companyCode}`,
    );
  }

  getCompanyPayoutMethods(companyCode: string) {
    return this.apiService.get<ISearchResponse2<ICompanyPayoutMethod>>(
      `${this.baseURL}/company/payout/methods/${companyCode}`,
    );
  }

  createCompanyMailGroup(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/mail/user-group`, payload)
      .pipe(retry(this.retryCount));
  }

  updateCompanyMailGroup(payload) {
    return this.apiService
      .put(`${this.baseURL}/company/mail/user-group/bulk`, payload)
      .pipe(retry(this.retryCount));
  }

  getCompanyLossTrigger(company) {
    return this.apiService
      .get<
        ISearchResponse2<{
          authBy: string;
          authOn: string;
          companyCode: string;
          createdBy: string;
          createdOn: string;
          description: string;
          event: string;
          lossRatio: number;
          processNode: number;
          updatedBy: string;
          updatedOn: string;
        }>
      >(`${this.baseURL}/company/loss/trigger/search?companyCode=${company}`)
      .pipe(retry(this.retryCount));
  }

  getCompanyGatewayIn(company) {
    return this.apiService
      .get<ICompanyEmailGatewayIn>(`${this.baseURL}/company/email/gateway/in/${company}`)
      .pipe(retry(this.retryCount));
  }

  getCompanyGatewayOut(company) {
    return this.apiService
      .get(`${this.baseURL}/company/email/gateway/out/${company}`)
      .pipe(retry(this.retryCount));
  }

  createCompanyTiers(payload) {
    return this.apiService.post(`${this.baseURL}/company/tiers`, payload).pipe(retry(this.retryCount));
  }

  createCompanyLossTrigger(payload) {
    return this.apiService.post(`${this.baseURL}/company/loss/trigger`, payload).pipe(retry(this.retryCount));
  }

  createCompanyGatewayIn(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/email/gateway/in`, payload)
      .pipe(retry(this.retryCount));
  }

  createCompanyGatewayOut(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/email/gateway/out`, payload)
      .pipe(retry(this.retryCount));
  }

  createCompanyTolerance(payload) {
    return this.apiService.post(`${this.baseURL}/company/tolerance`, payload).pipe(retry(this.retryCount));
  }

  createCompanySuspiciousTransactions(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/suspicious/inflow`, payload)
      .pipe(retry(this.retryCount));
  }

  createCompanyPayoutMethods(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/payout/methods`, payload)
      .pipe(retry(this.retryCount));
  }

  updateCompanyLossTrigger(payload) {
    return this.apiService
      .put(`${this.baseURL}/company/loss/trigger/bulk`, payload)
      .pipe(retry(this.retryCount));
  }

  getCompanyDefaultWorkflows(companyCode: string) {
    return this.apiService
      .get<
        ISearchResponse2<{
          auth1Task: string;
          auth2Task: string;
          auth3Task: string;
          claimDVReceiptTask: string;
          claimRequestTask: string;
          coInsuranceIdentity: string;
          companyCode: string;
          complianceTask: string;
          controlFeedBackTask: string;
          controlPayoutTask: string;
          createReceiptTask: string;
          dnReminderTask: string;
          dvAuthTask: string;
          healthPreAuthReqTask: string;
          lossRatioTriggerTask: string;
          medicalUwReqTask: string;
          nbRequestTask: string;
          nbValidationFailedTask: string;
          newProviderTask: string;
          proposalUwRequestTask: string;
          quoteAuthReqTask: string;
          quoteUwRequestTask: string;
          quoteUwResponseTask: string;
          receiptNoticeTask: string;
          reinsClaimNotifyTask: string;
          reinsDecisionTask: string;
          reinsFeedbackTask: string;
          riskSurveyReqTask: string;
          sendToBankTask: string;
          specialAgeRatingUwTask: string;
          userFeedbackTask: string;
          uwDecisionTask: string;
        }>
      >(`${this.baseURL}/company/tasks/search`, { companyCode })
      .pipe(retry(this.retryCount));
  }

  createCompanyDefaultWorkflows(payload) {
    return this.apiService.post(`${this.baseURL}/company/tasks`, payload).pipe(retry(this.retryCount));
  }

  updateCompanyDefaultWorkflows(id, payload) {
    return this.apiService.put(`${this.baseURL}/company/tasks/${id}`, payload).pipe(retry(this.retryCount));
  }

  getCompanyRates(companyCode: string) {
    return this.apiService
      .get<ICompanyRates>(`${this.baseURL}/company/rates/${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  createCompanyRates(payload) {
    return this.apiService.post(`${this.baseURL}/company/rates`, payload).pipe(retry(this.retryCount));
  }

  updateCompanyRates(id, payload) {
    return this.apiService.put(`${this.baseURL}/company/rates/${id}`, payload).pipe(retry(this.retryCount));
  }

  getCompanyCertIssue(companyCode: string) {
    return this.apiService
      .get(`${this.baseURL}/company/certificate-issue/search?companyCode=${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  createCompanyCertIssue(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/certificate-issue`, payload)
      .pipe(retry(this.retryCount));
  }

  updateCompanyCertIssue(id, payload) {
    return this.apiService
      .put(`${this.baseURL}/company/certificate-issue/${id}`, payload)
      .pipe(retry(this.retryCount));
  }

  getCompanyFundGrouping(companyCode: string) {
    return this.apiService
      .get(`${this.baseURL}/company/funding/grouping/search?companyCode=${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  createCompanyFundGrouping(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/funding/grouping`, payload)
      .pipe(retry(this.retryCount));
  }

  updateCompanyFundGrouping(id, payload) {
    return this.apiService
      .put(`${this.baseURL}/company/funding/grouping/${id}`, payload)
      .pipe(retry(this.retryCount));
  }

  getCompanySuspense(companyCode: string) {
    return this.apiService
      .get(`${this.baseURL}/company/suspense/history/search?companyCode=${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  createCompanySuspense(payload) {
    return this.apiService
      .post(`${this.baseURL}/company/suspense/history`, payload)
      .pipe(retry(this.retryCount));
  }

  updateCompanySuspense(id, payload) {
    return this.apiService
      .put(`${this.baseURL}/company/suspense/history/${id}`, payload)
      .pipe(retry(this.retryCount));
  }

  getCompanyPortals(companyCode: string) {
    return this.apiService
      .get<{
        agentPortalUrl: string;
        agtPortalUserBasis: string;
        bancassurancePortalUrl: string;
        clPortalUserBasis: string;
        claimPortalUrl: string;
        clientPortalUrl: string;
        companyCode: string;
        companyId: number;
        complaintUrl: string;
        garragePortalUrl: string;
        healthPortalUrl: string;
        id: number;
        mgtAppUrl: string;
        proPortalUserBasis: string;
        providerPortalUrl: string;
        reinsPortalUrl: string;
        salesPortalUrl: string;
        usrPortalUserBasis: string;
        warehouseUrl: string;
      }>(`${this.baseURL}/company/portals/${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  createCompanyPortals(payload) {
    return this.apiService.post(`${this.baseURL}/company/portals`, payload).pipe(retry(this.retryCount));
  }

  updateCompanyPortals(id, payload) {
    return this.apiService.put(`${this.baseURL}/company/portals/${id}`, payload).pipe(retry(this.retryCount));
  }

  getBranchCodeAndDescByCompanyCode = (companyCode: string) => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `/branch/code/desc/${companyCode}`);
  };
  /**
   * get all branch codes with description
   * @returns observable
   */
  getBranchCodeAndDesc = () => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `/branch/code/desc`);
  };

  /**
   * get all branch code with description
   * @returns observable
   */
  getBranchDescByCode = (branchCode, companyCode) => {
    return branchCode && companyCode
      ? this.apiService.get<ICodeDescription>(this.baseURL + `/branch/${branchCode}/${companyCode}`)
      : of(null);
  };

  getCompanyidAndSenderEmailByCompanyCode(companyCode: string): Observable<any> {
    return this.apiService
      .get(`${this.baseURL}/company/id/sender/email/${companyCode}`)
      .pipe(retry(this.retryCount));
  }

  getCompanyidAndSenderEmail(): Observable<any> {
    return this.apiService.get(`${this.baseURL}/company/id/sender/email`).pipe(retry(this.retryCount));
  }

  // deleteEmployee(id: number): Observable<any> {
  //     return this.http.delete(`${this.URL}/${id}`, { responseType: 'text' });
  // }

  // update(id: string, employee: any): Observable<employee> {
  //     return this.http.put<employee>(`${this.URL}` + id, employee, this.httpOptions);
  // }

  // find(id: string): Observable<employee> {
  //     return this.http.get<employee>(`${this.URL}` + id);
  // }

  // Error handling
  handleError(error: { error: { message: string }; status: any; message: any }) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);

    return throwError(errorMessage);
  }
}
