import { Injectable, signal, computed } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@Services/api.service';
import {
  IAdminUser,
  IUser,
  IUserGroup,
  IUserGroupSearchResult,
  IUserGroups,
  IUserSearchResult,
} from '../shared/models/admin-user.interface';
import { UserGroup } from '@Shared/models/life/user/Users';
import { CreateCrmUserGroup } from '@Shared/models/life/user/UserGroup';
import { map, retry, switchMap, tap } from 'rxjs';
import { StorageService } from '@Services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService {
  baseUrl = environment.apiBaseUrl + '/admin/rest/user/';
  baseCoreUrl = environment.apiBaseUrl + '/rest/user/';
  baseCoreUrl2 = environment.apiBaseUrl + '/rest/users/';
  baseGroupUrl = this.baseUrl + 'group/';
  noRetry = 0;
  readonly userKey = 'adminUser';
  protected readonly _user = signal<IAdminUser>(null);
  readonly user = computed(() => this._user());
  constructor(
    public apiService: ApiService,
    public sS: StorageService,
  ) {}

  /**
   *
   * @param id Id of usergroup
   * @returns Observable of UserGroup details
   */
  getUserGroupById(id: string) {
    return this.apiService.get<IUserGroup>(this.baseGroupUrl + id);
  }

  /**
   *
   * @param id Id of user
   * @returns Observable of user details
   */
  getUserById(id: string) {
    return this.apiService.get<IUser>(this.baseUrl + id);
  }

  /**
   *
   * @param id Id of usergroup
   * @param data Data to be updated
   * @returns Observable of update process
   */
  updateUserGroupById(id: string, data: any) {
    return this.apiService.put<IUserGroup>(this.baseGroupUrl + id, data);
  }

  /**
   *
   * @param id Id of user
   * @param data Data to be updated
   * @returns Observable of update process
   */
  updateUserById(id: string, data: any) {
    return this.apiService.put<IUser>(this.baseUrl + id, data);
  }

  /**
   *
   * @param id Id of usergroup
   * @returns Details of usergroup deleted
   */
  deleteUserGroupById(id: string) {
    return this.apiService.delete<IUserGroup>(this.baseUrl + id);
  }

  /**
   *
   * @param id Id of user
   * @returns Details of user deleted
   */
  deleteUserById(id: string) {
    return this.apiService.delete<IUser>(this.baseUrl + id);
  }

  /**
   *
   * @param data Data to be created
   * @returns Observable of Created UserGroup
   */
  createUserGroup(data: any) {
    return this.apiService.post<IUserGroup>(this.baseGroupUrl, data);
  }

  createUserGroup2(createUserGroup: any) {
    return this.apiService
      .postWithEndorsment<CreateCrmUserGroup>(this.baseGroupUrl, createUserGroup)
      .pipe(retry(this.noRetry));
  }

  /**
   *
   * @param data Data to be created
   * @returns Observable of Created UserGroup
   */
  createUser(data: any) {
    return this.apiService.post<IUser>(this.baseUrl, data);
  }

  /**
   *
   * @param query Query to use to get user groups
   * @returns Array of user groups created
   */
  searchUserGroup(query: any) {
    return this.apiService.get<IUserGroupSearchResult>(this.baseGroupUrl + 'search/', query);
  }

  getUserGroups() {
    return this.apiService.get<IUserGroups[]>(this.baseCoreUrl2 + 'group');
  }

  searchUserGroupByGroup(query: any) {
    return this.apiService.get<any>(this.baseGroupUrl + 'search/', query);
  }

  searchUser = (query?: any) => {
    return this.apiService.get<IUserSearchResult>(this.baseUrl + 'search/', query).pipe(
      map((r) => {
        r.content = r.content.filter((x) => x.username);
        return r;
      }),
    );
  };

  searchCoreUser = (query?: any) => {
    return this.apiService.get<IUserSearchResult>(this.baseCoreUrl + 'search', query);
  };

  /**
   *
   * @param query Query to use to identify user by username
   * @returns Properties of user object
   */
  searchUserByUserName(query: any) {
    return this.apiService.get<IUserSearchResult>(this.baseUrl + 'search' + '?username=' + query);
  }

  loginUser(username: string, password: string) {
    return this.apiService
      .post<IAdminUser>(`${this.baseUrl}login`, {
        username,
        password,
      })
      .pipe(
        tap((r) => this.saveToLocal(r)),
        switchMap((r) => this.getUserByUsername(r.username)),
        tap((user) => {
          const _user = this.getFromLocal();
          this.saveToLocal({ ..._user, ...user });
        }),
      );
  }
  logout() {
    this.sS.clear();
    location.href = '/';
  }

  saveToLocal(user: IAdminUser) {
    environment.adminUserSignal.set(user);
    environment.userSignal.set(user as any);
    this.sS.saveItem(this.userKey, user);
  }
  getFromLocal() {
    environment.adminUserSignal.set(this.sS.getItem(this.userKey));
    this._user.set(environment.adminUser);
    // debugger;
    environment.userSignal.set(environment.adminUser as any);
    return environment.adminUser;
  }

  recoverCrendentials(username: string) {
    return this.apiService.get<any>(this.baseUrl + `recover-credentials/${username}`);
  }

  getUserByUsername(username: string) {
    return this.apiService.get<any>(this.baseUrl + `username/${username}`);
  }

  changePassword(username: string, data: any) {
    return this.apiService.get<any>(this.baseUrl + `recover-credentials/${username}`, data);
  }
}
