 <div class="row align-items-end mt-4">
   <div class="col-md-4">
     <app-input-basic type="number" label='Height' hint="Metres" [form]="form" name='height'
       (mchange)="onHeightWeightChange($event)" #heightTag>
     </app-input-basic>
     <app-validation-message [input]="heightTag">
     </app-validation-message>
   </div>
   <div class="col-md-4">
     <app-input-basic type="number" label='Weight' hint="KG" [form]="form" name='weight'
       (mchange)="onHeightWeightChange($event)" #weightTag>
     </app-input-basic>
     <app-validation-message [input]="weightTag">
     </app-validation-message>
   </div>
   <div class="col-md-4">
     <app-input-basic type="select" [options]="cS.getCodesByCodeSubGroup|functionCaller1:'BLOOD_GROUP'|async"
       label='Blood Group' [form]="form" name='bloodGroup' valueField="code" labelType="ct" #bloodGroupTag>
     </app-input-basic>
     <app-validation-message [input]="bloodGroupTag">
     </app-validation-message>
   </div>
   <div class="col-md-4">
     <app-input-basic type="number" label='BMI' [form]="form" name='bmi' #bmiTag>
     </app-input-basic>
     <app-validation-message [input]="bmiTag">
     </app-validation-message>
   </div>
   <div class="col-md-4">
     <app-input-basic type="select" [options]="cS.getCodesByCodeSubGroup|functionCaller1:'GENOTYPE'|async"
       label='Genotype' [form]="form" name='genotype' valueField="code" labelType="ct" #genotypeTag>
     </app-input-basic>
     <app-validation-message [input]="genotypeTag">
     </app-validation-message>
   </div>
   <div class="col-md-4">
     <app-input-basic type="checkbox" label='Pre-existing Condition' [form]="form" name='preExistCondition'
       #preExistConditionTag>
     </app-input-basic>
     <app-validation-message [input]="preExistConditionTag">
     </app-validation-message>
   </div>
   @if (preExistConditionTag.valueSignal()) {

     @for (subForm of conditions.controls; track subForm; let i = $index) {
  <div class="col-md-4">
       <div class="row align-items-end">
         <div class="col">
           <app-input-basic type="text" label='Pre-existing Conditions' [form]="subForm" name='condition'
             #preExistConditionsTag>
           </app-input-basic>
         </div>
         <div class="col-auto">
           <div class=" d-flex">
             <app-btn type="danger-outline" [iconBtn]="true" icon="delete" (mclick)="removeCondition(i)"></app-btn>
             <div class=" mx-1"> </div>
             <app-btn type="outline" [iconBtn]="true" icon="add" (mclick)="addCondition(i+1)"></app-btn>
           </div>
         </div>
       </div>

       <app-validation-message [input]="preExistConditionsTag">
       </app-validation-message>
     </div>
}
   
}

 </div>
