import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, of, throwError } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { ICodeDescription } from '@Shared/models/index.model';
import { ICountryData } from '../ApiModels/location.interface';
import { ApiService } from '@Services/api.service';
import { LocationService as LS } from '@Services/location.service';
import { EVFunctions } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends LS {
  constructor(public apiService: ApiService) {
    super(apiService);
  }

  ___getRegion = (country: string): Promise<any[]> => {
    //if (!country) return of([]);
    return this.apiService.getWithLocalCache<any[]>(`${this.URL}/region/country/${country}`).toPromise();
  };

  getRegionWithCache = (country: string): Observable<any[]> => {
    if (!country) return of([]);
    return this.apiService.getFromlocal<any[]>(`${this.URL}/region/country/${country}`);
  };

  getTown = (stateCode: string): Observable<any> => {
    if (!stateCode) return of([]);
    return this.apiService.getWithLocalCache<any[]>(`${this.URL}/town/${stateCode}`);
  };

  getTownWithCache = (stateCode: string) => {
    if (!stateCode) return of([]);
    return this.apiService.getFromlocal<any[]>(`${this.URL}/town/${stateCode}`);
  };

  getTownByCode = (townCode: string) => {
    if (!townCode) return of(null);
    return this.apiService.getWithLocalCache<ICodeDescription>(`${this.URL}/town/info/${townCode}`);
  };
  getTownDescriptionByCode = (townCode: string) => {
    return this.getTownByCode(townCode).pipe(
      map((r) => EVFunctions.strConcatenator2(townCode, r?.description)),
    );
  };

  getRegionByCode = (regionCode: string): Observable<any> => {
    if (!regionCode) return of([]);
    return this.apiService.getWithLocalCache<ICodeDescription>(`${this.URL}/region/info/${regionCode}`);
  };

  getPorts() {}

  getCountryName = async (code: string) => {
    const res = await lastValueFrom(this.getCountryByCode(code));
    return EVFunctions.strConcatenator2(code, res?.description);
  };

  getStateName = async (code: string) => {
    const res = await lastValueFrom(this.getStatesByCode(code));
    return EVFunctions.strConcatenator2(code, res?.description);
  };

  getRegionName = async (code: string) => {
    const res = await lastValueFrom(this.getRegionByCode(code));
    return EVFunctions.strConcatenator2(code, res?.description);
  };

  getTownName = async (code: string) => {
    const res = await lastValueFrom(this.getTownByCode(code));
    return EVFunctions.strConcatenator2(code, res?.description);
  };
}
