import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { PolicyEndpointsService } from 'projects/evolutics-client-ui/src/app/Life/policy-desk/policy-desk-extras/policy-endpoints.service';
import { CodeService } from '@Services/code.service';
import { FunctionCaller1 } from 'ets-fe-ng-sdk';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ValidationMessageComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';

@Component({
    selector: 'app-client-health',
    templateUrl: './client-health.component.html',
    styleUrls: ['./client-health.component.scss'],
    imports: [
        InputBasicComponent,
        ValidationMessageComponent,
        NgIf,
        NgFor,
        BtnComponent,
        AsyncPipe,
        FunctionCaller1,
    ]
})
export class ClientHealthComponent implements OnInit {
  @Input() form: FormGroup;

  constructor(
    public cS: CodeService,
    public pdS: PolicyEndpointsService,
  ) {}

  ngOnInit(): void {}

  get conditions() {
    return this.form.controls.clientHealthPreExist as FormArray;
  }
  addCondition(i = this.conditions.length) {
    this.conditions.insert(
      i,
      new FormGroup({
        clientNo: new FormControl<string>(null),
        condition: new FormControl<string>(null),
      })
    );
  }
  removeCondition(i: number) {
    if (this.conditions.length > 1) this.conditions.removeAt(i);
  }

  onHeightWeightChange(event){
    if(this.form.value.weight && this.form.value.height){
      this.form.controls['bmi'].disable()
      this.pdS.computeBMIByWeightAndHeight(this.form.value.height,  this.form.value.weight)
      .subscribe((r)=> this.form.controls['bmi'].patchValue(r))

    }else{
       this.form.controls['bmi'].enable()
    }

  }


}
