import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindSortCodeModalComponent } from './find-sort-code-modal.component';
import { SharedModule } from '@Shared/shared.module';
import { FindSortCodeFormComponent } from './find-sort-code-form/find-sort-code-form.component';

@NgModule({
    imports: [CommonModule, SharedModule, FindSortCodeModalComponent, FindSortCodeFormComponent],
    exports: [FindSortCodeModalComponent, FindSortCodeFormComponent]
})
export class FindSortCodeModalModule {}
