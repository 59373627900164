import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LayoutModule } from '../Layout/layout.module';
import { CardModule } from './components/card/card.module';
import { DocumentsNameDisplayModule } from './components/documents-name-display/documents-name-display.module';
import { EditableTextCaseModule } from './components/editable-text-case/editable-text-case.module';
import { ExportTableModule } from './components/export-table/export-table.module';

import {
  InputNGModelComponent,
  FormErrorComponent,
  RowActionsModule,
  LabelModule,
  LoaderModule,
  FormGeneratorModule,
  TextCase2Component,
  TextCase1Component,
  ComponentsModule,
  ToggleInputFormComponent,
  TableHttpsComponent,
  TablePlainComponent,
  SDKTranslateNoLoaderPipe,
} from 'ets-fe-ng-sdk';
import { TranslateSingleNoLoaderPipe, TranslateSinglePipe } from '@evolutics/translation'
import { FormLinkModule } from './components/form-link/form-link.module';
import { FormTabHeadersModule } from './components/form-tab-headers/form-tab-headers.module';
import { InfoIconModule } from './components/info-icon/info-icon.module';
import ModalComponents from './components/modal-components/modal.components';
import { ModalFormModule } from './components/modal-form/modal-form.module';
import { ModalHeaderModule } from './components/modal-header/modal-header.module';
import { ModalModule } from './components/modal/modal.module';
import { PageTemplateModule } from './components/page-template/page-template.module';
import { PageToComponentModule } from './components/page-to-component/page-to-component.module';
import { SvgIconModule } from './components/svg-icon/svg-icon.module';
import { TextModule } from './components/text/text.module';
import { TranslatorCaseComponent } from './components/translator-case/translator-case.component';
import { ViewFormButtonsModule } from './components/view-form-buttons/view-form-buttons.module';
import { DirectivesModule } from './directives/index.directive';
import { UtilityPipesModule } from './pipes/utility.pipe';
import {
  AutocompleteModule,
  BtnModule,
  AddItemComponent,
  DetailsBtnModule,
  InputModule,
  PhoneNumberComponent,
  TextCaseInputComponent,
  InputComponents,
} from 'ets-fe-ng-sdk';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserPreviewModalComponent } from './components/user-preview-modal/user-preview-modal.component';

export const _SharedModule = [
  ...AutocompleteModule,
  ...BtnModule,
  ...InputComponents.default,
  ...ModalComponents,
  AddItemComponent,
  CardModule,
  CommonModule,
  ComponentsModule,
  DetailsBtnModule,
  DirectivesModule,
  DocumentsNameDisplayModule,
  EditableTextCaseModule,
  ExportTableModule,
  FontAwesomeModule,
  FormErrorComponent,
  FormGeneratorModule,
  FormLinkModule,
  FormsModule,
  FormTabHeadersModule,
  InfoIconModule,
  InputNGModelComponent,
  SDKTranslateNoLoaderPipe,
  LabelModule,
  LayoutModule,
  LoaderModule,
  MatButtonModule,
  MatDialogModule,
  TranslateSinglePipe,
  TranslateSingleNoLoaderPipe,
  MatExpansionModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatTooltipModule,
  ModalFormModule,
  ModalHeaderModule,
  ModalModule,
  PageTemplateModule,
  PageToComponentModule,
  PhoneNumberComponent,
  ReactiveFormsModule,
  RouterModule,
  RowActionsModule,
  SvgIconModule,
  TableHttpsComponent,
  TablePlainComponent,
  TextCase1Component,
  TextCase2Component,
  TextCaseInputComponent,
  TextModule,
  ToggleInputFormComponent,
  TranslatorCaseComponent,
  UserPreviewModalComponent,
  UtilityPipesModule,
  ViewFormButtonsModule,
] as const;

@NgModule({
  declarations: [],
  imports: [CommonModule, ..._SharedModule],
  exports: [..._SharedModule],
  providers: [DecimalPipe],
})
export class SharedModule {}
