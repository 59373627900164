import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ApiService } from './api.service';
import { ICodeDescription } from '../Shared/models/index.model';
import { ICountryData } from '../Shared/models/location.interface';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  URL = environment.apiBaseUrl + '/rest/location';
  noRetry = 0;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(public apiService: ApiService) {}

  getCountries = () => {
    return this.apiService.getWithLocalCache<ICountryData[]>(`${this.URL}/country`);
  };

  getCountriesWithCache = () => {
    return this.apiService.getFromlocal<ICountryData[]>(`${this.URL}/country`);
  };
  getCountryByCode = (countryCode: string) => {
    return countryCode
      ? this.apiService.getWithLocalCache<ICountryData>(`${this.URL}/country/${countryCode}`)
      : of(null);
  };

  getStates = (region?: string) => {
    if (!region) return this.apiService.getWithLocalCache<ICodeDescription[]>(`${this.URL}/state`);
    return this.apiService.getWithLocalCache<ICodeDescription[]>(`${this.URL}/state/${region}`);
  };

  getStatesWithCache = (region?: string): Observable<any> => {
    if (!region) return this.apiService.getFromlocal<ICodeDescription[]>(`${this.URL}/state`);
    return this.apiService.getFromlocal<any[]>(`${this.URL}/state/${region}`);
  };

  getStatesCountry = (country: string) => {
    if (!country) return of(<ICodeDescription[]>[]);
    return this.apiService.getWithLocalCache<ICodeDescription[]>(`${this.URL}/state/country/${country}`);
  };

  getRegionStates = (region: string) => {
    if (!region) return of(<ICodeDescription[]>[]);
    return this.apiService.getWithLocalCache<ICodeDescription[]>(`${this.URL}/state/${region}`);
  };

  getStatesByCode = (code: string) => {
    if (!code) return of([]);
    return this.apiService.getWithLocalCache<any>(`${this.URL}/state/info/${code}`);
  };

  getLGA = (): Observable<any> => {
    return this.apiService.get<any>(`${this.URL}/lga`).pipe(retry(this.noRetry));
  };

  getRegion = (country: string): Observable<any> => {
    if (!country) return of([]);
    return this.apiService.getWithLocalCache<any[]>(`${this.URL}/region/country/${country}`);
  };

  getTown = (stateCode: string): Observable<any> => {
    if (!stateCode) return of([]);
    return this.apiService.getWithLocalCache<any[]>(`${this.URL}/town/${stateCode}`);
  };

  getTownByCode = (townCode: string): Observable<any> => {
    if (!townCode) return of([]);
    return this.apiService.getWithLocalCache<ICodeDescription>(`${this.URL}/town/info/${townCode}`);
  };

  getRegionByCode = (regionCode: string): Observable<any> => {
    if (!regionCode) return of({});
    return this.apiService.getWithLocalCache<ICodeDescription>(`${this.URL}/region/info/${regionCode}`);
  };

  getCityByCode = (cityCode: string): Observable<any> => {
    if (!cityCode) return of({});
    return this.apiService.getWithLocalCache<ICodeDescription>(`${this.URL}/city/info/${cityCode}`);
  };

  getRegionMapping = () => {
    return this.apiService
      .get<any[]>(`${environment.apiBaseUrl}/rest/region/mapping`)
      .pipe(map((res) => res.map((x) => ({ code: x.regionMapCode, description: x.description }))));
  };

  // Error handling
  handleError(error: { error: { message: string }; status: any; message: any }) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);

    return throwError(errorMessage);
  }

  getCountryList() {
    return this.apiService.getWithLocalCache(this.URL + '/country');
  }
  getRegionList(countryCode: string = '') {
    const country = countryCode ? `/country/${countryCode}` : '';
    return this.apiService.getWithLocalCache(`${this.URL}/region${country}`);
  }
  getStateList(regionCode: string = '') {
    return this.apiService.getWithLocalCache(`${this.URL}/state/${regionCode}`);
  }
  getTownList(cityCode: string = '') {
    return this.apiService.getWithLocalCache(`${this.URL}/town/${cityCode}`);
  }
}
