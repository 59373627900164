<loader [loading]="loading">
  <form [formGroup]="corporateForm">
    <app-company-info-form [form]="companyForm"></app-company-info-form>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        {{'Contact Information'|appTranslate|async}}
      </mat-expansion-panel-header>
      <app-contact-info-form [form]="contactInformationArray"></app-contact-info-form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        {{'Directors'|appTranslate|async}}
      </mat-expansion-panel-header>
      <app-directors-form [form]="directorsArray"></app-directors-form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        {{'Payment Information'|appTranslate|async}}
      </mat-expansion-panel-header>
      <app-create-account-form #paymentInfo></app-create-account-form>
    </mat-expansion-panel>

    <div class="row mt-5 justify-content-end">
      <div class="col" (click)="uS.logForm(corporateForm)">
      </div>
      <div class="col-auto">
        <app-btn [form]="corporateForm" group="submit" (mclick)="onSubmit()">
        </app-btn>
      </div>
      <div class="col-12">
        <form-error [form]="corporateForm" />
      </div>
    </div>
  </form>
</loader>