<nav class="">
  <div class="nav nav-pills nav-justified  d-flex justify-content-center border-0" id="nav-tab" role="tablist">
    @for (tab of tabs; track tab; let index = $index) {
  <button class="nav-link  border-0"
      [ngClass]="selectedIndex == index? 'active' : ''" (click)="changeTab(index)" type="button" role="tab"
      aria-controls="nav-home">{{tab.label|appTranslate|async}}
    </button>
}
  </div>
</nav>
