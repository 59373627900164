<modal-header header="View Document" [dialogRef]="dialogRef" />
<loader [loading]="loading()">
  <app-card header="Basic Details">
    <div class="row">
      <div class="col-md">
        <div class="row row-cols-md-3 g-2 justify-content-between">
          @for (item of lbls1; track item) {
            <div class=" ">
              <text-case-2
                [stacked]="true"
                [mini]="true"
                [coloredVal]="false"
                [label]="item.label"
                [route]="item.route"
                [value]="item.value"
                [formatter]="item.formatter"></text-case-2>
            </div>
          }
        </div>
      </div>
    </div>
  </app-card>
  <div class="row justify-content-center row-cols-lg-6 g-3 my-4">
    <div>
      <app-btn [animate]="true" [text]="'Download'" (mclick)="downloadDocument(btn)" #btn></app-btn>
    </div>
  </div>
</loader>
