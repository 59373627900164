import { Component, DestroyRef, inject } from '@angular/core';
import { PageTemplateComponent as PTC } from 'ets-fe-ng-sdk';
import { BehaviorSubject, Observable, switchMap, tap, catchError, of } from 'rxjs';
import { UtilityService } from '@Services/utility.service';
import { Environment } from '@Shared/models/environment.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  template: ``,
  standalone: true,
})
export class PageTemplateComponent<ModelData = any> extends PTC<ModelData, Environment> {
  readonly uS = inject(UtilityService);
  protected readonly baseRefresh$ = new BehaviorSubject<IRefresher>({ useLoader: true });
  private _lastGoodRefreshedValue: any;
  readonly baseRefreshSignal = toSignal(this.baseRefresh$);
  protected baseRefresherWrapper = <T>(ob$: (config: IRefresher) => Observable<T>) =>
    this.baseRefresh$.pipe(
      switchMap((config) =>
        ob$(config).pipe(
          tap((r) => (this._lastGoodRefreshedValue = r)),
          catchError((e) => (config.ignoreLastGoodValue ? of(null) : of(this._lastGoodRefreshedValue as T))),
        ),
      ),
    );
  protected destroyerRef = inject(DestroyRef);

  baseRefresh(config: IRefresher = { useLoader: true }) {
    this.baseRefresh$.next(config);
  }
}

interface IRefresher {
  useLoader?: boolean;
  ignoreLastGoodValue?: boolean;
}
