import { Component, OnInit, computed } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from '@Services/utility.service';
import { IdlerService } from './idler.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-idler',
    templateUrl: './idler.component.html',
    styleUrls: ['./idler.component.scss'],
    imports: [NgIf]
})
export class IdlerComponent implements OnInit {
  protected readonly timeLeftFormatted = computed(() =>
    this.uS.secondsToHourMinSec(this.idlerService.timeLeft()),
  );
  protected readonly timeLeftString = computed<string>(() => {
    const { hours, mins, secs } = this.timeLeftFormatted() || {};
    return `${hours ? hours + 'hrs' : ''} ${mins ? mins + 'min' : ''} ${secs ? secs + 's' : ''}`;
  });
  constructor(
    public dialogRef: MatDialogRef<IdlerComponent>,
    public idlerService: IdlerService,
    public uS: UtilityService,
  ) {}

  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
  }
}
