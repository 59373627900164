@if (schema()) {
  <app-find-item
    label="Provider"
    [autoOrder]="false"
    [formSchema]="schema()"
    [searchFunction]="search()"
    [showAdditionalColumns]="true"
    [displayedColumns]="displayedColumns()"
    [autoFormatSchema]="true" />
}
