<mat-menu #menu="matMenu" class="dashboard-menu glass" [xPosition]="xPosition()">
  @for (subMenuitem of computedMenuItems(); track subMenuitem.id; let isLast = $last) {
    @if (subMenuitem.hasSub) {
      <a
        mat-menu-item
        [matMenuTriggerFor]="subMenuTag.matMenu()"
        class="dropdown-item"
        [ngClass]="{ underline: !isLast }">
        <!-- <span class=""> -->
        {{ subMenuitem.label | appTranslate | async }}
        <!-- </span> -->
      </a>
      <app-menu-items #subMenuTag [xPosition]="xPosition()" [menuItems]="subMenuitem.subs"></app-menu-items>
    } @else {
      <a
        mat-menu-item
        class="dropdown-item"
        routerLink="{{ subMenuitem.link }}"
        [ngClass]="{ underline: !isLast }">
        {{ subMenuitem.label | appTranslate | async }}
      </a>
    }
  }
</mat-menu>
