import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { FormNavigationComponent } from './form-navigation/form-navigation.component';
import { NavigationComponent } from './navigation.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { IsNavMenuEnabledPipe, UtilityPipesModule } from '@Shared/pipes/utility.pipe';
import { LoaderModule } from 'ets-fe-ng-sdk';
import { DirectivesModule } from '@Shared/directives/index.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MenuItemComponent } from './bottom-nav/menu-item/menu-item.component';
import { MenuItemsComponent } from './bottom-nav/menu-items/menu-items.component';
import { VerticalNavComponent } from './vertical-nav/vertical-nav.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SearchModule } from '@Shared/components/search/search.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgIconModule } from '@Shared/components/svg-icon/svg-icon.module';
import { SideMenuItemComponent } from './side-navigation/side-menu-item/side-menu-item.component';
import { SideMenuItemsComponent } from './side-navigation/side-menu-items/side-menu-items.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { VerticalNavClientComponent } from './vertical-nav-client/vertical-nav-client.component';

const comps = [
  // SubMenuItemComponent,
  BottomNavComponent,
  FormNavigationComponent,
  MenuItemComponent,
  MenuItemsComponent,
  NavigationComponent,
  SideMenuItemComponent,
  SideMenuItemsComponent,
  SideNavigationComponent,
  TopNavComponent,
  VerticalNavComponent,
  VerticalNavClientComponent,
];
@NgModule({
  exports: comps,
  imports: [
    CommonModule,
    DirectivesModule,
    FontAwesomeModule,
    LoaderModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    RouterModule,
    SearchModule,
    SvgIconModule,
    UtilityPipesModule,
    ...comps,
  ],
  providers: [IsNavMenuEnabledPipe],
})
export class NavigationModule {}
