import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { CMSContentTypeComponent } from '../cms-content-type.base.component';
import { BtnComponent, LoaderComponent, SDKTranslateNoLoaderPipe, ToAnyPipe } from 'ets-fe-ng-sdk';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, Observable, of, switchMap, tap } from 'rxjs';
import { TranslatePipe } from '../../../../../../Shared/pipes/translate.pipe';

@Component({
  selector: 'render-cms-file',
  standalone: true,
  imports: [
    CommonModule,
    BtnComponent,
    ToAnyPipe,
    PdfViewerModule,
    LoaderComponent,
    TranslatePipe,
    SDKTranslateNoLoaderPipe,
  ],
  templateUrl: './render-cms-file.component.html',
  styleUrl: './render-cms-file.component.scss',
})
export class RenderCMSFileComponent extends CMSContentTypeComponent {
  readonly loading = signal(true);
  readonly arrayBuffer = toSignal(
    toObservable(this.content).pipe(
      filter((content) => content.meta.displayFileContent && !content._hidePreview),
      tap(() => this.loading.set(true)),
      switchMap((content) =>
        content?.docId ? this.service.documentService.getFileArrayBuffer(content.docId) : of(null),
      ),
      tap(() => this.loading.set(false)),
    ),
  );
  readonly pdfSrc = computed(() => {
    return { data: this.arrayBuffer() };
  });
}
