import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, computed } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { environment } from '@environments/environment';
import { EVFunctions, FunctionCaller1, TyperPipe } from 'ets-fe-ng-sdk';
import { UtilityService } from '@Services/utility.service';
import { reinsMenu } from '../../../reins-configs/reins-menu.config';
import { MatMenuModule } from '@angular/material/menu';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { AppService } from '../../../services/app.service';
import { ReinsuranceHomeService } from '../../../reinsurance-home/reinsurance-home.service';
import { TranslateNoLoaderPipe } from '@evolutics/translation';
import { EntityImageThumbnailComponent } from "../../../../../../evolutics-shared-lib/src/lib/Reusables/reusable-comps/entity-image-thumbnail/entity-image-thumbnail.component";

@Component({
  selector: 'app-reins-dashboard-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    FunctionCaller1,
    TyperPipe,
    TranslateNoLoaderPipe,
    MatListModule,
    RouterLinkActive,
    MatMenuModule,
    EntityImageThumbnailComponent
],
  templateUrl: './reins-dashboard-layout.component.html',
  styleUrl: './reins-dashboard-layout.component.scss',
})
export class ReinsDashboardLayoutComponent {
  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  environment = environment;
  menu = reinsMenu;

  sampleMenuObj = this.menu[0];

  hours = new Date().getHours();
  readonly welcomeMessage = `Good ${this.period(this.hours)}`;

  private _mobileQueryListener: () => void;
  readonly fullName=computed(()=>environment.webUser?.fullName)
  readonly providerNo=computed(()=>environment.webUser?.providerNo)

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public uS: UtilityService,
    public authS: AuthenticationService,
    public appS: AppService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  period(hours = this.hours): string {
    return hours >= 21 || hours <= 5
      ? `Night`
      : hours >= 18
        ? `Evening`
        : hours >= 12
          ? `Afternoon`
          : `Morning`;
  }
}
