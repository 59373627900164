import { Component, EventEmitter, OnInit, Output, computed } from '@angular/core';
import {
  faHeart,
  faTruck,
  faHeartbeat,
  faSquareRootAlt,
  faChartLine,
  faSignal,
  faChartPie,
  faDollyFlatbed,
  faCoins,
  faReceipt,
  faFileWord,
  faChartBar,
  faCopy,
  faUser as faHuman,
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppService } from '@Services/app.service';
import { MenuItem } from '@Shared/models/IMenuItem';
import { UtilityService } from '@Services/utility.service';
import { SearchService } from '@Shared/components/search/search-extras/search.service';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';

import { FilterMenuArrayPipe } from '@Shared/pipes/utility.pipe';
import { TranslatePipe } from '../../../Shared/pipes/translate.pipe';
import { NavbarSearchComponent } from '../../../Shared/components/search/search-components/navbar-search/navbar-search.component';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgStyle, NgFor, AsyncPipe } from '@angular/common';
import { IconComponent } from '../../../Shared/components/icon/icon.component';
import { ToAnyPipe } from 'ets-fe-ng-sdk';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
    imports: [
        RouterLink,
        NgIf,
        MatButtonModule,
        NgStyle,
        NgFor,
        RouterLinkActive,
        NavbarSearchComponent,
        AsyncPipe,
        FilterMenuArrayPipe,
        TranslatePipe,
        IconComponent,
        ToAnyPipe,
    ]
})
export class TopNavComponent implements OnInit {
  faHeart = faHeart;
  faTruck = faTruck;
  faHeartbeat = faHeartbeat;
  faSquareRootAlt = faSquareRootAlt;
  faDollyFlatbed = faDollyFlatbed;
  faChartLine = faChartLine;
  faChartPie = faChartPie;
  faSignal = faSignal;
  faCoins = faCoins;
  faReceipt = faReceipt;
  faFileWord = faFileWord;
  faChartBar = faChartBar;
  navColor = '';
  system = '';
  faCopy = faCopy;
  faHumn = faHuman;
  routeSub: Subscription;
  @Output() toggleSideMenu = new EventEmitter<boolean>();
  readonly favIcon = computed(() => this.uS.environment?.images?.favicon);

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public appS: AppService,
    public uS: UtilityService,
    public sS: SearchService,
  ) {
    this.routeSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: any) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route: any) => route.data),
      )
      .subscribe((event: any) => {
        console.log(event);
        if (event['color'] && event['system']) {
          this.navColor = event['color'].toString();
          this.system = event['system'].toString();
          document.getElementsByTagName('body')[0].className = this.system;
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }
  setCurrentMenu(item: MenuItem) {
    this.appS.setCurrentTopMenu(item.system as any);
  }
}
