<div class="container-scroller">
  <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
      <div class="me-3">
        <button
          class="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-bs-toggle="minimize">
          <span class="icon-menu"></span>
        </button>
      </div>
      <div>
        <a class="navbar-brand brand-logo" href="/">
          <img src="assets/img/ets/logos/logo.dark.ets.png" alt="logo" />
        </a>
        <a class="navbar-brand brand-logo-mini" href="/">
          <img src="{{ uS.environment?.images?.favicon }}" alt="logo" />
          <!-- {{environment.appName}} -->
        </a>
      </div>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-top">
      <ul class="navbar-nav">
        <li class="nav-item font-weight-semibold d-none d-lg-block ms-0">
          <h1 class="welcome-text">
            @if (appS.isHome) {
              {{ welcomeMessage | appTranslateNL | async }},
              <span class="text-black fw-bold">{{ uS.environment.webUser?.fullName }}</span>
            } @else {
              {{ appS.pageName() | appTranslateNL | async }}
            }
          </h1>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <!-- <li class="nav-item">
          <form class="search-form" action="#">
            <i class="icon-search text-primary pointer"></i>
            <input type="search" class="form-control" placeholder="Search Here" title="Search here" />
          </form>
        </li> -->
        <li class="nav-item dropdown d-none d-lg-block user-dropdown">
          <a class="nav-link d-flex gap-2 align-items-center" [matMenuTriggerFor]="profileMenu">
            <!-- <img class="img-xs rounded-circle" src="../assets/images/faces/face8.jpg" alt="Profile image" /> -->

            <div class="fs-20">
              {{ fullName() }}
            </div>
            <entity-image-thumbnail [refNo]="providerNo()" refCat="PRO" [viewOnly]="true" />
          </a>
          <mat-menu #profileMenu="matMenu" xPosition="after" class="">
            <button mat-menu-item routerLink="/profile">
              <i class="fa fa-user"></i>
              My Profile
            </button>
            <button mat-menu-item (click)="authS.logout()">
              <i class="fa fa-sign-out"></i>
              Log Out
            </button>
          </mat-menu>
        </li>
      </ul>
      <button
        class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-bs-toggle="offcanvas">
        <span class="icon-menu"></span>
      </button>
    </div>
  </nav>
  <!-- partial -->
  <div class="container-fluid page-body-wrapper">
    <!-- partial:partials/_sidebar.html -->
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <ng-template #menuItem let-obj>
          @if (obj | typer: sampleMenuObj; as item) {
            <li class="nav-item">
              @if (item.subs?.length) {
                <a
                  class="nav-link pointer"
                  [ngClass]="{ collapsed: subsContainer.hidden }"
                  (click)="subsContainer.hidden = !subsContainer.hidden"
                  attr.aria-expanded="{{ !subsContainer.hidden }}">
                  @if (item.icon) {
                    <i class="{{ item.icon }} me-2"></i>
                  }
                  <span class="menu-title w-100">{{ item.label }}</span>
                  <i class="fa fa-chevron-down"></i>
                </a>
                <div
                  class="collapse"
                  [ngClass]="{ show: !subsContainer.hidden }"
                  [hidden]="true"
                  #subsContainer>
                  <ul class="nav flex-column sub-menu">
                    @for (subItem of item.subs; track subItem.id) {
                      <ng-container
                        *ngTemplateOutlet="menuItem; context: { $implicit: subItem }"></ng-container>
                    }
                  </ul>
                </div>
              } @else {
                <a class="nav-link" routerLinkActive="active" routerLink="{{ item.link }}">
                  @if (item.icon) {
                    <i class="{{ item.icon }} me-2"></i>
                  }
                  <span class="menu-title">{{ item.label }}</span>
                </a>
              }
            </li>
          }
        </ng-template>

        @for (item of menu; track item.link) {
          @if (item.subs?.length) {
            <li class="nav-item nav-category">
              <i class="{{ item.icon }} me-2"></i>
              {{ item.label }}
            </li>
            @for (subItem of item.subs; track subItem.link) {
              <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: subItem }" />
            }
          } @else {
            <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: item }" />
          }
        }
      </ul>
    </nav>
    <!-- partial -->
    <div class="main-panel">
      <div class="content-wrapper" [class.notHome]="!appS.isHome">
        <router-outlet />
      </div>
    </div>
    <!-- main-panel ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
