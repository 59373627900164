import { Component, EventEmitter, Input, OnInit, Output, computed } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FindSortCodeModalComponent } from '@Reusables/reusable-comps/find-sort-code-modal/find-sort-code-modal.component';
import { AccountService } from 'projects/evolutics-client-ui/src/app/Services/account.service';
import { BankService } from 'projects/evolutics-client-ui/src/app/Services/bank.service';
import { DocumentService } from 'projects/evolutics-client-ui/src/app/Services/document.service';
import { LocationService } from 'projects/evolutics-client-ui/src/app/Services/location.service';
import { UtilityService } from '@Services/utility.service';
import { IAccount } from '@Shared/models/account.interface';
import { environment } from '@environments/environment';
import { IndividualAgentService } from '../../../agent-desk/new-individual-agent/services/individual-agent.service';
import { ICodeDescription, ICodeTitle } from '@Shared/models/index.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@Shared/shared.module';
import { FindSortCodeModalModule } from '@Reusables/reusable-comps/find-sort-code-modal/find-sort-code-modal.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ICountryData } from '@Shared/models/location.interface';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';
import { FileUploadComponent } from 'ets-fe-ng-sdk';
// import { FileUploadComponent } from "../../../../../../../evolutics-shared-lib/src/lib/Shared/components/file-upload/file-upload.component";

@Component({
    selector: 'app-create-account-form',
    templateUrl: './create-account-form.component.html',
    styleUrls: ['./create-account-form.component.scss'],
    imports: [CommonModule, SharedModule, FindSortCodeModalModule, MatDialogModule, FileUploadComponent]
})
export class CreateAccountFormComponent implements OnInit {
  isSalesPortal: boolean = environment.isSalesPortal || false;
  readonly form = new FormGroup({
    accountName: new FormControl<string>(null),
    accountNo: new FormControl(null, this.validateAccountNo.bind(this)),
    accountType: new FormControl<string>(null),
    authBy: new FormControl<any>(null),
    bankAdd: new FormControl<any>(null),
    bankBranch: new FormControl<any>(null),
    bankCity: new FormControl<any>(null),
    bankCode: new FormControl<any>(null),
    bankName: new FormControl<any>(null),
    bankSwiftCd: new FormControl<any>(null),
    bankTown: new FormControl<any>(null),
    blocked: new FormControl<boolean>(null),
    blockedDate: new FormControl<any>(null),
    clientId: new FormControl<number>(null),
    clientNo: new FormControl<string>(null),
    country: new FormControl<string>(null),
    currency: new FormControl<any>(null),
    id: new FormControl<number>(null),
    mmProvider: new FormControl<any>(null),
    no: new FormControl<string>(null),
    payeeNo: new FormControl<string>(null),
    provider: new FormControl(null),
    sortCode: new FormControl<string>(null),
  });
  @Input() accProofFile: File;
  @Input() bankModel: boolean;
  readonly countries = toSignal(this.locS.getCountries());
  readonly countriesMap = computed(() => this.countries()?.toMap('code')||{});
  @Input() clientNo: string;
  @Input() clientName: string;
  @Input() bankDetail: Partial<IAccount>;
  @Input() mobileModel: boolean;
  @Input() hideBlocked: boolean = true;
  @Input() showSubmissionBtn: boolean;
  @Output() submitted = new EventEmitter<IAccount>();
  readonly countryFormValue = toSignal(
    this.form.controls.country.valueChanges.pipe(map(() => this.form.controls.country.getRawValue())),
  );
  readonly countryData = computed(() => this.countriesMap()[this.countryFormValue()]);
  loading: boolean;
  message: any;
  constructor(
    public uS: UtilityService,
    public individualService: IndividualAgentService,
    public locS: LocationService,
    public bankS: BankService,
    public accS: AccountService,
    public docS: DocumentService,
  ) {}

  ngOnInit(): void {
    if (this.clientNo) this.form.controls.clientNo.patchValue(this.clientNo);
    if (this.bankDetail) this.form.patchValue(this.bankDetail);
    console.log(this.clientNo, this.bankDetail);
    // if (this.data.showSubmissionBtn) this.showSubmissionBtn = true;
    this.showBankModel();
    this.form.controls.country.valueChanges
      .pipe(filter((code) => this.bankDetail?.country != code))
      .subscribe((r) => {
        this.form.patchValue({ bankCode: null,bankName: null, sortCode: null, accountNo: null });
        this.setAccountName();
      });
    this.form.controls.bankCode.valueChanges.subscribe(async (r) => {
      this.form.patchValue({
        sortCode: r ? await this.bankS.getSortCodeByBankCode(r).toPromise() : null,
        bankBranch: 'HQ',
      });
    });
    // debugger;
    this.setAccountName();
  }
  setAccountName() {
    if (this.clientName)
      this.form.patchValue({
        accountName: this.clientName,
      });
  }

  validateAccountNo(control: AbstractControl) {
    const val: string = control?.value;
    if (!val) return null;
    const countryData = this.countryData();
    if (countryData?.bankAccountMaxLength && val.toString().length > +countryData.bankAccountMaxLength)
      return { maxLength: true };
    if (countryData?.bankAccountMinLength && val.toString().length < +countryData.bankAccountMinLength)
      return { minLength: true };
    return null;
  }
  get sortCode() {
    return this.form.controls.sortCode?.value;
  }
  openSortCodeModal() {
    this.uS.dialogOpener(
      FindSortCodeModalComponent,
      {
        maxHeight: '80vh',
        maxWidth: '90%',
        width: '80%',
      },
      (r) => {
        if (r) {
          r.sortCode
            ? this.form.patchValue({
                sortCode: r.sortCode,
              })
            : this.form.controls.sortCode.reset();
        }
      },
    );
  }
  setBankNameTitle($event: ICodeTitle<string>) {
    debugger;
    this.form.patchValue({ bankName: $event?.title });
  }
  setBankNameDesc($event: ICodeDescription) {
    debugger;
    this.form.patchValue({ bankName: $event?.description });
  }
  showBankModel() {
    this.bankModel = true;
    this.mobileModel = false;
    this.form.reset({ ...this.bankDetail, clientNo: this.clientNo || this.bankDetail?.clientNo });
    delete this.form.value.payeeNo;
    this.form.patchValue({
      accountType: 'BK',
    });
  }

  showMobileModel() {
    this.mobileModel = true;
    this.bankModel = false;
    this.form.reset({ ...this.bankDetail, clientNo: this.clientNo || this.bankDetail?.clientNo });
    delete this.form.value.accountName;
    delete this.form.value.bankBranch;
    delete this.form.value.sortCode;
    this.form.patchValue({
      accountType: 'MM',
    });
  }

  async submit() {
    this.loading = true;
    this.message = null;
    try {
      const formdata = this.form.getRawValue();
      formdata.clientNo = formdata.clientNo || this.clientNo;
      if (this.mobileModel) {
        delete formdata.bankName;
        delete formdata.sortCode;
        delete formdata.bankBranch;
        delete formdata.accountName;
      }
      if (formdata.id) {
        const res = await this.accS.updateAccountWithEnd(formdata as any).toPromise();
        this.uS.handleEndorsementSubmmission({
          endorsementRes: res,
          endorsementMsgPrefix: `Client bank account`,
          msgPrefix: `Client bank account`,
          cb: async (r) => {
            if (this.accProofFile) {
              const doc = await this.docS
                .upload(this.accProofFile, {
                  boxNo: environment?.userProfile?.users.docBox,
                  category: 'CLI',
                  sensitivity: 'L',
                  subCategory: 'RIB',
                  title: 'ProofofAccount',
                  refCat: 'CLI',
                  refNo: this.clientNo,
                })
                .toPromise();
            }
            this.submitted.emit(res.data);
          },
          endorsementCB: (r) => {
            this.submitted.emit();
          },
        });
      } else {
        const account = await this.accS.createAccountWithEnd(formdata as any).toPromise();
        if (this.accProofFile) {
          const doc = await this.docS
            .upload(this.accProofFile, {
              boxNo: environment?.userProfile?.users.docBox,
              category: 'CLI',
              sensitivity: 'L',
              subCategory: 'RIB',
              title: 'ProofofAccount',
              refCat: 'CLI',
              refNo: this.clientNo,
            })
            .toPromise();
        }
        this.submitted.emit(account.data);
        if (account) this.uS.notify('Created Successfully', 1);
        // this.uS.handleEndorsementSubmmission({
        //   endorsementRes: account,
        //   endMsgPrefix: `Client bank account`,
        //   msgPrefix: `Client bank account`,
        //   cb: async (r) => {
        //     if (this.accProofFile) {
        //       const doc = await this.docS
        //         .upload(this.accProofFile, {
        //           boxNo: environment?.userProfile?.users.docBox,
        //           category: 'CLI',
        //           sensitivity: 'L',
        //           subCategory: 'RIB',
        //           title: 'ProofofAccount',
        //           refCat: 'CLI',
        //           refNo: this.clientNo,
        //         })
        //         .toPromise();
        //     }
        //     this.submitted.emit(account.data);
        //   },
        //   endorsementCB: (r) => {
        //     this.submitted.emit();
        //   },
        // });
      }
      this.loading = false;
    } catch (e) {
      // this.uS.notify(e, 0);
      console.log(e);
      this.message = e;
      this.loading = false;
    }
  }
}
