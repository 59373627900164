import { ModalBodyDirective, ModalFooterDirective } from 'ets-fe-ng-sdk';
import { ModalFormComponent } from '../modal-form/modal-form.component';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { ModalComponent } from '../modal/modal.component';
export { ModalFormComponent } from '../modal-form/modal-form.component';
export { ModalHeaderComponent } from '../modal-header/modal-header.component';
export { ModalComponent } from '../modal/modal.component';

export const ModalComponents = [
  ModalComponent,
  ModalFormComponent,
  ModalBodyDirective,
  ModalFooterDirective,
  ModalHeaderComponent,
] as const;

export default ModalComponents;
