export type FileNameFormat =
  | 'NON_POLICY_DOCUMENT'
  | 'POLICY_DOCUMENT'
  | 'POLICY_DOCUMENT_WITH_SUFFIX'
  | 'POLICY_DOCUMENT_SUFFIX';

// export const formats = {
//   NON_POLICY_DOCUMENT: 'refCat_refNo_docSubCat_title.ext',
//   POLICY_DOCUMENT: 'refCat_policyNo-policyCode_docSubCat_title.ext',
//   POLICY_DOCUMENT_WITH_SUFFIX: 'refCat_policyNo-policyCode-policyNoSuffix_docSubCat_title.ext',
// };

export const formats = {
  NON_POLICY_DOCUMENT: 'refCat_refNo_docSubCat_title',
  POLICY_DOCUMENT: 'refCat_policyNo-policyCode_docSubCat_title',
  POLICY_DOCUMENT_WITH_SUFFIX: 'refCat_policyNo-policyCode-policyNoSuffix_docSubCat_title',
};
