import { MediaMatcher } from '@angular/cdk/layout';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { MenuItem } from "@Shared/models/IMenuItem";
import { AppService } from '@Services/app.service';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { UtilityService } from '@Services/utility.service';
import { Router, RouterLink } from '@angular/router';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import PS from 'perfect-scrollbar';

import { IsNavMenuEnabledPipe } from '@Shared/pipes/utility.pipe';
import { TranslatePipe } from "../../../Shared/pipes/translate.pipe";
import { ImageLoaderDirective } from '../../../Shared/directives/index.directive';
import { MatMenuModule } from '@angular/material/menu';
import { ETSResponsivenessDirectiveModule } from 'ets-fe-ng-sdk';
import { NavbarSearchComponent } from '../../../Shared/components/search/search-components/navbar-search/navbar-search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'vertical-nav-client',
    templateUrl: './vertical-nav-client.component.html',
    styleUrls: ['./vertical-nav-client.component.scss'],
    imports: [NgClass, NgIf, MatToolbarModule, MatButtonModule, MatIconModule, NavbarSearchComponent, MatSidenavModule, ETSResponsivenessDirectiveModule, MatTreeModule, RouterLink, MatMenuModule, ImageLoaderDirective, AsyncPipe, TranslatePipe]
})
export class VerticalNavClientComponent {
  @Input() fullScreen: boolean;
  @Input() isHome: boolean;
  @Input() showMenu: boolean;
  @Input() showSearch: boolean;
  @Input() title: string;
  @Input() createButton: boolean;
  @Input() findButton: boolean;
  @Input() set menuItems(v: MenuItem[]) {
    if(!v?.length)return
    v.forEach((x) => (x.level = 0));
    this.dataSource = new MatTreeNestedDataSource<MenuItem>(); 
     this.dataSource.data = v;
  }

  id = 'matSideNav' + this.uS.genRandomID;
  helpLink = environment.helpLink;
  user = environment.userProfile;
  treeControl = new NestedTreeControl<MenuItem>((node) => node.subs);

  dataSource: MatTreeNestedDataSource<MenuItem>;

  @ViewChild('snav') drawer: MatDrawer;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appS: AppService,
    public uS: UtilityService,
    public router: Router,
    public authS: AuthenticationService,
    public imeS: IsNavMenuEnabledPipe
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit(): void {

    // this.drawer.open();
    const doc = document.querySelector<HTMLDivElement>(
      `#${this.id} .mat-drawer-inner-container`
    );
    if (doc) {
      doc.style.position = 'relative';
      const ps = new PS(doc, {
        wheelSpeed: 0.5,
        swipeEasing: false,
        wheelPropagation: true,
        minScrollbarLength: 40,
      });
    }
  }

  hasChild = (_: number, node: MenuItem) => node.hasSub && this.shouldShow(_,node);
  shouldShow = (_: number, node: MenuItem) => this.isHome?this.imeS.transform(node)?.enabled:true;
  signOut() {
    this.uS.startLoader();
    this.authS.logout().then(() => this.router.navigateByUrl('/'));
  }
}
interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
}
