import { NgModule } from "@angular/core";
import { FilterTasksPipe } from "./filter-tasks.pipe";
import { FormatMinutesPipe } from "./format-minutes.pipe";
import { SlaColourToLevelPipe, SlaLevelColourPipe } from "./sla-level-colour.pipe";
import { TruncateToPipe } from "./truncate-to.pipe";
import { WorkflowsFilterPipe } from "./workflows-filter.pipe";

@NgModule({
    imports: [FilterTasksPipe,
        FormatMinutesPipe,
        SlaColourToLevelPipe,
        SlaLevelColourPipe,
        TruncateToPipe,
        WorkflowsFilterPipe],
    exports: [
        FilterTasksPipe,
        FormatMinutesPipe,
        SlaColourToLevelPipe,
        SlaLevelColourPipe,
        TruncateToPipe,
        WorkflowsFilterPipe,
    ],
    providers: [FormatMinutesPipe]
})
export class WorkflowPipesModule {}