<div class="row align-items-center">
  <label
    [matTooltip]="hint"
    class="col-md-{{ stacked ? '12 pb-0' : mini ? 'auto' : '' }} m-0 hide-scroll-x {{labelClass()}}"
    [ngClass]="{ 'text-primary': coloredLbl, light, 'overflow-x-auto': wrapLabel }">
    {{ label | appTranslate | async }}
  </label>
  <div class="{{ stacked ? 'col-12' : 'col-md' }} position-relative {{valueClass()}}">
    <div class="row align-items-center">
      @if (editing) {
        <div class="col-9 pe-0">
          <app-input-basic
            [form]="form"
            [stacked]="true"
            [showLabel]="false"
            [name]="name"
            [type]="type"></app-input-basic>
        </div>
        <div class="col-auto">
          <i (click)="reset()" class="ps-1 pointer fas fa-undo-alt text-danger"></i>
        </div>
      } @else {
        <a
          [matTooltip]="valueHint"
          [mrouterLink]="route || (routeFunc | functionCaller1: value | async)"
          class="{{ !coloredVal ? '' : 'text-primary' }} col-{{
            !stacked ? 'auto' : showEditing ? '9' : '12'
          }} lbl">
          <div class="hide-scroll-x overflow-x-auto">
            {{ (value | valueFormatter: formatter | async) || '-' }}
          </div>
        </a>
        <div class="col-auto" *ngIf="showEditing">
          <i
            (click)="unreset()"
            class="ps-1 pointer fas fa-pencil-alt {{ !coloredVal ? '' : 'text-primary' }}"></i>
        </div>
      }
      <ng-template #elseTemplate>
        <a
          [matTooltip]="valueHint"
          [mrouterLink]="route || (routeFunc | functionCaller1: value | async)"
          class="{{ !coloredVal ? '' : 'text-primary' }} col-{{
            !stacked ? 'auto' : showEditing ? '9' : '12'
          }} lbl">
          <div class="hide-scroll-x overflow-x-auto">
            {{ (value | valueFormatter: formatter | async) || '-' }}
          </div>
        </a>
        @if (showEditing) {
          <div class="col-auto">
            <i
              (click)="unreset()"
              class="ps-1 pointer fas fa-pencil-alt {{ !coloredVal ? '' : 'text-primary' }}"></i>
          </div>
        }
      </ng-template>
    </div>
  </div>
</div>
