<!-- <div class="">
  <app-input-basic type="text" label='Name' [noFormat]="true" [required]="true" [form]="form" name='fullName'
    [stacked]="true" #nameTag>
  </app-input-basic>
  <app-validation-message [input]="nameTag">
  </app-validation-message>
</div> -->

<mat-expansion-panel>
  <mat-expansion-panel-header>
    {{'Company Information'|appTranslate|async}}
  </mat-expansion-panel-header>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row row-cols-lg-3 row-cols-md-2">
      @if (!appS.hasBusLine) {
<div class="">
        <app-input-basic type="select" label='Business Line' [form]="form" name='busLine' labelType="vl"
          valueField="value" [options]="appS.busLines" [stacked]="true" #busLine>
        </app-input-basic>
        <app-validation-message [input]="busLine">
        </app-validation-message>
      </div>
}
      <div class="">
        <app-input-basic type="text" label='Name' [noFormat]="true" [required]="true" [form]="form" name='fullName'
          [stacked]="true" #nameTag>
        </app-input-basic>
        <app-validation-message [input]="nameTag">
        </app-validation-message>
      </div>
      <div class="">
        <label class="text-primary">{{"Company Registration Number"|appTranslate|async}}</label><br>
        <input type="text" formControlName="companyRedgNo" class="form-control  control-bg-gray">
        <app-validation-message>
        </app-validation-message>
      </div>
      <div class="">
        <label class="text-primary">{{"Country of Registration"|appTranslate|async}}</label><br>
        <app-autocomplete [form]="form" name="country" (mchange)="setRegionList()" [options]="countryList" valueField="code" labelType="cd"></app-autocomplete>
        <!-- <select formControlName="country" (change)="setRegionList()" class="form-control  control-bg-gray">
          <option [value]="null">{{"Select Country"|appTranslate|async}}</option>
          <option [value]="item.code" *ngFor="let item of countryList">{{item.code}} - {{item.description}}</option>
        </select> -->
        <app-validation-message>
        </app-validation-message>
      </div>
      <div class="">
        <label class="text-primary">{{"Segment"|appTranslate|async}}</label>
       <app-autocomplete [form]="form" name="segment" [options]="segmentList" valueField="code"
                  labelType="ct"/>

        <!-- <label class="text-primary">{{"Segment"|appTranslate|async}}</label><br>
        <select formControlName="segment" class="form-control  control-bg-gray">
          <option [value]="null">{{"Select Sector"|appTranslate|async}}</option>
          <option [value]="item.code" *ngFor="let item of segmentList">{{item.code}} - {{item.title}} </option>
        </select> -->
        <app-validation-message>
        </app-validation-message>
      </div>
      <div class="">
        <label class="text-primary">{{"Email"|appTranslate|async}}</label><br>
        <input type="email" formControlName="email" class="form-control  control-bg-gray">
        <app-validation-message   [control]="validation?.email">
        </app-validation-message>
      </div>
      <div class="">
        <label class="text-primary">{{"Website"|appTranslate|async}}</label><br>
        <input type="text" data-noformat="true" formControlName="website" class="form-control  control-bg-gray"
          placeholder="www.example.com">
        <app-validation-message>
        </app-validation-message>
      </div>
      <div class=" ">
        <label class="text-primary">{{"Sector"|appTranslate|async}}</label>
          <app-autocomplete [form]="form" name="sector" [options]="sectorList" valueField="code"
            labelType="ct" />
        <!-- <label class="text-primary">{{"Sector"|appTranslate|async}}</label><br>
        <select formControlName="sector" class="form-control  control-bg-gray">
          <option [value]="null">{{"Select Sector"|appTranslate|async}}</option>
          <option [value]="item.code" *ngFor="let item of sectorList">{{item.code}} - {{item.title}}</option>
        </select> -->
        <app-validation-message>
        </app-validation-message>
      </div>
      <div class="">
        <app-input-basic type="text" label='Address' [placeholder]="uS.config.FormPresets.addressPlaceholder"
          [form]="form" name='address' [stacked]="true" #addressTag>
        </app-input-basic>
        <app-validation-message />
      </div>
      <div class="">
        <app-autocomplete label="Address Country" [form]="form" name="addressCountry" [stacked]="true"
          [showValidation]="countryTag.invalidSignal()" [showValidationIcon]="false" (mchange)="setRegionList()"
          [options]="countryList" labelType="cd" valueField="code" #countryTag>
        </app-autocomplete>
        <app-validation-message />
      </div>
      <div class="">
        <app-autocomplete [disabled]="!countryTag.hasValue" label="Address Region" [form]="form" name="addressRegion"
          [stacked]="true" (mchange)="setStateList()" [showValidation]="locationRegion.invalidSignal()"
          [showValidationIcon]="false" [options]="regionList" labelType="cd" valueField="code" #locationRegion>
        </app-autocomplete>
        <app-validation-message />
      </div>
      <div class="">
        <app-autocomplete [disabled]="!locationRegion.hasValue" label="Address State" [form]="form" name="addressState"
          [stacked]="true" (mchange)="setTownList()" [showValidation]="stateTag.invalidSignal()" [showValidationIcon]="false"
          [options]="stateList" labelType="cd" valueField="code" #stateTag>
        </app-autocomplete>
        <app-validation-message />
      </div>
      <div class="">
        <app-autocomplete [disabled]="!stateTag.hasValue" label="Address Town" [form]="form" name="addressTown"
          [stacked]="true" [showValidation]="townTag.invalidSignal()" [showValidationIcon]="false" [options]="townList"
          labelType="cd" valueField="code" #townTag>
        </app-autocomplete>
        <app-validation-message />
      </div>
      <div class="">
        <label class="text-primary">{{"Phone Number"|appTranslate|async}}</label><br>
        <app-phone-number [form]="form" name="phoneNumber" [showLabel]="false" [countryCode3]="countryTag.valueSignal()"
          [showValidation]="pn.invalidSignal()" [showValidationIcon]="false" [showValidationMsg]="true" #pn>
        </app-phone-number>
        <app-validation-message  [control]="validation?.phoneNumber">
        </app-validation-message>
      </div>



    </div>


  </form>
</mat-expansion-panel>