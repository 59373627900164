import { Component, input, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EValidationType, IStrictFormGroup } from '@Shared/models/index.model';
import { CreateIndividualClientService } from '../create-individual-client.service';
import { TranslatePipe } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { FunctionCaller } from 'ets-fe-ng-sdk';
import { AsyncPipe } from '@angular/common';
import { FileUploadComponent as FileUploadComponent_1 } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/components/file-upload/file-upload.component';
import { InputFormatDirective } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/directives/input-formatter.directive';
import { ValidationMessageComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { IClientIndividual } from '../individual-client-extras/individual-client.interface';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

@Component({
    selector: 'app-identification',
    templateUrl: './identification.component.html',
    styleUrls: ['./identification.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        InputBasicComponent,
        ValidationMessageComponent,
        InputFormatDirective,
        FileUploadComponent_1,
        AsyncPipe,
        FunctionCaller,
        TranslatePipe,
        AutocompleteComponent,
    ]
})
export class IdentificationComponent implements OnInit {
  readonly fc = input.required<FormGroup<IStrictFormGroup<IClientIndividual['identification']>>>();
  identificationForm = new FormGroup({});
  passportFile: File;
  idcardFile: File;
  eValidationType = EValidationType;
  readonly penComPrefix = 'PEN';
  readonly penComLength = 12;
  readonly ninLength = 11;
  // readonly useNationalID = toSignal(this.fc().controls.idType.valueChanges.pipe(map((val) => val == 'N')));

  constructor(public ccS: CreateIndividualClientService) {}

  ngOnInit(): void {}
  get validation() {
    return this.fc()?.controls;
  }

  onSubmit() {
    console.log(this.identificationForm);
    this.ccS.identificationInfo(this.identificationForm.value);
  }

  changeTab(tab: any) {
    console.log('tab');
    this.ccS.tabChange(tab);
  }

  upload(event: any) {
    let file = event.target.files[0];
    console.log('imagefile', file);
    console.log('imagepath', file.name);
    this.passportFile = file;
  }
  upload2(event: any) {
    let file = event.target.files[0];
    console.log('imagefile', file);
    console.log('imagepath', file.name);
    this.idcardFile = file;
  }
  getImage() {
    let as = document.getElementById('filebtn')?.click();
  }
  getImage2() {
    let as = document.getElementById('filebtn1')?.click();
  }
}
