<loader [loading]="loading">

  <div>
    <div class="row align-items-end  ">
      <div class="col-md-3">
        <app-autocomplete label="Select Country" type="select" [form]="form" name="country" [stacked]="true"
          [options]="aeS.getCountry|functionCaller|async" labelType="cd" valueField="code" #country>
        </app-autocomplete>
      </div>

      <div class="col-md-3">
        <app-autocomplete label="Select Region" type="select" [form]="form" name="region" [stacked]="true"
          [options]="aeS.getRegion|functionCaller1:country?.valueSignal()|async" labelType="cd" valueField="code" #region>
        </app-autocomplete>
      </div>

      <div class="col-md-3">
        <app-autocomplete label="Select State" type="select" [form]="form" name="city" [stacked]="true"
          [options]="aeS.getCitiesByRegion|functionCaller1:region?.valueSignal()|async" labelType="cd" valueField="code" #state>
        </app-autocomplete>
      </div>

      <div class="col-md-3">
        <app-autocomplete label="Select Town" type="select" [form]="form" name="town" [stacked]="true"
          [options]="aeS.getTownByCity|functionCaller1:state?.valueSignal()|async" labelType="cd" valueField="code" #town>
        </app-autocomplete>
      </div>

      <div class="col-md-3">
        <app-autocomplete label="Select Bank" type="select" [form]="form" name="bankName" [stacked]="true"
          [options]="aeS.getBanks|functionCaller|async" labelType="cd" valueField="code" #bank>
        </app-autocomplete>
      </div>

      <div class="col-md-3">
        <app-input-basic label="Sort Code" type="text" [form]="form" name="sortCode" [stacked]="true">
        </app-input-basic>
      </div>

      <!-- <div class="col-md-3">
         <app-input-basic label="Select Branch" type="select" [form]="form" name="branch" [stacked]="true"
           [options]="aeS.getBranchTitleByBankCode|functionCaller1:bank?.value?.code|async" labelType="cd" labelType="cd" #branch>
         </app-input-basic>
       </div> -->

      @if (showShowBtn) {
<div class="col-auto mt-4">
        <app-btn   icon="search" (mclick)="search()"> Search</app-btn>
      </div>
}

    </div>

    @if (result) {
<div class="cage mt-2">
      <table class="table border table-striped">
        <thead>
          <tr>
            <th>{{'Code'|appTranslate|async}}</th>
            <th>{{'Sort Code'|appTranslate|async}}</th>
            <th>{{'Title'|appTranslate|async}}</th>
            <th>{{'HQ'|appTranslate|async}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for (item of result?.page?.content; track item; let i = $index) {
  <tr>
            <td>{{item.code}} </td>
            <td>{{item.sortCode}} </td>
            <td>{{item.title}} </td>
            <td>{{item.hq?'Yes':'No'}}</td>
            <td>
              <app-btn type="outline-nm" (mclick)="select(i)"> Select</app-btn>
            </td>
          </tr>
}
        </tbody>
      </table>
      @if (result?.page?.empty) {
<div class="vh-20 center">
        {{'There were no results found'|appTranslate|async}}
      </div>
}
    </div>
}
  </div>

</loader>