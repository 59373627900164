<ng-template #temp>
  <modal-header [header]="header()" [dialogRef]="dialogRef()" />
  <loader [loading]="loading()">
    <div mat-dialog-content>
      <form-generator
        [form]="form"
        [formSchema]="formSchema"
        [gridStyle]="gridStyle"
        [gridMDStyle]="gridMDStyle"
        [gridXXLStyle]="gridXXLStyle"
        [submitFunc]="submitFunc"
        [submitBtnText]="text"
        [showSubmitBtn]="false"></form-generator>
    </div>
    <div mat-dialog-actions>
      <div class="row row-cols-auto justify-content-end">
        <app-btn
          [text]="text"
          group="submit"
          [form]="form"
          (mclick)="submitted.emit(this.form.value); dialogRef()?.close()"></app-btn>
      </div>
    </div>
  </loader>
</ng-template>
