import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { catchError, map, retry, shareReplay, switchMap, tap } from 'rxjs/operators';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';

import { ClientViewData, IClientViewData } from '@Shared/models/client-desk.interface';
import { ApiService } from '@Services/api.service';
import { UtilityService } from '@Services/utility.service';
import {
  EClientType,
  IClientContactInfo,
  IClientDateInfo,
  IClientDetails,
  IClientSearchObj,
  IClientSearchResult,
  IClientTax,
  IClientUniqueObj,
  IValidation,
} from 'projects/evolutics-client-ui/src/app/Life/client-desk/client-extras/client.interface';
import { CustomValidationError, ICodeDescription, ISearchResponse2 } from '@Shared/models/index.model';
import { Observable, forkJoin, lastValueFrom, of, timer } from 'rxjs';
import { IClientRelationship } from 'projects/evolutics-client-ui/src/app/Services/client.service';
import {
  BankAccount,
  IBankAccount,
} from 'projects/evolutics-client-ui/src/app/Life/Setup/Account/bank/bank-accounts/bank-accounts.interface';
import { IClientBank } from 'projects/evolutics-client-ui/src/app/Life/policy-desk/policy-desk-pages-2/policy-annuities/policy-annunities';
import {
  IBasicClient,
  IClientAdditionalInfo,
} from 'projects/evolutics-client-ui/src/app/Life/client-desk/client-extras/basic-client.interface';
import { IClientHealth } from 'projects/evolutics-client-ui/src/app/Life/client-desk/client-extras/client-health.interface';
import { EVFunctions, IGetQuery } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class Client1Service {
  protected readonly baseURL = environment.apiBaseUrl + '/rest/client/';
  readonly penComPrefix = 'PEN';
  readonly penComLength = 12;
  clientData: IClientViewData;

  noRetry = 0;

  constructor(
    public apiService: ApiService,
    public uS: UtilityService,
  ) {}
  getClientAge(clientNo: string) {
    return this.apiService.getText(this.baseURL + `age/${clientNo}`);
  }
  getLossSectorRatio = (sector: string) => {
    return this.apiService
      .get(environment.apiBaseUrl + '/rest/crm/client/cum/loss-ratio/sector', {
        sector,
      })
      .pipe(shareReplay());
  };
  getClientHealth(clientNo: string) {
    return this.apiService.get<IClientHealth>(this.baseURL + `health/${clientNo}`);
  }

  readonly clientNames: { [clientNo: string]: string } = {};
  /**
   * Get client fullname by client number
   * @param num Client number
   * @returns Client Fullname
   */
  getClientNameByNum = (clientNo: string) =>
    this.getClientNameByNum2(clientNo).pipe(tap((r) => (this.clientNames[clientNo] = r)));

  /**
   * Get all client no with name
   * @returns Array of Client Number and Full Name
   */
  getAllClientNoAndFullName = () => {
    return this.apiService.get<{ fullName: string; clientNo: string }[]>(this.baseURL + 'clientNo/fullName');
  };

  getClientNameByNum2(clientNo: string) {
    return clientNo
      ? this.apiService.getText(this.baseURL + `full_name/${clientNo}`).pipe(map((r) => r.removeNull()))
      : of(null);
  }

  getClientNameByNumJoined = (clientNo: string) =>
    this.getClientNameByNum2(clientNo).pipe(map((r) => EVFunctions.strConcatenator2(clientNo, r)));

  getClientRelationship = (clientNo: string, relClientNo: string) => {
    return this.apiService
      .get(this.baseURL + `view/relationship/${clientNo}/${relClientNo}`)
      .pipe(map((res) => res?.relationship));
  };

  getClientRelationshipFull = (clientNo: string, relClientNo: string) => {
    return this.apiService.get<IClientRelationship[]>(this.baseURL + `related/clients/${clientNo}`).pipe(
      map((res) => {
        // debugger;
        return res?.find((x) => x.relClientNo.toLowerCase() == relClientNo?.toLowerCase());
      }),
    );
  };
  getClientCumRatio = (clientNo: string) => {
    return this.apiService.get(this.baseURL + 'cum/loss/ratio/' + clientNo).pipe(shareReplay());
  };

  getClientPolicyRatio = (policyNo: string) => {
    return this.apiService.get(this.baseURL + '/details/' + policyNo).pipe(shareReplay());
  };

  getIndividualClient = (clientNo: string) => {
    return this.apiService.get<any>(this.baseURL + `individual/${clientNo}`);
  };
  getClientType = (clientNo: string) =>
    this.apiService
      .get<{ type: EClientType }>(this.baseURL + `type/${clientNo}`)
      .pipe(map((res) => res?.type));

  checkIfClientExistsBy = (query: IClientUniqueObj) =>
    this.apiService.get<boolean>(this.baseURL + `unique/exists`, query);

  getClientViewData = (clientNo: string) => {
    return this.getClientBasicData(clientNo);
    // return this.apiService.get(`${this.baseURL}view/${clientNo}`).pipe(map((r) => new ClientViewData(r)));
  };
  getClientBasicData = (clientNo: string) =>
    clientNo
      ? this.apiService.get<IBasicClient>(`${this.baseURL}basic-info/${clientNo}`).pipe(
          map((c) => {
            c.fullName = c.fullName?.removeNull();
            c.firstName = c.firstName?.removeNull();
            c.middleName = c.middleName?.removeNull();
            c.surname = c.surname?.removeNull();
            c._category =
              (c.categoryList?.filter((x) => !!x.category) || []).map((x) => x.category).join('/') || null;

            return c;
          }),
        )
      : of(null);

  getClientTaxData = (clientNo: string) => this.apiService.get<IClientTax>(`${this.baseURL}tax/${clientNo}`);

  getClientDateData = (clientNo: string) =>
    this.apiService.get<IClientDateInfo>(`${environment.apiBaseUrl}/rest/crm/client/view/date/${clientNo}`);

  getClientContactData = (clientNo: string) =>
    clientNo
      ? this.apiService.get<IClientContactInfo>(
          `${environment.apiBaseUrl}/rest/crm/client/view/contact/${clientNo}`,
        )
      : of(null);

  getClientDetailsData = (clientNo: string) =>
    this.apiService.get<IClientDetails>(`${this.baseURL}client-details/${clientNo}`);

  getClientAdditionalInfo = (clientNo: string) => {
    return this.apiService.get<IClientAdditionalInfo>(`${this.baseURL}additional-info/${clientNo}`);
  };
  getCurrenciesList = () => {
    return this.apiService.get<ICodeDescription[]>(environment.apiBaseUrl + '/rest/currency/');
  };
  getClientEnroleeData = <T = any>(enroleeNo: string) => {
    return this.apiService.get<T>(this.baseURL + 'enrolee?enrolee=' + enroleeNo);
  };
  getClientProviderData = <T = any>(providerNo: string) => {
    return this.apiService.get<T>(this.baseURL + 'provider/' + providerNo);
  };
  getClientProviderContact = <T = any>(providerNo: string) => {
    return this.apiService.get<T>(this.baseURL + 'provider/contact/' + providerNo);
  };
  createClientProviderContact = <T = any>(data: any) => {
    return this.apiService.post<T>(this.baseURL + `provider/contact`, data);
  };
  updateClientProviderContact = <T = any>(id: string, data: any) => {
    return this.apiService.put<T>(this.baseURL + `provider/contact/${id}`, data);
  };
  getClientProviderPayment = (providerNo: string) => {
    return this.apiService
      .get(this.baseURL + 'provider/payment/search', { providerNo })
      .pipe(map((res) => res.content));
  };
  createClientProviderPayment = <T = any>(data: any) => {
    return this.apiService.post<T>(this.baseURL + `provider/payment`, data);
  };
  updateClientProviderPayment = <T = any>(id: string, data: any) => {
    return this.apiService.put<T>(this.baseURL + `provider/payment/${id}`, data);
  };
  getProviderFullNameByNo = <T = any>(providerNo: string) => {
    return this.apiService.get<T>(this.baseURL + 'provider/no/fullName/' + providerNo);
  };

  validateClientNo = async (control: AbstractControl) => {
    // debugger
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.getClientViewData(val)
      .toPromise()
      .then((res) => {
        if (!res.clientNo) return { notFound: true };
        if (!res.type) return { notFound: true };
        (control.parent as FormGroup)?.patchValue({ clientData: res });
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };
  validateClientNo2 = async (control: AbstractControl) => {
    // debugger
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.getClientViewData(val)
      .toPromise()
      .then((res) => {
        if (!res.clientNo) return { notFound: true };
        if (!res.type) return { notFound: true };
        (control.parent as FormGroup)?.patchValue({ ...res, principalEnrolee: control.value });
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };
  validateClientNoWithoutPatching = async (control: AbstractControl) => {
    // debugger;
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.apiService
      .get<IValidation>(this.baseURL + 'validate/clientno/' + control.value)
      .toPromise()
      .then((res) => {
        return res?.response ? null : { notFound: true };
      })
      .catch((err) => {
        return { notFound: true };
      });
  };

  /**
   * This checks if a clientNo is valid but does not patch the form with the result.
   * @param control
   * @returns It returns the validation error if it exists
   */
  validateClientNoLite =
    (keyPrefix: string = this.uS.generateUUID()) =>
    async (control: AbstractControl) => {
      if (!control.value) return null;
      return this.uS.asyncValidation({
        uniqueKey: keyPrefix,
        callback: () =>
          lastValueFrom(
            this.apiService.get<IValidation>(this.baseURL + 'validate/clientno/' + control.value),
          ),
      });
    };
  /**
   * This checks if a clientNo is valid and doesn't use a debouncer
   * @param control
   * @returns It returns the validation error if it exists
   */
  validateClientNoLite2 = async (clientNo: string) => {
    return lastValueFrom(this.apiService.get<IValidation>(this.baseURL + 'validate/clientno/' + clientNo));
  };
  validateEmail = (control: AbstractControl, currentValue: string) => {
    // debugger;
    if (!control?.dirty) return of(null);
    const value = control?.value?.trim();
    if (!value) return of(null);
    return timer(700).pipe(
      switchMap(() =>
        forkJoin([
          this.checkIfClientExistsBy({ email: value }),
          this.checkIfClientExistsBy({ alternativeEmail: value }),
        ]).pipe(
          map((r) => (r.includes(true) ? { custom: 'Email already exists' } : null)),
          catchError((e) => of({ custom: 'Email already exists' })),
        ),
      ),
    );
  };
  readonly bvnLength = 11;
  checkUniqueBVN =
    (savedValue?: string) =>
    (control: AbstractControl): Observable<CustomValidationError> => {
      if (!control?.dirty) return of(null);
      const bvn = control?.value?.trim();
      if (!bvn) return of(null);
      const length = control.value.toString().length;
      if (length != this.bvnLength)
        return of({ custom: `BVN must be ${this.bvnLength} (${length}) characters long.` });
      else if (savedValue == bvn) return of(null);
      else
        return this.checkIfClientExistsBy({
          bvn,
        }).pipe(
          map((r) => (r ? { notUnique: true } : null)),
          catchError(() => of({ notUnique: true })),
        );
    };
  readonly ninLength = 11;
  checkUniqueNIN = (savedValue?: string) => async (control: AbstractControl<string>) => {
    const nationalInsuranceNumber = control?.value?.trim();
    if (!nationalInsuranceNumber) return null;
    const length = control.value.toString().length;
    if (length != this.ninLength)
      return { custom: `NIN must be ${this.ninLength} (${length}) characters long.` };
    else if (savedValue == nationalInsuranceNumber) return null;
    else
      return this.checkIfClientExistsBy({ nationalInsuranceNumber })
        .toPromise()
        .then((r) => {
          return r ? { notUnique: true } : null;
        })
        .catch((e) => {
          return { notUnique: true };
        });
  };
  checkUniqueTIN = async (control: AbstractControl) => {
    const tin = control?.value?.trim();
    if (!tin) return null;
    return this.checkIfClientExistsBy({ tin })
      .toPromise()
      .then((r) => {
        return r ? { notUnique: true } : null;
      })
      .catch((e) => {
        return { notUnique: true };
      });
  };

  /**
   * replate + to %2B and validate entered phone numberif phone exist
   * @param control
   * @returns
   */
  checkUniquePhoneNumber = (control: AbstractControl) => {
    if (!control?.dirty) return of(null);
    const value = control?.value?.trim();
    if (!value) return of(null);
    let phoneNumber = value.replace('+', '%2B');
    // let phoneNumber = controlValue
    return timer(700).pipe(
      switchMap(() =>
        this.checkIfClientExistsBy({ phoneNumber }).pipe(
          map((r) => (r ? { custom: `Phone number already exists` } : null)),
          catchError((e) => of({ custom: `Phone number already exists` })),
        ),
      ),
    );
  };
  checkIDUniqueness = (control: AbstractControl, idTypeField: string = 'idType') => {
    return new Promise((resolve) => {
      const idNumber = control?.value?.trim();
      if (!idNumber) resolve(null);
      else {
        const idType = control.parent.get(idTypeField)?.value?.trim();
        lastValueFrom(this.checkIfClientExistsBy({ idNumber, idType }))
          .then((r) => {
            resolve(r ? { notUnique: true } : null);
          })
          .catch((e) => {
            resolve({ notUnique: true });
          });
      }
    });
  };
  checkUniquePenCom = (savedValue?: string) => (control: AbstractControl<string>) => {
    const pensionCommissionNumber = control?.value?.trim()?.toUpperCase().replace('PEN', '');
    if (!pensionCommissionNumber) return new Promise((res) => res(null));
    else
      return new Promise((res) => {
        const length = pensionCommissionNumber?.length;
        if (length != 12) res({ custom: `PEN COMM must be 12 (${length}) characters long.` });
        else if (savedValue?.toUpperCase().replace('PEN', '') == pensionCommissionNumber) res(null);
        else
          this.checkIfClientExistsBy({
            pensionCommissionNumber: 'PEN' + pensionCommissionNumber,
          })
            .toPromise()
            .then((r) => (r ? res({ notUnique: true }) : res(null)))
            .catch(() => res({ notUnique: true }));
      });
  };
  checkUniqueWebsite = async (control: AbstractControl) => {
    const website = control?.value?.trim();
    if (!website) return null;
    return this.checkIfClientExistsBy({ website })
      .toPromise()
      .then((r) => {
        return r ? { notUnique: true } : null;
      })
      .catch((e) => {
        return { notUnique: true };
      });
  };
  checkUniqueCompanyRedgNo = async (control: AbstractControl) => {
    const companyRedgNo = control?.value?.trim();
    if (!companyRedgNo) return null;
    return this.checkIfClientExistsBy({ coyRegdNo: companyRedgNo })
      .toPromise()
      .then((r) => {
        return r ? { notUnique: true } : null;
      })
      .catch((e) => {
        return { notUnique: true };
      });
  };
  checkAlternateEmail = (control: AbstractControl, mainEmailField = 'email') => {
    const value: string = control?.value;
    if (!value) return null;
    else if (value == control?.parent?.get(mainEmailField)?.value)
      return { custom: 'Email and Alternate Email should not be the same' };
    else return null;
  };
  checkAlternatePhone = (control: AbstractControl) => {
    const value: string = control?.value;
    if (!value) return null;
    else if (value == control?.parent?.get('phoneNumber')?.value) return { equalToOther: true };
    else return null;
  };

  getEnrollee(enrolleeNo: string) {
    return this.apiService.get(`${this.baseURL}view/enrolee/${enrolleeNo}`);
  }

  asyncValidateEnrolleeNo = async (control: AbstractControl) => {
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return lastValueFrom(this.getEnrollee(val))
      .then((res) => {
        if (!res.enroleeNo) return { notFound: true };
        (control.parent as FormGroup)?.patchValue({ enroleeData: res });
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };

  getClientBankAccounts = (clientNo: string) => {
    return this.apiService.get<IClientBank[]>(`${this.baseURL}existing/accounts/${clientNo}`);
  };

  getClientBank(clientNo) {
    return this.apiService.get(`${this.baseURL}bankNo/${clientNo}`);
  }

  getClientBankDetails(bankNo: string) {
    return this.apiService.get<IBankAccount[]>(`${this.baseURL}bank/${bankNo}`);
  }

  getClientBasicDataLiteByNo = (clientNo: string) =>
    this.getClientsBasicInfoByNos([{ clientNo }]).pipe(map((r) => r?.[0]));

  getClientsBasicInfoByNos(query: { clientNo: string; id?: string }[]) {
    return this.apiService.post<
      {
        address: string;
        city?: string;
        clientNo: string;
        dateOfBirth: string;
        email: string;
        endpointTitle: string;
        firstName: string;
        fullName: string;
        gender: string;
        maritalStatus: string;
        middleName: string;
        phone?: string;
        rowId?: string;
        surname: string;
        title: string;
      }[]
    >(this.baseURL + `view/`, query);
  }

  searchClientEmployment(payload) {
    return this.apiService.get(this.baseURL + 'employment', payload);
  }

  /**
   * validate client by phone and eamil
   * @param email entered email
   * @param phone entered phone
   * @returns promise of client no
   */
  validateClientExistsByPhoneNoAndEmail(email, phone) {
    return this.apiService.getText(this.baseURL + `exists/${phone}/${email}`);
  }

  search = (data: IClientSearchObj) => {
    console.log(data);
    return this.apiService
      .get<ISearchResponse2<IClientSearchResult>>(this.baseURL + 'search', data)
      .pipe(catchError((e) => of(null)));
  };

  searchClientsByName = (name: string) =>
    this.search({ name }).pipe(
      map((res) =>
        res?.content?.sort2('clientNo', true)?.map((c) => ({ code: c.clientNo, title: c.fullName })),
      ),
    );

  searchClientsByClientNo = (clientNo: string) =>
    this.search({ clientNo }).pipe(
      map((res) =>
        res?.content?.sort2('clientNo', true)?.map((c) => ({ code: c.clientNo, title: c.fullName })),
      ),
    );

  searchByNameEmailPhone = (field: string) => {
    const query: IGetQuery = { pageNumber: 1, pageSize: 10 };
    return this.search({ name: field, ...query }).pipe(
      switchMap((value) => (value.content.length > 0 ? of(value) : this.search({ email: field, ...query }))),
      switchMap((value) =>
        value.content.length > 0 ? of(value) : this.search({ phoneNo: field, ...query }),
      ),
      map((value) => value.content.map((x) => ({ code: x.clientNo, title: x.fullName }))),
      catchError((e) => of(null)),
    );
  };
}

export type IClientMini = IClientDetails;
