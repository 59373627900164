import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@Shared/shared.module';
import { ComponentsModule } from '@Shared/components/core/button/components.module';
import { RouterModule } from '@angular/router';
import { CompanyInfoFormComponent } from './company-info-form/company-info-form.component';
import { ContactInfoFormComponent } from './contact-info-form/contact-info-form.component';
import { DirectorsFormComponent } from './directors-form/directors-form.component';
import { CreateAccountFormModule } from '../../life-components/create-account-modal/create-account-form/create-account-form.module';
import { MatMenuModule } from '@angular/material/menu';
import { CreateNewClientModalComponent } from './modal/new-client-modal/create-new-client-modal.component';

@NgModule({
    exports: [
        DirectorsFormComponent,
        CompanyInfoFormComponent,
        ContactInfoFormComponent,
        CreateNewClientModalComponent,
        CreateAccountFormModule,
        MatMenuModule,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ComponentsModule,
        RouterModule,
        CreateAccountFormModule,
        MatMenuModule,
        DirectorsFormComponent,
        CompanyInfoFormComponent,
        ContactInfoFormComponent,
        CreateNewClientModalComponent,
    ],
})
export class CreateCorporateClientModule {}
