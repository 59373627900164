import { MediaMatcher } from '@angular/cdk/layout';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, ViewChild, ChangeDetectorRef, input, linkedSignal, computed } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { MenuItem } from '@Shared/models/IMenuItem';
import { AppService } from '@Services/app.service';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { UtilityService } from '@Services/utility.service';
import { Router, RouterLink } from '@angular/router';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import PS from 'perfect-scrollbar';

import { IsNavMenuEnabledPipe } from '@Shared/pipes/utility.pipe';
import { TranslatePipe } from '../../../Shared/pipes/translate.pipe';
import { ImageLoaderDirective } from '../../../Shared/directives/index.directive';
import { MatMenuModule } from '@angular/material/menu';
import { ETSResponsivenessDirectiveModule } from 'ets-fe-ng-sdk';
import { NavbarSearchComponent } from '../../../Shared/components/search/search-components/navbar-search/navbar-search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'vertical-nav',
  templateUrl: './vertical-nav.component.html',
  styleUrls: ['./vertical-nav.component.scss'],
  imports: [
    NgClass,
    NgIf,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    NavbarSearchComponent,
    MatSidenavModule,
    ETSResponsivenessDirectiveModule,
    MatTreeModule,
    RouterLink,
    MatMenuModule,
    ImageLoaderDirective,
    AsyncPipe,
    TranslatePipe,
  ],
  animations: [
    trigger('slideVertical', [
      state(
        '*',
        style({
          height: 0,
        }),
      ),
      state(
        'show',
        style({
          height: '*',
        }),
      ),
      transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
  ],
})
export class VerticalNavComponent {
  @Input() fullScreen: boolean;
  @Input() isHome: boolean;
  @Input() showMenu: boolean;
  @Input() showSearch: boolean;
  @Input() title: string;
  readonly _profileMenuButtons = input<Partial<MenuItem>[]>(null, { alias: 'profileMenuButtons' });
  protected readonly profileMenuButtons = computed(() => this._profileMenuButtons() || this.navigationService.profileMenuButtons());
  @Input() set menuItems(v: MenuItem[]) {
    if (!v?.length) return;
    v.forEach((x) => (x.level = 0));
    this.dataSource = new MatTreeNestedDataSource<MenuItem>();
    this.dataSource.data = v;
  }

  id = 'matSideNav' + this.uS.genRandomID;
  helpLink = environment.helpLink;
  user = environment.userProfile;
  treeControl = new NestedTreeControl<MenuItem>((node) => node.subs);

  dataSource: MatTreeNestedDataSource<MenuItem>= new MatTreeNestedDataSource<MenuItem>();

  @ViewChild('snav') drawer: MatDrawer;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appS: AppService,
    protected navigationService: NavigationService,
    public uS: UtilityService,
    public router: Router,
    public authS: AuthenticationService,
    public imeS: IsNavMenuEnabledPipe,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit(): void {
    // this.drawer.open();
    const doc = document.querySelector<HTMLDivElement>(`#${this.id} .mat-drawer-inner-container`);
    if (doc) {
      doc.style.position = 'relative';
      const ps = new PS(doc, {
        wheelSpeed: 0.5,
        swipeEasing: false,
        wheelPropagation: true,
        minScrollbarLength: 40,
      });
      // debugger;
    }
  }

  hasChild = (_: number, node: MenuItem) => node.hasSub && this.shouldShow(_, node);
  shouldShow = (_: number, node: MenuItem) => (this.isHome ? this.imeS.transform(node)?.enabled : true);
  signOut() {
    this.uS.startLoader();
    this.authS.logout().then(() => this.router.navigateByUrl('/'));
  }
}
interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
}
