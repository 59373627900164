import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from '@Services/app.service';
import {
  faArrowAltCircleLeft,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { environment } from '@environments/environment';
import { UtilityService } from '@Services/utility.service';
import { WatermarkComponent } from '../../Reusables/reusable-comps/watermark/watermark.component';
import { FormNavigationComponent } from '../navigation/form-navigation/form-navigation.component';

@Component({
    selector: 'app-form-layout',
    templateUrl: './form-layout.component.html',
    styleUrls: ['./form-layout.component.scss'],
    imports: [FormNavigationComponent, RouterOutlet, WatermarkComponent]
})
export class FormLayoutComponent implements OnInit {
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faHome = faHome;
  @Input() title: string = '';
  bodyClass: string;
  constructor(
    public appS: AppService,
    private location: Location,
    public router: Router,
    public uS: UtilityService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.bodyClass = this.route.snapshot.data.bodyClass;
  }

  goBack() {
    this.location.back();
  }
  goHome() {
    this.router.navigateByUrl(this.appS.getCurrentSystemMetadata.appRoute.pub);
  }
}
