import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  computed,
  input,
  viewChild,
} from '@angular/core';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { ETSResponsivenessDirectiveModule } from 'ets-fe-ng-sdk';
import { NgIf } from '@angular/common';
import { TopNavComponent } from './top-nav/top-nav.component';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styles: [
        `
      :host ::ng-deep {
        .navbar-dark .navbar-nav .nav-link {
          font-weight: 600;
        }
      }
    `,
    ],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TopNavComponent, NgIf, BottomNavComponent, ETSResponsivenessDirectiveModule]
})
export class NavigationComponent implements OnInit {
  readonly bottomNavRef = viewChild<BottomNavComponent>('bottomNav');
  readonly showMenu = input<boolean>();
  readonly searchRef = computed(() => {
    return this.bottomNavRef()?.searchRef();
  });
  @Output() toggleSideMenu = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {}
}
