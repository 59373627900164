import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@Services/api.service';
import { environment } from '@environments/environment';
import { IBranch, IDiscipline } from '../../client-provider-extras/client-provider.interface';
import { ICodeDescription, IGetQuery } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class FindProviderService {
  baseURL = environment.apiBaseUrl + '/rest';
  constructor(private apiService: ApiService) {}
  search = (query?: ISearchProvider) => {
    return this.searchFull(query).pipe(map((r) => r?.clientProvider));
  };

  searchByLocation = (location: string) => {
    return this.apiService.get<any[]>(this.baseURL + `/client/provider-locations`, { location });
  };

  searchFull = (query?: ISearchProvider) => {
    query = query || {};
    if (query.pageSize == null) {
      query.pageSize = 9999999;
      query.pageNumber = 1;
    }
    return this.apiService.get<IProviderSR>(this.baseURL + `/client/provider/search`, query);
  };

  searchProvidersByName = (providerName: string) =>
    this.searchFull({ providerName }).pipe(
      map((res) => res?.clientProvider?.sort2('no', true)?.map((p) => ({ code: p.no, title: p.fullName }))),
    );
  /**
   * Calls endpoint to get provider license data
   * @param providerNo client provider number
   * @returns observable that contains an array of provider license data
   */
  getAllProviderLicense(providerNo: string) {
    return this.apiService
      .get(this.baseURL + `/client/provider/license/search?providerNo=${providerNo}`)
      .pipe(
        map((r) => {
          r?.content.forEach((r) => {
            if (!r.active) r.active = 0;
            else r.active = 1;
          });
          return r?.content;
        }),
      );
  }
  /**
   * Calls endpoint to get reins provider statement data
   * @param providerNo client provider number
   * @returns observable that contains an array of provider statement data
   */
  getAllReinsProviderStatements(providerNo: string) {
    return this.apiService.get(this.baseURL + `/reins/statement/search?providerNo=${providerNo}`);
  }
  /**
   * Calls endpoint to get reins statement data
   * @param providerNo client provider number
   * @returns observable that contains an array of statement data
   */
  getReinsStatementData(query) {
    return this.apiService.get(this.baseURL + `/reins/statement/details/search`, query);
  }
  /**
   * Calls endpoint to get provider statement data
   * @param providerNo client provider number
   * @returns observable that contains an array of provider statement data
   */
  getAllProviderStatements(providerNo: string) {
    return this.apiService.get(this.baseURL + `/provider/statement/search?providerNo=${providerNo}`);
  }
  /**
   * Calls endpoint to get statement data
   * @param providerNo client provider number
   * @returns observable that contains an array of statement data
   */
  getStatementData(providerNo: string) {
    return this.apiService.get(this.baseURL + `/provider/statement/detail/search?statementNo=${providerNo}`);
  }
  /**
   *
   * @param query provider license object
   * @returns
   */
  createProviderLicense(query: any) {
    return this.apiService.postWithEndorsment(this.baseURL + `/client/provider/license`, query);
  }

  createReinsPayout(id: any) {
    return this.apiService.post(this.baseURL + `/reins/payout/${id}`);
  }
  /**
   *
   * @param id id of selected provider license
   * @param query object data of provider license
   * @returns
   */
  updateProviderLicense(id, query: any) {
    return this.apiService.putWithEndorsment(this.baseURL + `/client/provider/license/${id}`, query);
  }
  /**
   * Calls endpoint to get provider discipline data
   * @param providerNo client provider number
   * @returns observable that contains an array of provider license data
   */
  getProviderDiscipline(providerNo: string) {
    return this.apiService.get(this.baseURL + `/client/provider/discipline/search`, { providerNo });
  }
  getAccountsGen(providerNo: string) {
    return this.apiService.getWithLocalCache(this.baseURL + `/gen/acct/reinsurance`, { providerNo });
  }
  getCompanyCodeAndDesc = () => {
    return this.apiService.get<ICodeDescription[]>(this.baseURL + `company/code/desc`);
  };
  /**
   * Calls endpoint to get provider contacts data
   * @param providerNo client provider number
   * @returns observable that contains an array of provider contacts data
   */
  getProviderContacts(providerNo: string) {
    return this.apiService.get(this.baseURL + `/client/provider/contact/${providerNo}`);
  }
  /**
   * Calls endpoint to get provider branches data
   * @param providerNo client provider number
   * @returns observable that contains an array of provider branches data
   */
  getProviderBranches(providerNo: string) {
    return this.apiService.get(this.baseURL + `/client/provider/branches/search?providerNo=${providerNo}`);
  }
  /**
   * Calls endpoint to get provider payments data
   * @param providerNo client provider number
   * @returns observable that contains an array of provider payments data
   */
  getProviderPayments(providerNo: string) {
    return this.apiService.get(this.baseURL + `/client/provider/payment/search?providerNo=${providerNo}`);
  }
  /**
   *
   * @param query provider branch object
   * @returns
   */
  createProviderBranch(data: any) {
    return this.apiService.postWithEndorsment(this.baseURL + `/client/provider/branches`, data);
  }
  createProviderContact(query: IBranch) {
    return this.apiService.postWithEndorsment(this.baseURL + `/client/provider/contact`, query);
  }
  /**
   *
   * @param query provider payment object
   * @returns
   */
  createProviderPayment(query) {
    return this.apiService.postWithEndorsment(this.baseURL + `/client/provider/payment`, query);
  }
  /**
   *
   * @param query provider discipline object
   * @returns
   */
  createProviderDiscipline(query: IDiscipline) {
    return this.apiService.postWithEndorsment(this.baseURL + `/client/provider/discipline`, query);
  }
  /**
   *
   * @param id id of selected provider discipline
   * @param query object data of provider discipline
   * @returns
   */
  updateProviderDiscipline(id, query: IDiscipline) {
    return this.apiService.putWithEndorsment(this.baseURL + `/client/provider/discipline/${id}`, query);
  }
  /**
   *
   * @param id id of selected provider branch
   * @param query object data of provider branch
   * @returns
   */
  updateProviderBranches(id: number, data: any) {
    return this.apiService.putWithEndorsment(this.baseURL + `/client/provider/branches/${id}`, data);
  }
  updateProviderContact(id, query: IBranch) {
    return this.apiService.put(this.baseURL + `/client/provider/contact/${id}`, query);
  }
  /**
   *
   * @param id id of selected provider payment
   * @param query object data of provider payment
   * @returns
   */
  updateProviderPayment(id, query: IBranch) {
    return this.apiService.putWithEndorsment(this.baseURL + `/client/provider/payment/${id}`, query);
  }
  /**
   *
   * @param id id of selected provider discipline
   * @returns
   */
  deleteProviderDiscipline(id) {
    return this.apiService.deleteWithEndorsment(this.baseURL + `/client/provider/discipline/${id}`);
  }
  /**
   *
   * @param id id of selected provider branch
   * @returns
   */
  deleteProviderBranches(id) {
    return this.apiService.deleteWithEndorsment(this.baseURL + `/client/provider/branches/${id}`);
  }
}
export type ISearchProvider = IGetQuery<{
  clientNo: string;
  createdBy: string;
  createdDateFrom: string;
  createdDateTo: string;
  discipline: string;
  location: string;
  network: string;
  no: string;
  providerCategory: string;
  providerName: string;
  providerSubCategory: string;
}>;
export interface IProviderSR {
  clientProviderDisciplines?: any;
  clientProvider: ClientProvider[];
}

export interface ClientProvider {
  id: number;
  clientNo: string;
  no: string;
  busLine?: string;
  type?: string;
  providerCategory: string;
  providerSubCategory: string;
  network: string;
  disciplines?: any;
  branches?: any;
  status?: string;
  tariffCode?: string;
  facilityDescription?: string;
  fullName?: string;
  createdBy?: string;
}
