import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TranslatePipe } from "../../pipes/translate.pipe";
import { AsyncPipe, CommonModule } from '@angular/common';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    imports: [CommonModule, TranslatePipe]
})
export class CardComponent implements OnInit {
  @Input() header:string
  @Input() actionBtnTemplate:TemplateRef<any>
  constructor() {}

  ngOnInit(): void {}
}
