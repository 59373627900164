import { Component, computed, inject, input, output } from '@angular/core';
import { SharedModule } from '../../../../../Shared/shared.module';
import { DocumentService } from 'projects/evolutics-client-ui/src/app/Services/document.service';
import { ICMSResource, ECMSType } from '../../cms.model';
import { RenderCMSUrlComponent } from './render-cms-url/render-cms-url.component';
import { RenderCMSImageComponent } from './render-cms-image/render-cms-image.component';
import { RenderCMSFileComponent } from './render-cms-file/render-cms-file.component';
import { RenderCMSTableComponent } from './render-cms-table/render-cms-table.component';
import { RenderCMSAccordionComponent } from './render-cms-accordion/render-cms-accordion.component';
import { ITranslatorConfig } from 'ets-fe-ng-sdk';
import { IETSTranslationQueueItem } from '@evolutics/translation';

@Component({
  selector: 'render-cms-content',
  standalone: true,
  imports: [
    SharedModule,
    RenderCMSUrlComponent,
    RenderCMSImageComponent,
    RenderCMSFileComponent,
    RenderCMSTableComponent,
    RenderCMSAccordionComponent,
  ],
  templateUrl: './render-cms-content.component.html',
  styleUrl: './render-cms-content.component.scss',
})
export class RenderCMSContentComponent {
  readonly content = input<ICMSResource>();
  readonly isRoot = input<boolean>();

  readonly translationConfig = computed(() => ({ id: this.content()?.id }) as IETSTranslationQueueItem);

  readonly eCMSType = ECMSType;

  readonly documentService = inject(DocumentService);
}
