import { CommonModule, DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  input,
  Input,
  NgModule,
  OnChanges,
  Output,
  resource,
  signal,
} from '@angular/core';
import { UtilityService } from '@Services/utility.service';
import { TranslatorDirective } from './translator.directive';
import { ImageType } from '../models/index.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NegativeNumberOnlyDirective } from './negative-number-only.directive';
import PerfectScrollbar from 'perfect-scrollbar';
import { Config } from '@configs/index.config';
import { PermissionManagerDirectivesModule } from './permission-manager.directive';
import { DragDropFileUploadDirective } from './drag-drop-file-upload.directive';
import { ETSResponsivenessDirectiveModule, InputFormatDirectivesModule } from 'ets-fe-ng-sdk';
import { NgChartDirective } from './ng-chart/ng-chart.directive';
import { FormInvalidClassDirective } from './form-invalid-class.directive';

@Directive({
  selector: '[imageUpload]',
  standalone: true,
})
export class ImageUpload {
  @Output() extractedImg = new EventEmitter<File>();
  @HostListener('click') onClick() {
    if (!this.disableUpload) this.openFile();
  }
  @Input() disableUpload: boolean;
  fileEl: HTMLInputElement;

  constructor(
    public el: ElementRef<HTMLImageElement>,
    public uS: UtilityService,
  ) {
    this.fileEl = document.createElement('input');
    this.fileEl.type = 'file';
  }
  ngAfterViewInit(): void {
    this.el.nativeElement.style.cursor = 'pointer';

    this.fileEl.onchange = () => {
      this.preview(this.fileEl.files);
    };
  }
  openFile() {
    this.fileEl.click();
  }

  preview(files: FileList) {
    if (files.length === 0) return;
    const f = files[0];
    const mimeType = f.type;
    if (files.length == 1 && f.size > 10000000) {
      this.uS.notify('Image has to be less then 10mb in size', 0);
      return;
    }
    if (mimeType.match(/image\/*/) == null) {
      this.uS.notify('Only images are supported.', 0);
      return;
    }
    this.extractedImg.emit(f);
  }
}
@Directive({
  selector: '[imageLoader]',
  standalone: true,
})
export class ImageLoaderDirective {
  readonly src = input.required<string>({ alias: 'imageLoader' });
  readonly imageType = input<ImageType>(undefined);

  readonly srcLoader = resource({
    request: () => ({ src: this.src(), type: this.imageType() }),
    loader: ({ request }) =>
      new Promise<string>((res) => {
        const imgEl = document.createElement('img');
        imgEl.src = request.src || this.getImageByType(request.type)?.src;
        imgEl.onload = (e) => {
          res(imgEl.src);
        };
      }),
  });
  //   readonly src = signal<string>(null);

  constructor(public el: ElementRef<HTMLImageElement>) {
    effect(() => {
      const type = this.imageType();
      this.el.nativeElement.src = this.srcLoader.value() || this.getImageByType(type)?.min;
    });
  }
 

  getImageByType(type: ImageType) {
    return Config.Images[type] ? Config.Images[type] : Config.Images.other;
  }
}

@Directive({
  selector: 'button',
  standalone: false,
})
export class OnClickDirective {
  @HostListener('click', ['$event']) onClick($event: MouseEvent) {
    const location = $event.view.location;
    const pathName = decodeURI(location.pathname);
    const innterHtml: string = this.el.nativeElement.innerHTML;
    console.log(location, pathName);
    // send pathName and innterHtml to server.
    //    :
  }

  constructor(private el: ElementRef) {}
}
@Directive({
  selector: '[mrouterLink]',
  standalone: true,
})
export class MrouterLinkirective {
  mrouterLink: string;
  @Input('mrouterLink') set _mrouterLink(v: string) {
    if (v) {
      this.mrouterLink = v;
      // debugger;
      this.setRouter();
    }
  }
  @Input() mrouterLinkActivatedRoute: ActivatedRoute;
  @Input() mqueryParams: any;
  @Input() isPhone: boolean;
  @Input() isEmail: boolean;

  constructor(
    public el: ElementRef<HTMLAnchorElement>,
    public route: ActivatedRoute,
    public router: Router,
    public uS: UtilityService,
  ) {}

  ngAfterViewInit(): void {
    this.setRouter();
  }

  setRouter() {
    // debugger;
    if (this.mrouterLink && this.el.nativeElement) {
      this.el.nativeElement.classList.remove('notLink');
      this.el.nativeElement.classList.add('link');
      // debugger;
      if (this.isEmail) {
        this.el.nativeElement.href = 'mailto:' + this.mrouterLink;
      } else if (this.isPhone) {
        this.el.nativeElement.href = 'tel:' + this.mrouterLink;
      } else {
        // debugger;
        const queryParams: { [x: string]: string } = this.mqueryParams || {},
          routeLevels = this.mrouterLink.split('../').length - 1,
          currentLocationParts = location.pathname.split('/');
        let route = '',
          urlParts = this.mrouterLink.split('?');

        route = urlParts[0];
        if (urlParts.length > 1) {
          const kvps = urlParts[1].split('&');
          for (const kvp of kvps) {
            const kvpSplit = kvp.split('=');
            queryParams[kvpSplit[0]] = kvpSplit[1];
          }
        }

        if (routeLevels) currentLocationParts.splice(routeLevels * -1);
        this.el.nativeElement.href =
          currentLocationParts.join('/') +
          '/' +
          route
            .split('../')
            .filter((x) => x)
            .join('/') +
          '?' +
          this.stringifyQueryParams(queryParams);
        this.el.nativeElement.onclick = (e: MouseEvent) => {
          e.preventDefault();
          // debugger;
          this.router.navigate([route], {
            relativeTo: this.uS.environment.activatedRoute || this.route,
            queryParams,
          });
        };
      }
    } else {
      this.el.nativeElement.classList.add('notLink');
    }
  }
  stringifyQueryParams(q: { [x: string]: string }): string {
    return Object.keys(q)
      .map((key) => `${key}=${q[key]}`)
      .join('&');
  }
}

@Directive({
  selector: '[PSBox]',
  standalone: true,
})
export class PSDirective implements OnChanges, AfterViewInit {
  @Input('PSBox') selector: string | HTMLElement;
  @Input('PSBoxNoHorizontal') noHorizontal: boolean;
  @Input('PSBoxNoVertical') noVertical: boolean;
  constructor(
    public el: ElementRef,
    public uS: UtilityService,
  ) {}
  ngOnChanges(): void {}
  ngAfterViewInit() {
    this.init();
  }
  init() {
    if (!this.uS.isMobile) {
      setTimeout(() => {
        const psNotif = new PerfectScrollbar(this.selector || this.el.nativeElement, {
          wheelSpeed: 0.5,
          swipeEasing: false,
          suppressScrollX: this.noHorizontal || false,
          suppressScrollY: this.noVertical || false,
          wheelPropagation: true,
          minScrollbarLength: 40,
        });
        psNotif.update();
      }, 0);
    }
  }
}

@Directive({
  selector: '[equalChildren]',
  standalone: true,
})
export class EqualChildrenDirective implements OnChanges, AfterViewInit {
  constructor(
    public el: ElementRef,
    public uS: UtilityService,
  ) {}
  ngOnChanges(): void {}
  ngAfterViewInit() {
    const children = this.el.nativeElement.children;
    let maxWidth: number = 0;
    debugger;
    for (const item of children) {
      maxWidth = Math.max(maxWidth, item.offsetWidth);
    }
  }
}
const comps = [
  // CommafyNumberDirective,
  EqualChildrenDirective,
  ImageLoaderDirective,
  ImageUpload,
  // NoFormatDirective,
  MrouterLinkirective,
  NegativeNumberOnlyDirective,
  // OnClickDirective,
  // InputFormatDirective,
  PSDirective,
  TranslatorDirective,
  DragDropFileUploadDirective,
  NgChartDirective,
  FormInvalidClassDirective,
];
@NgModule({
  imports: [CommonModule, InputFormatDirectivesModule, ...comps],
  exports: [
    ...comps,
    PermissionManagerDirectivesModule,
    InputFormatDirectivesModule,
    ETSResponsivenessDirectiveModule,
  ],
  providers: [DecimalPipe],
})
export class DirectivesModule {}
