import { AsyncPipe, NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { environment } from '../../../environments/environment';
import { WatermarkComponent } from '../../Reusables/reusable-comps/watermark/watermark.component';
import { AppService } from '../../Services/app.service';

import { FilterMenuArrayPipe } from '@Shared/pipes/utility.pipe';
import { NavigationComponent } from '../navigation/navigation.component';
import { VerticalNavClientComponent } from '../navigation/vertical-nav-client/vertical-nav-client.component';

@Component({
    selector: 'app-dashboard-client-admin',
    templateUrl: './dashboard-client-admin.component.html',
    styleUrls: ['./dashboard-client-admin.component.scss'],
    imports: [
        NgClass,
        NavigationComponent,
        VerticalNavClientComponent,
        WatermarkComponent,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        AsyncPipe,
        FilterMenuArrayPipe,
        RouterOutlet,
    ]
})
export class DashboardClientAdminComponent implements OnInit {
  title = environment.appName;
  constructor(public appS: AppService) {}

  ngOnInit(): void {}
}
