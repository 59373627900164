import { ModalComponent } from '../modal/modal.component';
import {
  Component,
  EventEmitter,
  Input, Output
} from '@angular/core';
import {
  FormGroup
} from '@angular/forms';
import { UtilityService } from '@Services/utility.service'; 
import { BtnComponent } from 'ets-fe-ng-sdk';
import { FormGeneratorComponent, IFormOptions, IFormSchema } from 'ets-fe-ng-sdk';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';

@Component({
    selector: 'modal-form',
    templateUrl: './modal-form.component.html',
    styleUrls: ['./modal-form.component.scss'],
    imports: [ModalHeaderComponent, LoaderComponent, FormGeneratorComponent, BtnComponent]
})
export class ModalFormComponent extends ModalComponent {
  @Input() keyField: 'field' | 'label' = 'field';
  @Output() submitted = new EventEmitter<any>();
  @Input() submitFunc: (value: any) => Promise<any>;

  @Input() text: string = 'Save';
  @Input() contextData: any;
  @Input() gridStyle: number;
  @Input() gridMDStyle: number;
  @Input() gridXXLStyle: number;
  @Input() form: FormGroup;
  standaloneForm: any;
  @Input() formSchema: IFormSchema[]; 
  optionsMap: IFormOptions = {};
  cellCountryCode3s: IFormOptions = {}; 
 
  ngOnInit(): void {}
 
  open(contextData) {
    if (contextData != null) this.contextData = contextData;
    super.open();
  }
}
