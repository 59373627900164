import { Component, inject, Inject, OnInit, signal } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogRef as MatDialogRef,
  MAT_DIALOG_DATA as MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogConfig,
} from '@angular/material/dialog';
import { IDocMetadata } from '@Shared/models/index.model';
import { environment } from '@environments/environment';
import { DocumentListService } from '../../reusable-pages/document-list/document-list.service';
import { FunctionCaller, FunctionCaller1 } from 'ets-fe-ng-sdk';
import { TranslatePipe } from '../../../Shared/pipes/translate.pipe';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { ModalHeaderComponent } from '@Shared/components/modal-header/modal-header.component';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { IAddDocumentMD } from './add-document-modal.service';
import { UtilityService } from '@Services/utility.service';
import { lastValueFrom } from 'rxjs';
import { UserService } from '@Services/user.service';
import {
  EDeliveryStatus,
  INotificationReceiver,
  ISendNotification,
} from '@Reusables/reusable-pages/messages/messages.model';
import { MessageService } from '@Reusables/reusable-pages/messages/message.service';

@Component({
    templateUrl: './add-document-modal.component.html',
    styleUrls: ['./add-document-modal.component.scss'],
    selector: 'add-document-modal',
    providers: [MatDialogConfig],
    imports: [
        BtnComponent,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        InputBasicComponent,
        NgFor,
        AsyncPipe,
        FunctionCaller,
        FunctionCaller1,
        TranslatePipe,
        ModalHeaderComponent,
        AutocompleteComponent,
        MatDialogModule,
        LoaderComponent,
    ]
})
export class AddDocumentModalComponent implements OnInit {
  protected messageS = inject(MessageService);
  form = new FormGroup({
    boxNo: new FormControl(environment?.userProfile?.users.docBox),
    branch: new FormControl(null),
    category: new FormControl(null, Validators.required),
    refCat: new FormControl(null),
    refNo: new FormControl(null),
    sensitivity: new FormControl(null),
    subCategory: new FormControl(null),
    title: new FormControl(null, Validators.required),
    user: new FormControl(null),
  });
  sensitivities = [];
  branches = [];
  subCategories = [];
  fileName: any;
  file: File;
  hideInputWithValue = false;
  loading: boolean;
  readonly allowUserTagging = signal<boolean>(false);

  constructor(
    public docS: DocumentListService,
    public uS: UtilityService,
    public dialogRef: MatDialogRef<AddDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IAddDocumentMD,
    public userS: UserService,
  ) {}

  ngOnInit(): void {
    this.hideInputWithValue = this.data?.options?.hideInputWithValue;
    this.form.patchValue(this.data?.document);
    if (this.data?.document.category === 'CL') {
      this.form.controls.category.disable();
      this.form.controls.subCategory.disable();
    }
    this.file = this.data?.file;
    this.setDocumentSens();
    this.setDocumentBranch();

    // this.form.controls.sensitivity.disable()
    this.form.controls.subCategory.valueChanges.subscribe((val) => {
      this.docS
        .getDocumentSensitivity(environment?.user?.code, val)
        .subscribe((r) => this.form.patchValue({ sensitivity: r?.sensitivity }));
    });
  }
  get category() {
    return this.form?.value?.category;
  }
  get sensitivity() {
    return this.form?.controls?.sensitivity.value;
  }
  setDocumentSubCategory(code) {
    this.docS.getSubCategoryList(code).subscribe((res) => {
      this.subCategories = res;
      console.log('sub category', res);
    });
  }
  tagUser() {
    this.form.controls.user.reset();
    this.allowUserTagging.update((v) => !v);
  }
  removeFile() {
    this.file = null;
  }
  setDocumentSens() {
    this.docS.getSensitivityList().subscribe((res) => {
      this.sensitivities = res;
      console.log('sesitivity', res);
    });
  }

  setDocumentBranch() {
    this.docS.getBranchList().subscribe((res) => {
      this.branches = res;
      console.log('branches', res);
    });
  }

  upload(event: any) {
    let file: File = event.target.files[0];
    this.file = file;
  }
  close() {
    this.dialogRef.close();
  }
  async notificationSubFormat(docObj) {
    try {
      const notificationPayload: ISendNotification = {
        notification: {
          assignedToGroup: '',
          body: docObj.title,
          dateSent: new Date().toISOString(),
          deliveryStatus: EDeliveryStatus.SENT,
          docPath: docObj.docKey,
          misc: '',
          notificationStatus: 'ACTIVE',
          refCat: 'DOC',
          refNo: docObj.code,
          title: 'Document',
        },
        notificationReceivers: [
          {
            assignedTo: this.form.value.user,
            dateSent: new Date().toISOString(),
            read: true,
          },
        ],
      };
      const notificationRes = await lastValueFrom(this.messageS.sendNotification(notificationPayload));
    } catch (err) {
      this.uS.info('Error Notifying User', 0);
    }
  }
  async onSubmit() {
    console.log(this.form);
    if (this.data?.options?.upload) {
      this.loading = true;
      try {
        const val = this.form.getRawValue();
        delete val.user;
        const doc = await lastValueFrom(
          this.data?.options?.isPolicy
            ? this.docS.submitPolicyDocument(this.file, val, {
                policyNo: this.data?.options?.policy?.policyNo,
                policyCode: this.data?.options?.policy?.policyCode,
                policyNoSuffix: this.data?.options?.policy?.policyNoSuffix,
              })
            : this.docS.submitDocument2(this.file, val),
        );
        this.uS.info(`Document ${doc.code} was uploaded successfully`);
        if (this.form.value.user) {
          const docResult = await lastValueFrom(this.docS.getMetadataByID(doc.code));
          await this.notificationSubFormat(docResult);
        }
        this.dialogRef.close();
      } catch (error) {
        this.uS.info(error, 0);
      }
      this.loading = false;
    } else
      this.dialogRef.close({
        metadataForm: this.form,
        metadata: this.form?.value,
        file: this.file,
        user: this.form.value.user,
      });
  }
}
