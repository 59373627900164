import { Component, OnInit, Input, input } from '@angular/core';
import { Observable } from 'rxjs';
import { InputType } from '../../models/index.model';
import { FunctionCaller1, ValueFormatterPipe } from 'ets-fe-ng-sdk';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { MrouterLinkirective } from '../../directives/index.directive';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-editable-text-case',
    templateUrl: './editable-text-case.component.html',
    styleUrls: ['./editable-text-case.component.scss'],
    imports: [
        MatTooltipModule,
        NgClass,
        NgIf,
        InputBasicComponent,
        MrouterLinkirective,
        AsyncPipe,
        FunctionCaller1,
        TranslatePipe,
        ValueFormatterPipe,
    ]
})
export class EditableTextCaseComponent implements OnInit {
  @Input() action: any;
  @Input() coloredLbl: boolean;
  @Input() coloredVal: boolean;
  @Input() form: any;
  @Input() formatter: (item) => string | Promise<string> | Observable<string>;
  @Input() hint: string;
  @Input() valueHint: string;
  @Input() label: string;
  @Input() light: boolean;
  @Input() mini: boolean;
  @Input() name: string;
  @Input() route: string;
  @Input() routeFunc: (item) => Promise<string> | Observable<string>;
  @Input() showEditing: boolean;
  @Input() stacked: boolean;
  @Input() type: InputType;
  @Input() value: any;
  @Input() wrapLabel: boolean = true;
  readonly labelClass = input<string>();
  readonly valueClass = input<string>();
  editing: boolean;
  constructor() {}

  ngOnInit(): void {}
  reset() {
    this.editing = false;
  }
  unreset() {
    console.log(this.action);
    if (this.action) {
      this.action();
    } else {
      this.editing = true;
    }
  }
}
