import { Component, OnInit, ChangeDetectionStrategy, computed } from '@angular/core';
import {
  faUser,
  faEye,
  faDatabase,
  faRocket,
  faQuestionCircle,
  faListCheck,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../environments/environment';
import { AppService } from '../../Services/app.service';
import { Router, RouterOutlet } from '@angular/router';
import { VerticalNavComponent } from '../navigation/vertical-nav/vertical-nav.component';
import { UtilityService } from '../../Services/utility.service';
import { AdminUserService } from 'projects/evolutics-admin-ui/src/app/Services/admin-user.service';
import { MenuItem } from '../../Shared/models/IMenuItem';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss'],
  imports: [VerticalNavComponent, RouterOutlet],
})
export class DashboardAdminComponent implements OnInit {
  readonly profileMenuButtons = computed<Partial<MenuItem>[]>(() => [
    { label: 'Profile', link: '/profile' },
    {
      label: 'Sign Out',
      action: () => {
        this.uS.startLoader();
        this.adminS.logout();
      },
    },
    { label: 'Help', link: environment.helpLink },
  ]);
  title = environment.appName;
  constructor(
    public appS: AppService,
    public uS: UtilityService,
    public router: Router,
    private adminS: AdminUserService,
  ) {}

  ngOnInit(): void {}
}
