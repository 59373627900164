import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { lastValueFrom, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { IAgentInfo, IAgentSearchItem } from '../Life/agent-desk/agent-extras/agent.interface';
import { IPaymentInfo } from '../Life/agent-desk/new-individual-agent/create-agent-payment/payment-info.inteface';
import { ApiService } from '@Services/api.service';
import {
  IAgentSearchQuery,
  IAgentSearchResponse,
} from '../General/asset-desk/asset-desk-extras/agent.search';
import { CustomValidationError, ICodeDescription, ISearchResponse2 } from '@Shared/models/index.model';
import { HttpHeaders } from '@angular/common/http';
import { EndorsementResponse } from '@Reusables/reusable-pages/endorsement/endorsement-extras/endorsement.model';
import { IValidation } from '../Life/client-desk/client-extras/client.interface';
import { IAgentLite } from '../Life/agent-desk/agent-extras/agent-lite.interface';
import { EVFunctions } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  private baseURL = environment.apiBaseUrl + '/rest/agent/';
  agentTypes: any[];
  constructor(protected apiService: ApiService) {}

  getAgentByNo = (agentNo: string) => {
    return this.apiService.get<IAgentInfo>(`${this.baseURL}${agentNo}`);
  };
  getAgentByNoLite = (agentNo: string) => {
    return this.apiService.get<IAgentLite>(`${this.baseURL}basic-info/${agentNo}`);
  };

  validateExternalRef(ref: string, table: string) {
    return this.apiService.get(this.baseURL + 'validate/external/ref', {
      externalRef: ref,
      tableEnum: table,
    });
  }

  search = (query: any) => {
    // debugger
    // delete query.pageNumber;
    // delete query.pageSize;

    // if (Object.values(query).length <= 2) return of<ISearchResponse2<IAgentSearchItem>>(<ISearchResponse2>{content:[]});
    return this.apiService.get<ISearchResponse2<IAgentSearchItem>>(this.baseURL + 'search', query);
  };

  searchByName = (name: string) =>
    this.search({ name }).pipe(
      map((res) => res?.content?.sort2('no', true)?.map((c) => ({ code: c.no, title: c.name }))),
    );

  searchByNo = (no: string) =>
    this.search({ no }).pipe(
      map((res) => res?.content?.sort2('no', true)?.map((c) => ({ code: c.no, title: c.name }))),
    );

  validateUniqness = (val: Partial<IAgentSearchQuery>) =>
    val
      ? this.uniqueness(val).pipe(map((x) => (x ? null : <CustomValidationError>{ notUnique: true })))
      : null;

  uniqueness = (query: Partial<IAgentSearchQuery>) => {
    return this.apiService
      .get<IAgentSearchResponse>(`${this.baseURL}search`, query)
      .pipe(map((x) => x?.totalElements == 0));
  };

  getAgentNameByAgentNo = (num: string) => {
    if (!num) return of(null);
    return this.apiService.getText(this.baseURL + `name/${num}`).pipe(
      catchError((e) => {
        if (!e) return of(null);
        throw e;
      }),
      map((r) => r?.trim()?.removeNull()),
    );
  };

  asyncGetAgentNameByAgentNo = (num: string) => lastValueFrom(this.getAgentNameByAgentNo(num));
  getAgentNameJoinedByAgentNo = (num: string) =>
    this.getAgentNameByAgentNo(num).pipe(map((r) => EVFunctions.strConcatenator2(num, r)));

  getAgentNoByClientNo = (clientNo: string) => {
    return this.apiService.get<{ no: string }>(this.baseURL + `no/${clientNo}`).pipe(map((res) => res?.no));
  };
  getAgentTypes = () => {
    return this.agentTypes?.length
      ? of(this.agentTypes)
      : this.apiService
          .getWithLocalCache<any[]>(`${this.baseURL}setup/type/desc`)
          .pipe(tap((x) => (this.agentTypes = x)));
  };
  getPayinMethods = () =>
    this.apiService.get<ICodeDescription[]>(`${environment.apiBaseUrl}/rest/payin/method/code/desc`);
  getManagerList = (agentype: string) => {
    return this.apiService.get(this.baseURL + `type/no/full-name/${agentype}`);
  };
  updateAgentPayment = (agentNo: string, paymentInformation: IPaymentInfo) => {
    return this.apiService.put(`${this.baseURL}${agentNo}`, {
      paymentInformation,
    });
  };
  findAllUniqueHierAgent = () => {
    return this.apiService.get<
      {
        hierAgentNo: string;
        agentName: string;
      }[]
    >(this.baseURL + `hierarchy/unique`);
  };

  /**
   * Checks if an agent number is valid
   * @param agentNo
   * @returns
   */
  agentNoValidator = (agentNo: string) => {
    return this.apiService.get<IValidation>(this.baseURL + `validate/${agentNo}`);
  };
  validateAgentNoLite = (control: AbstractControl) => {
    return new Promise<any>((res) => {
      if (!control.value) res({ invalid: true });
      else
        lastValueFrom(this.agentNoValidator(control.value))
          .then((r) => {
            // debugger
            res(r?.response ? null : { notFound: true });
          })
          .catch((e) => {
            console.error(e);

            res({ notFound: true });
          });
    });
  };
  asyncValidateAgentNo = async (control: AbstractControl) => {
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.getAgentByNo(val)
      .toPromise()
      .then((res) => {
        if (!res.agentInformation) return { notFound: true };
        (control.parent as FormGroup)?.patchValue({
          agentData: res,
          agentName: res.agentInformation.name,
        });
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };

  asyncValidateExternalPolicyRef = async (control: AbstractControl) => {
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.validateExternalRef(val, 'POLICY')
      .toPromise()
      .then((res) => {
        if (!res) return { notFound: true };
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };
  asyncValidateExternalClientRef = async (control: AbstractControl) => {
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.validateExternalRef(val, 'CLIENT')
      .toPromise()
      .then((res) => {
        if (!res) return { notFound: true };
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };
  asyncValidateExternalAgentRef = async (control: AbstractControl) => {
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.validateExternalRef(val, 'AGENT')
      .toPromise()
      .then((res) => {
        if (!res) return { notFound: true };
        return null;
      })
      .catch((err) => {
        return { notFound: true };
      });
  };

  updateAgentStatus(agentNo, status) {
    const today = new Date().toISOString().split('T')[0];
    return this.apiService
      .put(
        `${this.baseURL}modify/agent/status`,
        {
          agentNo,
          effectiveDate: today,
          newStatusCode: status,
          userName: environment.userProfile?.users?.userName,
        },
        {
          options: { headers: new HttpHeaders().set('useEndorsement', 'true') },
        },
      )
      .pipe(map((r) => new EndorsementResponse(r)));
  }

  getAgentStatusHistory(agentNo) {
    return this.apiService.get(`${this.baseURL}status/history/${agentNo}`);
  }

  checkAgentClientMandatoryInfo(clientNo: string) {
    return this.apiService
      .get<{
        clientCheck: boolean;
        responseList: string[];
      }>(`${this.baseURL}validate/client-mand-info`, { clientNo })
      .pipe(
        catchError((e) => {
          console.error(e);
          return of(null);
        }),
      );
  }

  createCalendarEvent(payload) {
    return this.apiService.post(`${this.baseURL}calender`, payload);
  }

  getCalendarEventList(payload) {
    return this.apiService.get(`${this.baseURL}list/calender/search`, payload);
  }

  deleteCalendarEvent(id) {
    return this.apiService.deleteText(`${this.baseURL}calender?id=${id}`);
  }

  updateAgentPaymentInfo = (agentNo: string, clientNo: string, payload) => {
    return this.apiService.putWithEndorsment<IAgentInfo['paymentInformation']>(
      `${this.baseURL}payment/${agentNo}/${clientNo}`,
      payload,
    );
  };

  /**
   * Calls the ednpoint that searches credit notes by Agent No
   * @param agentNo
   * @returns
   */
  getCreditNotesByAgentNo(agentNo: string) {
    return this.apiService
      .get(`${environment.apiBaseUrl}/rest/credit/note/search`, { agentNo })
      .pipe(map((res) => res.content));
  }
}
