@if (content(); as content) {
  <div [ngClass]="{ isRoot: isRoot() }">
    @switch (content.type) {
      @case (eCMSType.file) {
        <render-cms-file [content]="content" />
      }
      @case (eCMSType.accordion) {
        <render-cms-accordion [content]="content | toAny" />
      }
      @case (eCMSType.image) {
        <render-cms-image [content]="content" />
      }
      @case (eCMSType.table) {
        <render-cms-table [content]="content | toAny" />
      }
      @case (eCMSType.url) {
        <render-cms-url [content]="content" />
      }
      @default {
        <div class="help-text-content">
          @if (!content.meta?.hideTitle) {
            <div class="content-title" [innerHTML]="content.title | appTranslateNL | async"></div>
          }
          @if (content.text) {
            <div
              class="overflow-auto"
              [innerHTML]="content.text | appTranslateSingleNL: translationConfig() | async"></div>
          }
        </div>
      }
    }
  </div>
}
