<modal-header header="Notifications" [dialogRef]="dialogRef" />

<div class="notif-case" mat-dialog-content>
  <div class="row row-cols-lg-1 m-0 gy-2">
    <div class="mb-2">
      <div class="row row-cols-lg-auto justify-content-end">
        <div>
          <mat-slide-toggle [(ngModel)]="showOnlyUnread" (ngModelChange)="toggleShowOnlyUnread($event)">
            {{ 'Only Unread' | appTranslate | async }}
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    @if (loading()) {
      <loader [loading]="true" />
    } @else {
      @for (item of notifications(); track item.id) {
        <div
          class="not-item my-2 border rounded-10 d-flex align-items-center"
          [ngClass]="{ unread: !item.read }">
          <div class="status-case">
            <div class="seen-status"></div>
          </div>
          <div class="body-case pointer" (click)="handleNotificationOpen($index)">
            @if (item._notification?.title) {
              <h6 class="not-header">{{ item._notification.title | appTranslate | async }}</h6>
            }
            <div
              class="not-body"
              [ngClass]="{ expanded: item._expanded }"
              [innerHTML]="item._notification?.body | trimText: 250 | appTranslate | async"></div>
          </div>
          <div class="action-case">
            @if (item._notification?.body) {
              <app-btn
                [mini]="true"
                (mclick)="item._expanded = !item._expanded; markAsUnread($index)"
                type="clear"
                customIcon="fa fa-chevron-{{ item._expanded ? 'up' : 'down' }}" />
            }
          </div>
        </div>
      } @empty {
        <div class="text-center">
          {{ 'There are no notifications' | appTranslate | async }}
        </div>
      }
      @if (canLoadMore()) {
        <div class="d-flex justify-content-center align-items-center">
          <app-btn text="Load More" type="clear" (mclick)="loadMore()" />
        </div>
      }
    }
  </div>
</div>
<modal-comp
  #previewModal
  [header]="selectedNotification()?._notification?.title || 'Notification' | appTranslate | async"
  [showFooter]="false">
  <ng-template modalBody>
    @if (selectedNotification(); as selectedNotification) {
      <div class="border p-2 rounded-10">
        <div class="" [innerHTML]="selectedNotification._notification?.body | appTranslate | async"></div>
      </div>
    }
  </ng-template>
</modal-comp>
