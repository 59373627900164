import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartDirective } from '@Shared/directives/ng-chart/ng-chart.directive';
import { RouterModule } from '@angular/router';
import { NavigationModule } from './navigation/navigation.module';
import { LoaderModule, TablePlainComponent } from 'ets-fe-ng-sdk';
import { WorkflowPipesModule } from '../Shared/pipes/workflow-pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '../Shared/directives/index.directive';
import { UtilityPipesModule } from '../Shared/pipes/utility.pipe';
import { ModalFormLayoutModule } from './modal-form-layout/modal-form-layout.module';
import { BtnModule } from '../Shared/components/btn/btn.module';
import { ExportTableModule } from '../Shared/components/export-table/export-table.module';
import { SearchModule } from '../Shared/components/search/search.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from '@Shared/components/modal/modal.module';

import { FormLayoutModule } from './form-layout/form-layout.module';
import { DashboardCaseModule } from './dashboard-case/dashboard-case.module';
import { DashboardAdminModule } from './dashboard-admin/dashboard-admin.module';
import { DashboardClientAdminComponent } from './dashboard-client-admin/dashboard-client-admin.component';

@NgModule({
  declarations: [],
  imports: [
    ...BtnModule,
    NgChartDirective,
    CommonModule,
    DirectivesModule,
    ExportTableModule,
    LoaderModule,
    MatTooltipModule,
    NavigationModule,
    RouterModule,
    SearchModule,
    TablePlainComponent,
    UtilityPipesModule,
    WorkflowPipesModule,
    FontAwesomeModule,
    ModalModule,
    DashboardCaseModule,
    DashboardClientAdminComponent,
  ],
  exports: [
    FormLayoutModule,
    NavigationModule,
    ModalFormLayoutModule,
    DashboardCaseModule,
    DashboardAdminModule,
    DashboardClientAdminComponent,
  ],
})
export class LayoutModule {}
