import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsComponent } from './notifications.component';
import { computed, signal } from '@angular/core';
import { MessageService } from '../../../Reusables/reusable-pages/messages/message.service';
import { INotificationReceiver } from '../../../Reusables/reusable-pages/messages/messages.model';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  readonly notifications = signal<INotificationReceiver[]>([]);
  readonly loading = signal(true);
  readonly showOnlyUnread = signal(true);
  readonly total = signal(0);
  readonly canLoadMore = computed<boolean>(() => this.notifications()?.length < this.total());

  protected readonly pageSize = signal(10);
  protected readonly pageNumber = signal(0);

  constructor(
    public mS: MessageService,
    public dialog: MatDialog,
  ) {}

  async loadMore() {
    this.loading.set(true);
    this.pageNumber.update((num) => num + 1);
    try {
      const res = await lastValueFrom(
        this.mS.searchNotificationReceivers(
          {
            pageSize: this.pageSize(),
            assignedTo: environment.userName,
            pageNumber: this.pageNumber(),
            sortBy: 'dateSent',
            sortDirection: 'DESC',
            read: this.showOnlyUnread() ? false : null,
          },
          true,
        ),
      );
      this.notifications.update((list) => list.concat(res.content));
      this.total.set(res.totalElements);
    } catch (error) {
      throw error;
    }
    this.loading.set(false);
  }

  toggleShowOnlyUnread(showOnlyUnread: boolean) {
    this.showOnlyUnread.set(showOnlyUnread);
    this.pageNumber.set(0);
    this.notifications.set([]);
    this.loadMore();
  }

  open() {
    this.dialog.open(NotificationsComponent, {
      width: '450px',
      position: { right: '-10px', top: '80px', bottom: '50px' },
      height: '100vh',
      hasBackdrop: true,
      // di
      panelClass: 'notifications-modal',
    });
  }
}
