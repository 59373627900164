<div class="container ">
  <form [formGroup]="fc" (ngSubmit)="onSubmit()">
    <div class="row row-cols-lg-3">
      <div class="mt-4">
        <app-autocomplete type="select" label='Employment Status' [form]="fc" name='employStatus' [stacked]="true"
          [options]="employmentStatusList" labelType="ct" valueField="code" #employStatus/>
        <app-validation-message [input]="employStatus">
        </app-validation-message>
        <!-- <label class="form-label text-primary">{{"Employment Status"|appTranslate|async}}</label><br>
        <select formControlName="employStatus" class="form-control  control-bg-gray">
          <option [value]="null">{{"Select Employment Status"|appTranslate|async}}</option>
          <option [value]="item.code" *ngFor="let item of employmentStatusList">{{item.code}} - {{item.title}}
          </option>
        </select>
        <div
          *ngIf="validation.employStatus.errors && (validation.employStatus.dirty || validation.employStatus.touched)">
          <div class="text-danger" *ngIf="validation.employStatus?.errors?.required ">
            {{"Field is invalid"|appTranslate|async}}!
          </div>
        </div> -->

      </div>
      <div class="mt-4">
        <label class="form-label text-primary">{{"Employer Client Number"|appTranslate|async}}</label><br>
        <div class="row align-items-end">
          <div class="col">
            <app-input-basic name="employerClientNo" [form]="fc" [showLabel]="false" [showValidationIcon]="true"
              #employerClientNoTag>
            </app-input-basic>
          </div>
          <div class="col-auto">
            <app-btn type="secondary" icon="search" (mclick)="clientS.openFindClientModal(employerClientNoTag.control())">
            </app-btn>
          </div>

          <div class="col-auto d-flex">
            <app-btn type="outline-nm" icon="add"
              (mclick)="clientS.openCreateCorporateClientModal(employerClientNoTag.control())"> </app-btn>
          </div>

        </div>
        @if (validation.employerClientNo.errors && (validation.employerClientNo.dirty || validation.employerClientNo.touched)) {
<div
         >
          @if (validation.employerClientNo?.errors?.required) {
<div class="text-danger">
            {{"Field is invalid"|appTranslate|async}}!
          </div>
}
        </div>
}
      </div>
      <div class="mt-4">
        <label class="form-label text-primary">{{"Employer FullName"|appTranslate|async}}</label>
        <app-input-basic name="_employerFullname" [form]="fc" [showLabel]="false" [readonly]="true">
        </app-input-basic>
      </div>
      <div class="mt-4">
        <app-autocomplete type="select" label='Sector' [form]="fc" name='employerSector' [stacked]="true"
          [options]="sectorList" labelType="ct" valueField="code" #sector />
        <app-validation-message [input]="sector">
        </app-validation-message>
        <!-- <label class="form-label text-primary">{{"Sector"|appTranslate|async}}</label><br>
        <select formControlName="employerSector" class="form-control  control-bg-gray">
          <option [value]="null">{{"Select Sector"|appTranslate|async}}</option>
          <option [value]="item.code" *ngFor="let item of sectorList">{{item.code}} - {{item.title}}</option>
        </select>
        <div
          *ngIf="validation.employerSector.errors && (validation.employerSector.dirty || validation.employerSector.touched)">
          <div class="text-danger" *ngIf="validation.employerSector?.errors?.required ">
            {{"Field is invalid"|appTranslate|async}}!
          </div>
        </div> -->
      </div>
      <div class="mt-4">
        <label class="form-label text-primary">{{"Staff Id"|appTranslate|async}}</label><br>
        <app-input-basic [form]="fc" name="staffNo" /> 
      </div>
      <div class="mt-4">
        <app-autocomplete type="select" label='Income Band' [form]="fc" name='incomeBand' [stacked]="true" [options]="incomeBands"
          labelType="ct" valueField="code" #incomeBand />
        <app-validation-message [input]="incomeBand">
        </app-validation-message>
        <!-- <label class="form-label text-primary">{{"Income Band"|appTranslate|async}}</label><br>
        <select formControlName="incomeBand" class="form-control  control-bg-gray">
          <option [value]="null">{{"Select Income Band"|appTranslate|async}}</option>
          <option [value]="item.code" *ngFor="let item of incomeBands">{{item.code}} - {{item.title}}</option>
        </select>
        <div *ngIf="validation.incomeBand.errors && validation.incomeBand.dirty">
          <div class="text-danger" *ngIf="validation.incomeBand?.errors?.required ">
            {{"Field is invalid"|appTranslate|async}}!
          </div>
        </div> -->
      </div>
      <div class="mt-4">
        <label class="form-label text-primary">{{"Company Name"|appTranslate|async}}</label><br>
        <input type="text" [value]="companyName" class="form-control  control-bg-gray" readonly>
        @if (validation.companyName.errors && (validation.companyName.dirty || validation.companyName.touched)) {
<div>
          @if (validation.companyName?.errors?.required ) {
<div class="text-danger">
            {{"Field is invalid"|appTranslate|async}}!
          </div>
}
        </div>
}
      </div>
      <div class="mt-4">
        <app-autocomplete type="select" label='Occupation Group' [form]="fc" name='occupationGroup' [stacked]="true"
          [options]="occupationGroupList" labelType="ct" valueField="code" #occupationGroup />
        <app-validation-message [input]="occupationGroup">
        </app-validation-message>
        
        <!-- <label class="form-label text-primary">{{"Occupation Group"|appTranslate|async}}</label><br>
        <select formControlName="occupationGroup" class="form-control  control-bg-gray" (change)="setOccupationList()">
          <option [value]="null">{{"Select Ocupation Group"|appTranslate|async}}</option>
          <option [value]="item.code" *ngFor="let item of occupationGroupList">{{item.code}} - {{item.title}}</option>
        </select>
        <div
          *ngIf="validation.occupationGroup.errors && (validation.occupationGroup.dirty || validation.occupationGroup.touched)">
          <div class="text-danger" *ngIf="validation.occupationGroup?.errors?.required ">
            {{"Field is invalid"|appTranslate|async}}!
          </div>
        </div> -->
      </div>
      <div class="mt-4">
        <app-autocomplete type="select" label='Occupation' [form]="fc" name='occupation' [stacked]="true"
          [options]="createIndividualClient.getOccupationList|functionCaller1:occupationGroup.valueSignal()|async" labelType="ct" valueField="code" #occupation />
        <app-validation-message [input]="occupation">
        </app-validation-message>

        <!-- <label class="form-label text-primary">{{"Occupation"|appTranslate|async}}</label><br>
        <select formControlName="occupation" class="form-control  control-bg-gray">
          <option [value]="null">{{"Select Occupation"|appTranslate|async}}</option>
          <option [value]="item.code" *ngFor="let item of occupationList">{{item.code}} - {{item.title}}</option>
        </select>
        <div *ngIf="validation.occupation.errors && (validation.occupation.dirty || validation.occupation.touched)">
          <div class="text-danger" *ngIf="validation.occupation?.errors?.required">
            {{"Field is invalid"|appTranslate|async}}!
          </div>
        </div> -->
      </div>
      <div class="mt-4">
        <label class="form-label text-primary">{{"Education Level"|appTranslate|async}}</label><br>
        <app-autocomplete [form]="fc" name="educationLevel" [options]="educationLevels" labelType="cdt" valueField="code" [showValidationMsg]="true" /> 
      </div>
    </div> 
  </form>
</div>