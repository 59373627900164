import { computed, Injectable } from '@angular/core';

import { AppService as CoreAppService } from '@Services/app.service';
@Injectable({
  providedIn: 'root',
})
export class AppService {
  isHome = false;
  readonly pageName = computed(() => this.appService.pageNameSignal());
  constructor(public appService: CoreAppService) {}
}
