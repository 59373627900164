import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FileNameFormat, formats } from './documents-name-display.model';
import { TranslatePipe } from "../../pipes/translate.pipe";
import { NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-documents-name-display',
    templateUrl: './documents-name-display.component.html',
    styleUrls: ['./documents-name-display.component.scss'],
    imports: [NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe, TranslatePipe]
})
export class DocumentsNameDisplayComponent implements OnInit {
  @Input() files: File[];
  @Input() fileNameFormat: FileNameFormat;
  @Output() valid = new EventEmitter<boolean>();
  @Output() error = new EventEmitter<any>();
  fileFormats = formats;

  fileNames: any[];
  errors: string[];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    // reset errors
    this.errors = [];

    if (changes.files.currentValue != changes.files.previousValue) {
      this.displayFileNames(changes.files.currentValue);
    }
  }

  displayFileNames(files) {
    this.fileNames = files
      ?.map((file) => this.parseFileName(file.name))
      // filter out files that weren't rightly named
      .filter((item) => !(item === null));
  }

  parseFileName(name) {
    let params;
    // returns an object representing different parts of the name or null if the name doesn't match
    switch (this.fileNameFormat) {
      case 'POLICY_DOCUMENT':
        params = this.getPolicyDocumentParams(name);
        break;
      case 'POLICY_DOCUMENT_WITH_SUFFIX':
        params = this.getPolicyDocumentWithSuffixParams(name);
        break;
      case 'NON_POLICY_DOCUMENT':
        params = this.getNonPolicyDocumentParams(name);
        break;
      default:
        params = {};
    }

    if (this.errors.length) {
      this.valid.emit(false);
      this.error.emit(this.errors);
    } else {
      this.valid.emit(true);
    }

    return params;
  }

  getPolicyDocumentParams(name) {
    const params = name.split(/[_\-\.]/i);

    const [refCat, policyNo, policyCode, docSubCat, title, ext] = params;

    const valid =
      refCat &&
      policyNo &&
      policyCode &&
      docSubCat &&
      title &&
      ext &&
      params.length === 6;
    if (!valid) this.errors.push(name);

    return valid ? { refCat, policyNo, policyCode, docSubCat, title } : null;
  }

  getPolicyDocumentWithSuffixParams(name) {
    const params = name.split(/[_\-\.]/i);

    const [
      refCat,
      policyNo,
      policyCode,
      policyNoSuffix,
      docSubCat,
      title,
      ext,
    ] = params;

    const valid =
      refCat &&
      policyNo &&
      policyCode &&
      policyNoSuffix &&
      docSubCat &&
      title &&
      ext &&
      params.length === 7;
    if (!valid) this.errors.push(name);

    return valid
      ? {
          refCat,
          policyNo,
          policyCode,
          policyNoSuffix,
          docSubCat,
          title,
        }
      : null;
  }

  getNonPolicyDocumentParams(name) {
    const params = name.split(/[_\.]/i);

    const [refCat, refNo, docSubCat, title, ext] = params;
    const valid =
      refCat && refNo && docSubCat && title && ext && params.length === 5;
    if (!valid) this.errors.push(name);

    return valid ? { refCat, refNo, docSubCat, title } : null;
  }
}
