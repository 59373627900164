import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, resource } from '@angular/core';
import { DocumentListService } from '@Reusables/reusable-pages/document-list/document-list.service';
import { UtilityService } from '@Services/utility.service';
import { EntityImagesModalComponent } from '../entity-images-modal/entity-images-modal.component';
import { ImageLoaderDirective } from '@Shared/directives/index.directive';
import { TranslatePipe } from '../../../Shared/pipes/translate.pipe';
import { RefCat, SubCategory } from '@Shared/models/index.model';

@Component({
  selector: 'entity-image-thumbnail',
  templateUrl: './entity-image-thumbnail.component.html',
  styleUrl: './entity-image-thumbnail.component.scss',
  imports: [CommonModule, ImageLoaderDirective, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityImageThumbnailComponent {
  readonly refNo = input.required<string>();

  readonly refCat = input.required<RefCat>();

  readonly editAcces = input<boolean>(true);

  readonly viewOnly = input<boolean>();

  readonly subCategory = input<SubCategory>('PP');

  readonly header = input<string>(undefined);

  readonly lastPPResource = resource({
    request: () => ({
      refCat: this.refCat(),
      refNo: this.refNo(),
      subCategory: this.subCategory(),
    }),
    loader: ({ request }) =>
      this.docS
        .getEntityLastImageDoc({
          refCategory: request.refCat,
          refNo: request.refNo,
          subCategory: request.subCategory,
        })
        .then((doc) =>
          doc?.documentContent
            ? `data:image/png;base64,` + doc.documentContent
            : this.docS.getFileDownloadURL(doc),
        ),
  });
  readonly lastPP = computed(() => this.lastPPResource.value() || '/assets/img/avatar.png');

  constructor(
    public uS: UtilityService,
    public docS: DocumentListService, // public docS: EntityImagesModalService
  ) {}

  openModal() {
    if (!this.viewOnly())
      this.uS.modalOpener(
        EntityImagesModalComponent.modalFactory(
          {
            refCat: this.refCat(),
            refNo: this.refNo(),
            subCategory: this.subCategory(),
          },
          () => {
            this.lastPPResource.reload();
          },
        ),
      );
  }
}
