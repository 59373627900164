import { ChangeDetectionStrategy, Component, computed, model, signal } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { NotificationsService } from './notifications.service';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { MessageService } from '../../../Reusables/reusable-pages/messages/message.service';
import { INotification } from '../../models/index.model';
import {
  ENotificationRefCat,
  INotificationReceiver,
} from '../../../Reusables/reusable-pages/messages/messages.model';
import { BtnComponent, IObjectLiteral, LoaderComponent, TrimTextPipe } from 'ets-fe-ng-sdk';
import { lastValueFrom } from 'rxjs';
import { UtilityService } from '../../../Services/utility.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { AsyncPipe, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import ModalComponents from '../modal-components/modal.components';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewDocumentModalComponent } from '@Reusables/reusable-comps/view-document-modal/view-document-modal.component';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  imports: [
    ModalHeaderComponent,
    MatDialogModule,
    BtnComponent,
    MatSlideToggleModule,
    LoaderComponent,
    TranslatePipe,
    AsyncPipe,
    FormsModule,
    TrimTextPipe,
    ModalComponents,
    NgClass,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  readonly selectedNotification = signal<INotificationReceiver>(null);
  readonly notifications = computed(() => this.nS.notifications());
  readonly loading = computed(() => this.nS.loading());
  readonly canLoadMore = computed(() => this.nS.canLoadMore());
  readonly showOnlyUnread = model(true);
  constructor(
    private nS: NotificationsService,
    public dialogRef: MatDialogRef<NotificationsComponent>,
    public mS: MessageService,
    public uS: UtilityService,
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.loadMore();
  }

  openDocument(index: number) {
    const notification = this.notifications()[index]?._notification;
    this.uS.dialogOpener(
      ViewDocumentModalComponent,
      {
        width: '80%',
        maxWidth: '90%',
        maxHeight: '90vh',
        data: {
          notification,
        },
      },
      () => {},
    );
  }

  async loadMore() {
    try {
      await this.nS.loadMore();
    } catch (error) {
      this.uS.info(error, 0);
    }
  }

  async toggleShowOnlyUnread(showRead:boolean) {
    try {
      await this.nS.toggleShowOnlyUnread(showRead);
    } catch (error) {
      this.uS.info(error, 0);
    }
  }

  handleNotificationOpen(index: number) {
    this.markAsUnread(index);
    if (this.notifications()[index]._notification.refCat === 'DOC') this.openDocument(index);
    else this.serviceHandleNotificationOpen(this.notifications()[index]);
  }

  markAsUnread(index: number) {
    const notification = this.nS.notifications()[index];
    if (notification.read) return;
    this.nS.notifications.update((list) => {
      list[index].read = true;
      return list.concat([]);
    });
    lastValueFrom(this.mS.markAsRead(notification.id, notification));
  }

  serviceHandleNotificationOpen(notification: INotificationReceiver) {
    const refCat = notification._notification?.refCat,
      refNo = notification._notification?.refNo;
    switch (refCat) {
      case ENotificationRefCat.workflow:
        return this.go('workflow-desk/view/index', { wfNo: refNo });
      case ENotificationRefCat.claim:
        return this.go('payment-desk/view-claim', { claimNo: refNo });
      default:
        break;
    }
  }

  protected go(route: string, queryParams: IObjectLiteral) {
    return this.uS.absoluteRouting(route, { queryParams, useRedirect: true });
  }
}
