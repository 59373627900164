<nav class="navbar navbar-expand-lg navbar-dark bg-primary shadow-sm sticky-top">
  <div class="container-fluid">
    <div #left class="d-flex align-items-center">
      <img [src]="favIcon()" alt="" height="24" class="me-1" />
      <a href="/" class="navbar-brand p-0">{{ uS.environment.appName }}</a>
    </div>
    @if (uS.isMobileSignal()) {
      <button mat-icon-button (click)="toggleSideMenu.emit()">
        <span class="fa fa-bars text-light"></span>
      </button>
    }
    @if (!uS.isMobileSignal()) {
      <div
        class="collapse navbar-collapse overflow-x-auto"
        [ngStyle]="{ 'margin-right': left.offsetWidth - right.offsetWidth + 'px' }"
        id="navbarNav">
        <ul class="navbar-nav mx-auto">
          @for (item of appS.computedTopMenu(); track item.id) {
            <li class="nav-item mx-0 mx-md-0 mx-xl-1 mx-lg-1">
              <a
                class="nav-link px-md-0 px-lg-0 px-sm-0 px-xl-2"
                (click)="setCurrentMenu(item)"
                routerLink="{{ item?.link }}"
                routerLinkActive="active"
                [id]="item.id">
                <app-icon [icon]="item.icon|toAny" />
                <span class="ps-1">{{ item.label | appTranslate | async }}</span>
              </a>
            </li>
          }
        </ul>
      </div>
    }

    <div #right class="nav-item mx-0 mx-md-0 mx-xl-1 mx-lg-1" [hidden]="uS.isMobileSignal()">
      <app-navbar-search />
    </div>
  </div>
</nav>
