import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { EIcon, IconService } from './icon.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-icon',
  imports: [CommonModule, MatIconModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  readonly icon = input<EIcon>();
  readonly iconClass = input<string>();

  readonly isRegistered = computed<boolean>(() => this.iconService.isRegistered(this.icon()!));
  readonly isClass = computed<{ isClass: boolean; class?: string }>(() =>
    this.icon() ? this.iconService.isClass(this.icon()!) : null,
  ); 

  constructor(protected iconService: IconService) {}
}
