import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@Services/api.service';
import { AppSaverService } from '@Services/cache/saver.service';
import { StorageService } from '@Services/storage.service';
import { TranslationService } from '@Services/translation.service';
import { environment } from '@environments/environment';
import { ITransGlossaryEntry, TranslationGlossary } from './translation-glossary.request.model';
import { ITranslationGlossaryResponse } from './translation-glossary.response.model';
import { lastValueFrom } from 'rxjs';
import { SDKEnvironment } from 'ets-fe-ng-sdk/lib/Shared/models/environment.model';
import { environment as SDKEvt } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class TranslationGlossaryService extends AppSaverService {
  private baseURL = environment.apiBaseUrl + '/rest/translation/glossary/';
  companyCode = 'company';
  name = environment.glossaryName;
  glossary: TranslationGlossary;
  constructor(
    private apiService: ApiService,
    public transService: TranslationService,
    public storageS: StorageService,
  ) {
    super('glossary', environment.transGlossaryKey);
    // debugger
    environment.transGlossary = this.glossary;
  }

  get targetLanguage() {
    return environment.targetLanguage || (environment.production ? null : 'FR');
  }
  async submitGlossary(entries: ITransGlossaryEntry[]) {
    const data: TranslationGlossary = {
      entries,
      companyCode: this.companyCode,
      // companyCode: environment.userProfile?.users?.primaryCompany,
      name: this.name,
      sourceLang: environment.sourceLanguage,
      targetLang: this.targetLanguage,
    };

    return lastValueFrom(
      (environment.transGlossary?.code
        ? this.apiService.put<ITranslationGlossaryResponse>(this.baseURL + environment.transGlossary.id, {
            ...environment.transGlossary,
            entries: entries.map((e) => `${e.sourceText}\t${e.targetText}`).join('\n'),
          })
        : this.apiService.post<ITranslationGlossaryResponse>(this.baseURL, data)
      ).pipe(
        map((r) => new TranslationGlossary(r)),
        tap((r) => (environment.transGlossary = r)),
      ),
    );
  }
  getGlossary() {
    // debugger;
    return this.apiService.get<ITranslationGlossaryResponse[]>(`${this.baseURL}`).pipe(
      map((r) => {
        SDKEvt._targetLanguage = environment.targetLanguage;
        const targetLanguage = this.targetLanguage;

        const glossary = r?.find(
          (x) =>
            x.sourceLang == environment.sourceLanguage &&
            x.targetLang == targetLanguage &&
            x.name == this.name,
        );
        // debugger;
        return glossary ? new TranslationGlossary(glossary) : null;
      }),
      tap(async (r) => {
        this.glossary = environment.transGlossary = await r;
        this.saveToLocal();
      }),
    );
  }
  protected deleteGlossary(code: string) {
    return this.apiService.delete(`${this.baseURL}${code}`);
  }
}
