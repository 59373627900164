import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormLayoutComponent } from './form-layout.component';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';




@NgModule({
    exports: [FormLayoutComponent],
    imports: [CommonModule, NavigationModule, RouterModule, FormLayoutComponent]
})
export class FormLayoutModule {}
