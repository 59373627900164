import { Component, Input, OnInit } from '@angular/core';
import { CreateIndividualClientService } from '../create-individual-client.service';
import {
  UntypedFormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { EValidationType, ICodeDescription, ICodeTitle, IStrictFormGroup } from '@Shared/models/index.model';
import { UtilityService } from '@Services/utility.service';
import { Client3Service } from '../../service/client.service';
import { AppService } from '@Services/app.service';
import { CodeService } from '@Services/code.service';
import { TranslatePipe } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { InputFormatDirective } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/directives/input-formatter.directive';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { PhoneNumberComponent } from 'ets-fe-ng-sdk';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { ValidationMessageComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { IClientIndividual } from '../individual-client-extras/individual-client.interface';

@Component({
    selector: 'app-personalinfo',
    templateUrl: './personalinfo.component.html',
    styleUrls: ['./personalinfo.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        InputBasicComponent,
        ValidationMessageComponent,
        MatExpansionModule,
        NgIf,
        AutocompleteComponent,
        PhoneNumberComponent,
        NgFor,
        BtnComponent,
        InputFormatDirective,
        AsyncPipe,
        TranslatePipe,
    ]
})
export class PersonalinfoComponent implements OnInit {
  @Input('fc') form: FormGroup;
  @Input() employmentInformationForm: FormGroup<IStrictFormGroup<IClientIndividual['employmentInformation']>>;
  @Input() minNameLength: number;
  regionList: ICodeDescription[] = [];
  countryList: ICodeDescription[] = [];
  stateList: ICodeDescription[] = [];
  townList: ICodeDescription[] = [];
  eValidationType = EValidationType;
  maxAltNumAllowed = 3;
  maritalStatuses: ICodeTitle[];
  genders: ICodeTitle[];
  reasons: ICodeTitle[];
  titles: ICodeTitle[];
  dateNotRequiredOptions = {
    B: true,
    O: true,
  };
  get hasBusLine() {
    return !!this.appS.getCurrentSystemMetadata?.busline;
  }
  constructor(
    public formService: CreateIndividualClientService,
    public uS: UtilityService,
    public appS: AppService,
    public clientS: Client3Service,
    public codeS: CodeService
  ) {}

  ngOnInit(): void {
    this.getReasons();
    this.setCountryList();
    this.setRegionList();
    this.setStateList();
    this.setTownList();
    this.clientS.getMaritalStatuses().subscribe((r) => {
      this.maritalStatuses = r;
    });
    this.clientS.getGenders().subscribe((r) => {
      this.genders = r;
    });
    this.codeS
      .getCodesByCodeSubGroup('TITLE')
      .subscribe((r) => (this.titles = r));
    this.addPhoneNumberForm();
  }
  getReasons() {
    this.codeS
      .getCodesByCodeSubGroup('CLIENT_CREATION_REASON')
      .subscribe((r) => (this.reasons = r));
  }
  get validation() {
    return this.form?.controls;
  }

  get altPhoneNumbersForm() {
    return this.form?.controls.altPhoneNumbers as UntypedFormArray;
  }

  addPhoneNumberForm(index = this.altPhoneNumbersForm.controls.length) {
    if (this.altPhoneNumbersForm.controls.length < this.maxAltNumAllowed)
      this.altPhoneNumbersForm.insert(index, new FormControl());
  }
  removePhoneNumberForm(index: number) {
    this.altPhoneNumbersForm.removeAt(index);
  }
  altPhoneNumbersFormLabel(i: number) {
    return this.altPhoneNumbersForm.controls?.length > 1 ? i + 1 : '';
  }
  onSubmit() {
    this.formService.personalInfo(this.form.value as any);
  }

  changeTab(tab: any) {
    this.formService.tabChange(tab);
  }

  setCountryList() {
    this.formService.getCountryList().subscribe((res) => {
      this.countryList = res;
    });
  }

  setRegionList() {
    if (this.form.get('addressCountry')?.value) {
      this.formService.getRegionList(this.form.get('addressCountry')?.value).subscribe((res) => {
        this.regionList = res;
        const addressRegionFC = this.form.get('addressRegion');
        if (!this.regionList.find((x) => x.code == addressRegionFC?.value)) addressRegionFC.reset();
      });
    }
  }

  setStateList() {
    if (this.form.get('addressRegion')?.value) {
      this.formService.getStateList(this.form.get('addressRegion')?.value).subscribe((res) => {
        this.stateList = res;
        const addressStateFC = this.form.get('addressState');
        if (!this.stateList.find((x) => x.code == addressStateFC?.value)) addressStateFC.reset();
      });
    }
  }

  setTownList() {
    if (this.form.get('addressState')?.value) {
      this.formService.getTownList(this.form.get('addressState')?.value).subscribe((res) => {
        this.townList = res;
        const addressTownFC = this.form.get('addressTown');
        if (!this.townList.find((x) => x.code == addressTownFC?.value)) addressTownFC.reset();
      });
    }
  }
}
